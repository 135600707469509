import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {FormattedMessage} from "react-intl";
import React from "react";

type CheckBoxComponentProps = {
    id?: string
    checked: boolean;
    onChange?: Function;
    label: string;
    defaultMessage: string;
}
//<FormattedMessage id="keep_me_logged_in" defaultMessage="Remember me" />{' '}

export const CheckBoxComponent = ({
    checked,
    onChange,
    label,
    defaultMessage
    }: CheckBoxComponentProps) => {
    return (
        <FormGroup sx={{mt: '33px'}}>
            <FormControlLabel
                className="checkbox-align-start"
                control={
                    <Checkbox
                        id="privacy_policy"
                        data-testid="checkbox"
                        name="privacy_policy"
                        checked={checked}
                        onChange={(e) => onChange ? onChange(e) : null}
                        sx={{
                            borderRadius: '8px',
                        }}
                    />
                }
                label={
                    <p
                        className="w-full"
                        style={{
                            fontWeight: 400,
                            fontSize: '12px',
                            color: '#9FA2B4'
                        }}>
                        <FormattedMessage id={label} defaultMessage={defaultMessage} />{' '}
                    </p>
                }
            />
        </FormGroup>
    )
}
