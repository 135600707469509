type CountriesDataT = {
    "code": string;
    "name": string;
    "icon": string;
};

export const countriesData: Array<CountriesDataT> = [
      {
        "code": "AF",
        "name": "Afghanistan",
        "icon": "assets/images/icons/flags/afghanistan.svg"
      },
      {
        "code": "AX",
        "name": "Åland Islands",
        "icon": "assets/images/icons/flags/aland-islands.svg"
      },
      {
        "code": "AL",
        "name": "Albania",
        "icon": "assets/images/icons/flags/albania.svg"
      },
      {
        "code": "DZ",
        "name": "Algeria",
        "icon": "assets/images/icons/flags/algeria.svg"
      },
      {
        "code": "AS",
        "name": "American Samoa",
        "icon": "assets/images/icons/flags/american-samoa.svg"
      },
      {
        "code": "AD",
        "name": "Andorra",
        "icon": "assets/images/icons/flags/andorra.svg"
      },
      {
        "code": "AO",
        "name": "Angola",
        "icon": "assets/images/icons/flags/angola.svg"
      },
      {
        "code": "AI",
        "name": "Anguilla",
        "icon": "assets/images/icons/flags/anguilla.svg"
      },
      {
        "code": "AQ",
        "name": "Antarctica",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "AG",
        "name": "Antigua and Barbuda",
        "icon": "assets/images/icons/flags/antigua-and-barbuda.svg"
      },
      {
        "code": "AR",
        "name": "Argentina",
        "icon": "assets/images/icons/flags/argentina.svg"
      },
      {
        "code": "AM",
        "name": "Armenia",
        "icon": "assets/images/icons/flags/armenia.svg"
      },
      {
        "code": "AW",
        "name": "Aruba",
        "icon": "assets/images/icons/flags/aruba.svg"
      },
      {
        "code": "AU",
        "name": "Australia",
        "icon": "assets/images/icons/flags/australia.svg"
      },
      {
        "code": "AT",
        "name": "Austria",
        "icon": "assets/images/icons/flags/austria.svg"
      },
      {
        "code": "AZ",
        "name": "Azerbaijan",
        "icon": "assets/images/icons/flags/azerbaijan.svg"
      },
      {
        "code": "BS",
        "name": "Bahamas",
        "icon": "assets/images/icons/flags/bahamas.svg"
      },
      {
        "code": "BH",
        "name": "Bahrain",
        "icon": "assets/images/icons/flags/bahrain.svg"
      },
      {
        "code": "BD",
        "name": "Bangladesh",
        "icon": "assets/images/icons/flags/bangladesh.svg"
      },
      {
        "code": "BB",
        "name": "Barbados",
        "icon": "assets/images/icons/flags/barbados.svg"
      },
      {
        "code": "BY",
        "name": "Belarus",
        "icon": "assets/images/icons/flags/belarus.svg"
      },
      {
        "code": "BE",
        "name": "Belgium",
        "icon": "assets/images/icons/flags/belgium.svg"
      },
      {
        "code": "BZ",
        "name": "Belize",
        "icon": "assets/images/icons/flags/belize.svg"
      },
      {
        "code": "BJ",
        "name": "Benin",
        "icon": "assets/images/icons/flags/benin.svg"
      },
      {
        "code": "BM",
        "name": "Bermuda",
        "icon": "assets/images/icons/flags/bermuda.svg"
      },
      {
        "code": "BT",
        "name": "Bhutan",
        "icon": "assets/images/icons/flags/bhutan.svg"
      },
      {
        "code": "BO",
        "name": "Bolivia, Plurinational State of",
        "icon": "assets/images/icons/flags/bolivia.svg"
      },
      {
        "code": "BQ",
        "name": "Bonaire, Sint Eustatius and Saba",
        "icon": "assets/images/icons/flags/bonaire.svg"
      },
      {
        "code": "BA",
        "name": "Bosnia and Herzegovina",
        "icon": "assets/images/icons/flags/bosnia-and-herzegovina.svg"
      },
      {
        "code": "BW",
        "name": "Botswana",
        "icon": "assets/images/icons/flags/botswana.svg"
      },
      {
        "code": "BV",
        "name": "Bouvet Island",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "BR",
        "name": "Brazil",
        "icon": "assets/images/icons/flags/brazil.svg"
      },
      {
        "code": "IO",
        "name": "British Indian Ocean Territory",
        "icon": "assets/images/icons/flags/british-indian-ocean-territory.svg"
      },
      {
        "code": "BN",
        "name": "Brunei Darussalam",
        "icon": "assets/images/icons/flags/brunei.svg"
      },
      {
        "code": "BG",
        "name": "Bulgaria",
        "icon": "assets/images/icons/flags/bulgaria.svg"
      },
      {
        "code": "BF",
        "name": "Burkina Faso",
        "icon": "assets/images/icons/flags/burkina-faso.svg"
      },
      {
        "code": "BI",
        "name": "Burundi",
        "icon": "assets/images/icons/flags/burundi.svg"
      },
      {
        "code": "KH",
        "name": "Cambodia",
        "icon": "assets/images/icons/flags/cambodia.svg"
      },
      {
        "code": "CM",
        "name": "Cameroon",
        "icon": "assets/images/icons/flags/cameroon.svg"
      },
      {
        "code": "CA",
        "name": "Canada",
        "icon": "assets/images/icons/flags/canada.svg"
      },
      {
        "code": "CV",
        "name": "Cape Verde",
        "icon": "assets/images/icons/flags/cape-verde.svg"
      },
      {
        "code": "KY",
        "name": "Cayman Islands",
        "icon": "assets/images/icons/flags/cayman-islands.svg"
      },
      {
        "code": "CF",
        "name": "Central African Republic",
        "icon": "assets/images/icons/flags/central-african-republic.svg"
      },
      {
        "code": "TD",
        "name": "Chad",
        "icon": "assets/images/icons/flags/chad.svg"
      },
      {
        "code": "CL",
        "name": "Chile",
        "icon": "assets/images/icons/flags/chile.svg"
      },
      {
        "code": "CN",
        "name": "China",
        "icon": "assets/images/icons/flags/china.svg"
      },
      {
        "code": "CX",
        "name": "Christmas Island",
        "icon": "assets/images/icons/flags/christmas-island.svg"
      },
      {
        "code": "CC",
        "name": "Cocos (Keeling) Islands",
        "icon": "assets/images/icons/flags/cocos-island.svg"
      },
      {
        "code": "CO",
        "name": "Colombia",
        "icon": "assets/images/icons/flags/colombia.svg"
      },
      {
        "code": "KM",
        "name": "Comoros",
        "icon": "assets/images/icons/flags/comoros.svg"
      },
      {
        "code": "CG",
        "name": "Congo",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "CD",
        "name": "Congo, the Democratic Republic of the",
        "icon": "assets/images/icons/flags/republic-of-the-congo.svg"
      },
      {
        "code": "CK",
        "name": "Cook Islands",
        "icon": "assets/images/icons/flags/cook-islands.svg"
      },
      {
        "code": "CR",
        "name": "Costa Rica",
        "icon": "assets/images/icons/flags/costa-rica.svg"
      },
      {
        "code": "CI",
        "name": "Côte d'Ivoire",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "HR",
        "name": "Croatia",
        "icon": "assets/images/icons/flags/croatia.svg"
      },
      {
        "code": "CU",
        "name": "Cuba",
        "icon": "assets/images/icons/flags/cuba.svg"
      },
      {
        "code": "CW",
        "name": "Curaçao",
        "icon": "assets/images/icons/flags/curacao.svg"
      },
      {
        "code": "CY",
        "name": "Cyprus",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "CZ",
        "name": "Czech Republic",
        "icon": "assets/images/icons/flags/czech-republic.svg"
      },
      {
        "code": "DK",
        "name": "Denmark",
        "icon": "assets/images/icons/flags/denmark.svg"
      },
      {
        "code": "DJ",
        "name": "Djibouti",
        "icon": "assets/images/icons/flags/djibouti.svg"
      },
      {
        "code": "DM",
        "name": "Dominica",
        "icon": "assets/images/icons/flags/dominica.svg"
      },
      {
        "code": "DO",
        "name": "Dominican Republic",
        "icon": "assets/images/icons/flags/dominican-republic.svg"
      },
      {
        "code": "EC",
        "name": "Ecuador",
        "icon": "assets/images/icons/flags/ecuador.svg"
      },
      {
        "code": "EG",
        "name": "Egypt",
        "icon": "assets/images/icons/flags/egypt.svg"
      },
      {
        "code": "SV",
        "name": "El Salvador",
        "icon": "assets/images/icons/flags/el-salvador.svg"
      },
      {
        "code": "GQ",
        "name": "Equatorial Guinea",
        "icon": "assets/images/icons/flags/equatorial-guinea.svg"
      },
      {
        "code": "ER",
        "name": "Eritrea",
        "icon": "assets/images/icons/flags/eritrea.svg"
      },
      {
        "code": "EE",
        "name": "Estonia",
        "icon": "assets/images/icons/flags/estonia.svg"
      },
      {
        "code": "ET",
        "name": "Ethiopia",
        "icon": "assets/images/icons/flags/ethiopia.svg"
      },
      {
        "code": "FK",
        "name": "Falkland Islands (Malvinas)",
        "icon": "assets/images/icons/flags/falkland-islands.svg"
      },
      {
        "code": "FO",
        "name": "Faroe Islands",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "FJ",
        "name": "Fiji",
        "icon": "assets/images/icons/flags/fiji.svg"
      },
      {
        "code": "FI",
        "name": "Finland",
        "icon": "assets/images/icons/flags/finland.svg"
      },
      {
        "code": "FR",
        "name": "France",
        "icon": "assets/images/icons/flags/france.svg"
      },
      {
        "code": "GF",
        "name": "French Guiana",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "PF",
        "name": "French Polynesia",
        "icon": "assets/images/icons/flags/french-polynesia.svg"
      },
      {
        "code": "TF",
        "name": "French Southern Territories",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "GA",
        "name": "Gabon",
        "icon": "assets/images/icons/flags/gabon.svg"
      },
      {
        "code": "GM",
        "name": "Gambia",
        "icon": "assets/images/icons/flags/gambia.svg"
      },
      {
        "code": "GE",
        "name": "Georgia",
        "icon": "assets/images/icons/flags/georgia.svg"
      },
      {
        "code": "DE",
        "name": "Germany",
        "icon": "assets/images/icons/flags/germany.svg"
      },
      {
        "code": "GH",
        "name": "Ghana",
        "icon": "assets/images/icons/flags/ghana.svg"
      },
      {
        "code": "GI",
        "name": "Gibraltar",
        "icon": "assets/images/icons/flags/gibraltar.svg"
      },
      {
        "code": "GR",
        "name": "Greece",
        "icon": "assets/images/icons/flags/greece.svg"
      },
      {
        "code": "GL",
        "name": "Greenland",
        "icon": "assets/images/icons/flags/greenland.svg"
      },
      {
        "code": "GD",
        "name": "Grenada",
        "icon": "assets/images/icons/flags/grenada.svg"
      },
      {
        "code": "GP",
        "name": "Guadeloupe",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "GU",
        "name": "Guam",
        "icon": "assets/images/icons/flags/guam.svg"
      },
      {
        "code": "GT",
        "name": "Guatemala",
        "icon": "assets/images/icons/flags/guatemala.svg"
      },
      {
        "code": "GG",
        "name": "Guernsey",
        "icon": "assets/images/icons/flags/guernsey.svg"
      },
      {
        "code": "GN",
        "name": "Guinea",
        "icon": "assets/images/icons/flags/guinea.svg"
      },
      {
        "code": "GW",
        "name": "Guinea-Bissau",
        "icon": "assets/images/icons/flags/guinea-bissau.svg"
      },
      {
        "code": "GY",
        "name": "Guyana",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "HT",
        "name": "Haiti",
        "icon": "assets/images/icons/flags/haiti.svg"
      },
      {
        "code": "VA",
        "name": "Holy Vatican City State",
        "icon": "assets/images/icons/flags/vatican-city.svg"
      },
      {
        "code": "HN",
        "name": "Honduras",
        "icon": "assets/images/icons/flags/honduras.svg"
      },
      {
        "code": "HK",
        "name": "Hong Kong",
        "icon": "assets/images/icons/flags/hong-kong.svg"
      },
      {
        "code": "HU",
        "name": "Hungary",
        "icon": "assets/images/icons/flags/hungary.svg"
      },
      {
        "code": "IS",
        "name": "Iceland",
        "icon": "assets/images/icons/flags/iceland.svg"
      },
      {
        "code": "IN",
        "name": "India",
        "icon": "assets/images/icons/flags/india.svg"
      },
      {
        "code": "ID",
        "name": "Indonesia",
        "icon": "assets/images/icons/flags/indonesia.svg"
      },
      {
        "code": "IR",
        "name": "Iran, Islamic Republic of",
        "icon": "assets/images/icons/flags/iran.svg"
      },
      {
        "code": "IQ",
        "name": "Iraq",
        "icon": "assets/images/icons/flags/iraq.svg"
      },
      {
        "code": "IE",
        "name": "Ireland",
        "icon": "assets/images/icons/flags/ireland.svg"
      },
      {
        "code": "IM",
        "name": "Isle of Man",
        "icon": "assets/images/icons/flags/isle-of-man.svg"
      },
      {
        "code": "IL",
        "name": "Israel",
        "icon": "assets/images/icons/flags/israel.svg"
      },
      {
        "code": "IT",
        "name": "Italy",
        "icon": "assets/images/icons/flags/italy.svg"
      },
      {
        "code": "JM",
        "name": "Jamaica",
        "icon": "assets/images/icons/flags/jamaica.svg"
      },
      {
        "code": "JP",
        "name": "Japan",
        "icon": "assets/images/icons/flags/japan.svg"
      },
      {
        "code": "JE",
        "name": "Jersey",
        "icon": "assets/images/icons/flags/jersey.svg"
      },
      {
        "code": "JO",
        "name": "Jordan",
        "icon": "assets/images/icons/flags/jordan.svg"
      },
      {
        "code": "KZ",
        "name": "Kazakhstan",
        "icon": "assets/images/icons/flags/kazakhstan.svg"
      },
      {
        "code": "KE",
        "name": "Kenya",
        "icon": "assets/images/icons/flags/kenya.svg"
      },
      {
        "code": "KI",
        "name": "Kiribati",
        "icon": "assets/images/icons/flags/kiribati.svg"
      },
      {
        "code": "KP",
        "name": "Korea, Democratic People's Republic of",
        "icon": "assets/images/icons/flags/north-korea.svg"
      },
      {
        "code": "KR",
        "name": "Korea, Republic of",
        "icon": "assets/images/icons/flags/south-korea.svg"
      },
      {
        "code": "KW",
        "name": "Kuwait",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "KG",
        "name": "Kyrgyzstan",
        "icon": "assets/images/icons/flags/kyrgyzstan.svg"
      },
      {
        "code": "LV",
        "name": "Latvia",
        "icon": "assets/images/icons/flags/latvia.svg"
      },
      {
        "code": "LB",
        "name": "Lebanon",
        "icon": "assets/images/icons/flags/lebanon.svg"
      },
      {
        "code": "LS",
        "name": "Lesotho",
        "icon": "assets/images/icons/flags/lesotho.svg"
      },
      {
        "code": "LR",
        "name": "Liberia",
        "icon": "assets/images/icons/flags/liberia.svg"
      },
      {
        "code": "LY",
        "name": "Libya",
        "icon": "assets/images/icons/flags/libya.svg"
      },
      {
        "code": "LI",
        "name": "Liechtenstein",
        "icon": "assets/images/icons/flags/liechtenstein.svg"
      },
      {
        "code": "LT",
        "name": "Lithuania",
        "icon": "assets/images/icons/flags/lithuania.svg"
      },
      {
        "code": "LU",
        "name": "Luxembourg",
        "icon": "assets/images/icons/flags/luxembourg.svg"
      },
      {
        "code": "MO",
        "name": "Macao",
        "icon": "assets/images/icons/flags/macao.svg"
      },
      {
        "code": "MK",
        "name": "Macedonia, North",
        "icon": "assets/images/icons/flags/republic-of-macedonia.svg"
      },
      {
        "code": "MG",
        "name": "Madagascar",
        "icon": "assets/images/icons/flags/madagascar.svg"
      },
      {
        "code": "MW",
        "name": "Malawi",
        "icon": "assets/images/icons/flags/malawi.svg"
      },
      {
        "code": "MY",
        "name": "Malaysia",
        "icon": "assets/images/icons/flags/malasya.svg"
      },
      {
        "code": "MV",
        "name": "Maldives",
        "icon": "assets/images/icons/flags/maldives.svg"
      },
      {
        "code": "ML",
        "name": "Mali",
        "icon": "assets/images/icons/flags/mali.svg"
      },
      {
        "code": "MT",
        "name": "Malta",
        "icon": "assets/images/icons/flags/malta.svg"
      },
      {
        "code": "MH",
        "name": "Marshall Islands",
        "icon": "assets/images/icons/flags/marshall-island.svg"
      },
      {
        "code": "MQ",
        "name": "Martinique",
        "icon": "assets/images/icons/flags/martinique.svg"
      },
      {
        "code": "MR",
        "name": "Mauritania",
        "icon": "assets/images/icons/flags/mauritania.svg"
      },
      {
        "code": "MU",
        "name": "Mauritius",
        "icon": "assets/images/icons/flags/mauritius.svg"
      },
      {
        "code": "YT",
        "name": "Mayotte",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "MX",
        "name": "Mexico",
        "icon": "assets/images/icons/flags/mexico.svg"
      },
      {
        "code": "FM",
        "name": "Micronesia, Federated States of",
        "icon": "assets/images/icons/flags/micronesia.svg"
      },
      {
        "code": "MD",
        "name": "Moldova, Republic of",
        "icon": "assets/images/icons/flags/moldova.svg"
      },
      {
        "code": "MC",
        "name": "Monaco",
        "icon": "assets/images/icons/flags/monaco.svg"
      },
      {
        "code": "MN",
        "name": "Mongolia",
        "icon": "assets/images/icons/flags/mongolia.svg"
      },
      {
        "code": "ME",
        "name": "Montenegro",
        "icon": "assets/images/icons/flags/montenegro.svg"
      },
      {
        "code": "MS",
        "name": "Montserrat",
        "icon": "assets/images/icons/flags/montserrat.svg"
      },
      {
        "code": "MA",
        "name": "Morocco",
        "icon": "assets/images/icons/flags/morocco.svg"
      },
      {
        "code": "MZ",
        "name": "Mozambique",
        "icon": "assets/images/icons/flags/mozambique.svg"
      },
      {
        "code": "MM",
        "name": "Myanmar",
        "icon": "assets/images/icons/flags/myanmar.svg"
      },
      {
        "code": "NA",
        "name": "Namibia",
        "icon": "assets/images/icons/flags/namibia.svg"
      },
      {
        "code": "NR",
        "name": "Nauru",
        "icon": "assets/images/icons/flags/nauru.svg"
      },
      {
        "code": "NP",
        "name": "Nepal",
        "icon": "assets/images/icons/flags/nepal.svg"
      },
      {
        "code": "NL",
        "name": "Netherlands",
        "icon": "assets/images/icons/flags/netherlands.svg"
      },
      {
        "code": "NC",
        "name": "New Caledonia",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "NZ",
        "name": "New Zealand",
        "icon": "assets/images/icons/flags/new-zealand.svg"
      },
      {
        "code": "NI",
        "name": "Nicaragua",
        "icon": "assets/images/icons/flags/nicaragua.svg"
      },
      {
        "code": "NE",
        "name": "Niger",
        "icon": "assets/images/icons/flags/niger.svg"
      },
      {
        "code": "NG",
        "name": "Nigeria",
        "icon": "assets/images/icons/flags/nigeria.svg"
      },
      {
        "code": "NU",
        "name": "Niue",
        "icon": "assets/images/icons/flags/niue.svg"
      },
      {
        "code": "NF",
        "name": "Norfolk Island",
        "icon": "assets/images/icons/flags/norfolk-island.svg"
      },
      {
        "code": "MP",
        "name": "Northern Mariana Islands",
        "icon": "assets/images/icons/flags/northern-marianas-islands.svg"
      },
      {
        "code": "NO",
        "name": "Norway",
        "icon": "assets/images/icons/flags/norway.svg"
      },
      {
        "code": "OM",
        "name": "Oman",
        "icon": "assets/images/icons/flags/oman.svg"
      },
      {
        "code": "PK",
        "name": "Pakistan",
        "icon": "assets/images/icons/flags/pakistan.svg"
      },
      {
        "code": "PW",
        "name": "Palau",
        "icon": "assets/images/icons/flags/palau.svg"
      },
      {
        "code": "PA",
        "name": "Panama",
        "icon": "assets/images/icons/flags/panama.svg"
      },
      {
        "code": "PG",
        "name": "Papua New Guinea",
        "icon": "assets/images/icons/flags/papua-new-guinea.svg"
      },
      {
        "code": "PY",
        "name": "Paraguay",
        "icon": "assets/images/icons/flags/paraguay.svg"
      },
      {
        "code": "PE",
        "name": "Peru",
        "icon": "assets/images/icons/flags/peru.svg"
      },
      {
        "code": "PH",
        "name": "Philippines",
        "icon": "assets/images/icons/flags/philippines.svg"
      },
      {
        "code": "PN",
        "name": "Pitcairn",
        "icon": "assets/images/icons/flags/pitcairn-islands.svg"
      },
      {
        "code": "PL",
        "name": "Poland",
        "icon": "assets/images/icons/flags/poland.svg"
      },
      {
        "code": "PT",
        "name": "Portugal",
        "icon": "assets/images/icons/flags/portugal.svg"
      },
      {
        "code": "PR",
        "name": "Puerto Rico",
        "icon": "assets/images/icons/flags/puerto-rico.svg"
      },
      {
        "code": "QA",
        "name": "Qatar",
        "icon": "assets/images/icons/flags/qatar.svg"
      },
      {
        "code": "RE",
        "name": "Réunion",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "RO",
        "name": "Romania",
        "icon": "assets/images/icons/flags/romania.svg"
      },
      {
        "code": "RU",
        "name": "Russian Federation",
        "icon": "assets/images/icons/flags/russia.svg"
      },
      {
        "code": "RW",
        "name": "Rwanda",
        "icon": "assets/images/icons/flags/rwanda.svg"
      },
      {
        "code": "BL",
        "name": "Saint Barthélemy",
        "icon": "assets/images/icons/flags/st-barts.svg"
      },
      {
        "code": "SH",
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "KN",
        "name": "Saint Kitts and Nevis",
        "icon": "assets/images/icons/flags/saint-kitts-and-nevis.svg"
      },
      {
        "code": "LC",
        "name": "Saint Lucia",
        "icon": "assets/images/icons/flags/st-lucia.svg"
      },
      {
        "code": "MF",
        "name": "Saint Martin (French part)",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "PM",
        "name": "Saint Pierre and Miquelon",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "VC",
        "name": "Saint Vincent and the Grenadines",
        "icon": "assets/images/icons/flags/st-vincent-and-the-grenadines.svg"
      },
      {
        "code": "WS",
        "name": "Samoa",
        "icon": "assets/images/icons/flags/samoa.svg"
      },
      {
        "code": "SM",
        "name": "San Marino",
        "icon": "assets/images/icons/flags/san-marino.svg"
      },
      {
        "code": "ST",
        "name": "Sao Tome and Principe",
        "icon": "assets/images/icons/flags/sao-tome-and-prince.svg"
      },
      {
        "code": "SA",
        "name": "Saudi Arabia",
        "icon": "assets/images/icons/flags/saudi-arabia.svg"
      },
      {
        "code": "SN",
        "name": "Senegal",
        "icon": "assets/images/icons/flags/senegal.svg"
      },
      {
        "code": "RS",
        "name": "Serbia",
        "icon": "assets/images/icons/flags/serbia.svg"
      },
      {
        "code": "SC",
        "name": "Seychelles",
        "icon": "assets/images/icons/flags/seychelles.svg"
      },
      {
        "code": "SL",
        "name": "Sierra Leone",
        "icon": "assets/images/icons/flags/sierra-leone.svg"
      },
      {
        "code": "SG",
        "name": "Singapore",
        "icon": "assets/images/icons/flags/singapore.svg"
      },
      {
        "code": "SX",
        "name": "Sint Maarten (Dutch part)",
        "icon": "assets/images/icons/flags/unknown.svg"
      },
      {
        "code": "SK",
        "name": "Slovakia",
        "icon": "assets/images/icons/flags/slovakia.svg"
      },
      {
        "code": "SI",
        "name": "Slovenia",
        "icon": "assets/images/icons/flags/slovenia.svg"
      },
      {
        "code": "SB",
        "name": "Solomon Islands",
        "icon": "assets/images/icons/flags/solomon-islands.svg"
      },
      {
        "code": "SO",
        "name": "Somalia",
        "icon": "assets/images/icons/flags/somalia.svg"
      },
      {
        "code": "ZA",
        "name": "South Africa",
        "icon": "assets/images/icons/flags/south-africa.svg"
      },
      {
        "code": "SS",
        "name": "South Sudan",
        "icon": "assets/images/icons/flags/south-sudan.svg"
      },
      {
        "code": "ES",
        "name": "España (Spain)",
        "icon": "assets/images/icons/flags/spain.svg"
      },
      {
        "code": "LK",
        "name": "Sri Lanka",
        "icon": "assets/images/icons/flags/sri-lanka.svg"
      },
      {
        "code": "SD",
        "name": "Sudan",
        "icon": "assets/images/icons/flags/sudan.svg"
      },
      {
        "code": "SR",
        "name": "Suriname",
        "icon": "assets/images/icons/flags/suriname.svg"
      },
      {
        "code": "SZ",
        "name": "Swaziland",
        "icon": "assets/images/icons/flags/swaziland.svg"
      },
      {
        "code": "SE",
        "name": "Sweden",
        "icon": "assets/images/icons/flags/sweden.svg"
      },
      {
        "code": "CH",
        "name": "Switzerland",
        "icon": "assets/images/icons/flags/switzerland.svg"
      },
      {
        "code": "SY",
        "name": "Syrian Arab Republic",
        "icon": "assets/images/icons/flags/syria.svg"
      },
      {
        "code": "TW",
        "name": "Taiwan, Province of China",
        "icon": "assets/images/icons/flags/taiwan.svg"
      },
      {
        "code": "TJ",
        "name": "Tajikistan",
        "icon": "assets/images/icons/flags/tajikistan.svg"
      },
      {
        "code": "TZ",
        "name": "Tanzania, United Republic of",
        "icon": "assets/images/icons/flags/tanzania.svg"
      },
      {
        "code": "TH",
        "name": "Thailand",
        "icon": "assets/images/icons/flags/thailand.svg"
      },
      {
        "code": "TL",
        "name": "Timor-Leste",
        "icon": "assets/images/icons/flags/east-timor.svg"
      },
      {
        "code": "TG",
        "name": "Togo",
        "icon": "assets/images/icons/flags/togo.svg"
      },
      {
        "code": "TK",
        "name": "Tokelau",
        "icon": "assets/images/icons/flags/tokelau.svg"
      },
      {
        "code": "TO",
        "name": "Tonga",
        "icon": "assets/images/icons/flags/tonga.svg"
      },
      {
        "code": "TT",
        "name": "Trinidad and Tobago",
        "icon": "assets/images/icons/flags/trinidad-and-tobago.svg"
      },
      {
        "code": "TN",
        "name": "Tunisia",
        "icon": "assets/images/icons/flags/tunisia.svg"
      },
      {
        "code": "TR",
        "name": "Turkey",
        "icon": "assets/images/icons/flags/turkey.svg"
      },
      {
        "code": "TM",
        "name": "Turkmenistan",
        "icon": "assets/images/icons/flags/turkmenistan.svg"
      },
      {
        "code": "TC",
        "name": "Turks and Caicos Islands",
        "icon": "assets/images/icons/flags/turks-and-caicos.svg"
      },
      {
        "code": "TV",
        "name": "Tuvalu",
        "icon": "assets/images/icons/flags/tuvalu.svg"
      },
      {
        "code": "UG",
        "name": "Uganda",
        "icon": "assets/images/icons/flags/uganda.svg"
      },
      {
        "code": "UA",
        "name": "Ukraine",
        "icon": "assets/images/icons/flags/ukraine.svg"
      },
      {
        "code": "AE",
        "name": "United Arab Emirates",
        "icon": "assets/images/icons/flags/united-arab-emirates.svg"
      },
      {
        "code": "GB",
        "name": "United Kingdom",
        "icon": "assets/images/icons/flags/united-kingdom.svg"
      },
      {
        "code": "US",
        "name": "United States",
        "icon": "assets/images/icons/flags/united-states.svg"
      },
      {
        "code": "UY",
        "name": "Uruguay",
        "icon": "assets/images/icons/flags/uruguay.svg"
      },
      {
        "code": "UZ",
        "name": "Uzbekistan",
        "icon": "assets/images/icons/flags/uzbekistan.svg"
      },
      {
        "code": "VU",
        "name": "Vanuatu",
        "icon": "assets/images/icons/flags/vanuatu.svg"
      },
      {
        "code": "VE",
        "name": "Venezuela, Bolivarian Republic of",
        "icon": "assets/images/icons/flags/venezuela.svg"
      },
      {
        "code": "VN",
        "name": "Viet Nam",
        "icon": "assets/images/icons/flags/vietnam.svg"
      },
      {
        "code": "VG",
        "name": "Virgin Islands, British",
        "icon": "assets/images/icons/flags/british-virgin-islands.svg"
      },
      {
        "code": "VI",
        "name": "Virgin Islands, U.S.",
        "icon": "assets/images/icons/flags/virgin-islands.svg"
      },
      {
        "code": "YE",
        "name": "Yemen",
        "icon": "assets/images/icons/flags/yemen.svg"
      },
      {
        "code": "ZM",
        "name": "Zambia",
        "icon": "assets/images/icons/flags/zambia.svg"
      },
      {
        "code": "ZW",
        "name": "Zimbabwe",
        "icon": "assets/images/icons/flags/zimbabwe.svg"
      }
    ]

