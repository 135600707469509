import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {userSelector, clearState} from './User/UserSlice';
import {useNavigate} from 'react-router-dom';
import {withErrorBoundary} from 'react-error-boundary';
import {Box, Container} from '@mui/material';
import LoggedInUserMenu from "../common/components/LoggedInUserMenu";
import CompanioButton from "../common/components/CompanioButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CompanioChart from "../common/components/CompanioChart";

const Index = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {user, isError} = useSelector(userSelector);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      navigate('login');
    }
  }, [isError, dispatch, navigate]);

  return <>
    <div className="flex flex-col w-full">

      <div className="flex justify-between p-6">
        <p className="text-xl text-left font-LaNord font-bold text-[#253292] tracking-wide leading-6">Hello {user.first_name}! It’s good to see you again.</p>
        <LoggedInUserMenu />
      </div>

      <div className="flex gap-6 p-6">
        <div className="w-6/12 flex flex-col gap-6">
          <div className="border rounded-lg flex justify-between relative w-full p-8">
            <div className="w-full text-left">
              <div className="text-2xl font-LaNord font-bold text-[#252733]">Give 50€, get 50€!</div>
              <div className="text-base font-LaNord pt-3 text-[#4B506D]">Refer a friend and get 50€!</div>
            </div>
            <img alt='Hearder image' src="/assets/images/Illustration_Header.png" style={{maxWidth: '190px', position: 'absolute', right: '50px', bottom: 0}}/>
          </div>
          <div className="flex gap-6">
            <div className="border rounded-lg w-6/12 px-9 py-8 text-left">
              <div className="rounded-lg bg-[#FEA500] w-[32px] h-[32px] flex justify-center items-center"><img alt='lamp' src="/assets/images/logos/Icon_lamp.svg" style={{width: '19px'}}/></div>
              <div className="text-xl font-LaNord font-bold text-[#252733] pt-5">Suggestion Box</div>
              <div className="text-sm font-LaNord text-[#9FA2B4] pt-2">Leave us your comments</div>
              <div className="pt-4 ml-[-8px]">
                <CompanioButton size="small" variant="contained" className="h-[35px] w-[186px]">Submit suggestion</CompanioButton>
              </div>
            </div>
            <div className="border rounded-lg w-6/12 px-9 py-8 text-left">
              <div className="rounded-lg bg-[#9A53CB] w-[32px] h-[32px] flex justify-center items-center"><img alt='camera' src="/assets/images/logos/Icon_camera.svg" style={{width: '19px'}}/></div>
              <div className="text-xl font-LaNord font-bold text-[#252733] pt-5">Need help?</div>
              <div className="text-sm font-LaNord text-[#9FA2B4] pt-2">Please check our videos</div>
              <div className="pt-4 ml-[-8px]">
                <CompanioButton size="small" variant="contained" className="h-[35px] w-[176px" onClick={()=>navigate("/help_videos")}>Help video section</CompanioButton>
              </div>
            </div>
          </div>
          <div className="border rounded-lg p-6"><CompanioChart /></div>
          <div className="border rounded-lg p-8 flex items-center">
            <div className="p-4 w-[57px] max-h-[57px] bg-[#018EFF] flex justify-center items-center rounded-lg mr-6"><img alt='Hearder image' src="/assets/images/logos/Icon_booking.svg" style={{ minWidth: '30px'}}/></div>
            <div className="text-left">
              <div className="text-xl font-LaNord font-bold text-[#252733]">Book a consulting session!</div>
              <div className="text-xs font-LaNord text-[#4B506D]">Do you have questions about e-residency? Our amazing customer support team is here to answer any questions about starting your business in Estonia. Click here to book!</div>
            </div>
          </div>
        </div>
        <div className="w-6/12 flex flex-col gap-6">
          <div className="border rounded-lg">
            <div className="flex justify-between px-6 pt-6">
              <div className="text-xl font-LaNord font-bold text-[#252733]">Important messages!</div>
              <div className="text-sm font-LaNord text-[#253292] cursor-pointer font-semibold">View details</div>
            </div>
            <div className="text-xs font-LaNord text-[#9FA2B4] text-left px-6">Today</div>
            <div className="flex py-8 justify-between items-end border-b px-6">
              <div className="flex items-center">
                <img alt='warning' src="/assets/images/logos/Icon_warning_red_circle.svg" style={{width: '31px'}}/>
                <div className="pl-4 text-left">
                  <div className="text-[10px] font-LaNord text-[#9FA2B4]">10.40 AM, Fri 10 Sept 2021</div>
                  <div className="text-base font-LaNord font-semibold text-[#253292] pt-1.5">You have 2 invoices to pay</div>
                  <div className="text-xs font-LaNord text-[#4B506D] pt-1.5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ut lectus augue. Mauris elementum at lacus id lacinia. </div>
                </div>
              </div>
              <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
            </div>
            <div className="flex py-8 justify-between items-end border-b px-6">
              <div className="flex items-center">
                <img alt='warning' src="/assets/images/logos/Icon_warning_red_circle.svg" style={{width: '31px'}}/>
                <div className="pl-4 text-left">
                  <div className="text-[10px] font-LaNord text-[#9FA2B4]">10.40 AM, Fri 10 Sept 2021</div>
                  <div className="text-base font-LaNord font-semibold text-[#253292] pt-1.5">You have 2 invoices to pay</div>
                  <div className="text-xs font-LaNord text-[#4B506D] pt-1.5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ut lectus augue. Mauris elementum at lacus id lacinia. </div>
                </div>
              </div>
              <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
            </div>
            <div className="flex py-8 justify-between items-end px-6">
              <div className="flex items-center">
                <img alt='warning' src="/assets/images/logos/Icon_warning_red_circle.svg" style={{width: '31px'}}/>
                <div className="pl-4 text-left">
                  <div className="text-[10px] font-LaNord text-[#9FA2B4]">10.40 AM, Fri 10 Sept 2021</div>
                  <div className="text-base font-LaNord font-semibold text-[#253292] pt-1.5">You have 2 invoices to pay</div>
                  <div className="text-xs font-LaNord text-[#4B506D] pt-1.5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ut lectus augue. Mauris elementum at lacus id lacinia. </div>
                </div>
              </div>
              <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
            </div>
          </div>
          <div className="border rounded-lg">
            <div className="px-6 py-6 flex justify-between items-center">
              <div className="text-left">
                <div className="text-xl font-LaNord font-bold text-[#252733]">Tickets </div>
                <div className="text-xs font-LaNord text-[#9FA2B4] text-left">Group:<span className="text-[#4B506D]">Support</span></div>
              </div>
              <CompanioButton size="small" variant="contained" className="h-[35px]">Open new ticket</CompanioButton>
            </div>
            <div className="text-left border-b px-6 py-5 flex justify-between">
              <div className="text-sm font-LaNord font-semibold text-[#252733]">Lorem ipsum dolor sit amet</div>
              <div className="text-sm font-LaNord font-semibold text-[#9FA2B4]">4238</div>
            </div>
            <div className="text-left border-b px-6 py-5 flex justify-between">
              <div className="text-sm font-LaNord font-semibold text-[#252733]">Lorem ipsum dolor sit amet</div>
              <div className="text-sm font-LaNord font-semibold text-[#9FA2B4]">1005</div>
            </div>
            <div className="text-left border-b px-6 py-5 flex justify-between">
              <div className="text-sm font-LaNord font-semibold text-[#252733]">Lorem ipsum dolor sit amet</div>
              <div className="text-sm font-LaNord font-semibold text-[#9FA2B4]">914</div>
            </div>
            <div className="text-left border-b px-6 py-5 flex justify-between">
              <div className="text-sm font-LaNord font-semibold text-[#252733]">Lorem ipsum dolor sit amet</div>
              <div className="text-sm font-LaNord font-semibold text-[#9FA2B4]">281</div>
            </div>
            <div className="text-center border-b px-6 py-5 text-[#253292] text-sm font-semibold font-LaNord">
              See all tickets
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default withErrorBoundary(
  Index,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })