import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {registerUser, userSelector, clearState, clearEmailSent} from '../User/UserSlice';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {withErrorBoundary} from 'react-error-boundary';

import {Button, TextField, Box, Checkbox, FormControlLabel, FormGroup} from '@mui/material';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [regData, setRegData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    checkbox: false
  })

  const {emailSent, isError, errorMessage} = useSelector(
    userSelector
  );
  const intl = useIntl()

  const onSubmit = (e: any) => {
    e.preventDefault()
    dispatch(registerUser(regData) as any);
  };

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
    if (emailSent) {
      toast.success(intl.formatMessage({id: "we_sent_you_magic_link_html"}))
      setTimeout(() => dispatch(clearEmailSent()), 5000);
    }
  }, [dispatch, isError, errorMessage, navigate, emailSent, intl]);

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const handleInputChange = (e: any) => {
    setRegData({
      ...regData,
      [e.target.id]: e.target.value
    })
  }
  const handleCheckboxChange = () => {
    setRegData({
      ...regData,
      'checkbox': !regData.checkbox
    })
  }

  return <div className="flex min-h-screen">
    <div className="flex-1 flex flex-col justify-between" style={{padding: '40px'}}>
      <img src="/assets/images/companio-logo.png" alt="logo" width="149" style={{maxHeight: "40px"}}/>

      <div>
        <p className="text-3xl">
          <FormattedMessage id="sign_up_with_companio" defaultMessage=""/>
        </p>
        <p className="text-3xl">
          <FormattedMessage id="start_invoicing_today" defaultMessage=""/>
        </p>

        <Box component="form"
             onSubmit={(e) => onSubmit(e)} noValidate sx={{mt: 1}}>
          <TextField
            margin="normal"
            required
            id="first_name"
            label="First name"
            name="first_name"
            value={regData.first_name}
            onChange={handleInputChange}
            autoComplete="first_name"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            id="last_name"
            label="Last name"
            value={regData.last_name}
            onChange={handleInputChange}
            name="last_name"
            autoComplete="last_name"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            value={regData.email}
            onChange={handleInputChange}
            label="Email"
            name="email"
            autoComplete="email"
          />

          <FormGroup>
            {/* // TODO: should check this part again... */}
            {/*<FormControlLabel control={<Checkbox*/}
            {/*  id="checkbox"*/}
            {/*  name="filter4"*/}
            {/*  checked={regData.checkbox}*/}
            {/*  onChange={handleCheckboxChange}*/}
            {/*/>} label={<FormattedMessage*/}
            {/*  id="i_accept_terms_and_conditions_and_privacy_policy"*/}
            {/*  values={{*/}
            {/*    a1: string => <a href='https://yourcompanyinestonia.com/terms/' target='_blank' rel="noreferrer">{string}</a>,*/}
            {/*    a2: string => <a href='https://yourcompanyinestonia.com/privacy-policy/' target='_blank'*/}
            {/*                     rel="noreferrer">{string}</a>*/}
            {/*  }}>*/}
            {/*  {chunks => {*/}
            {/*    return chunks[0] + chunks[1] + chunks[2] + chunks[3] + chunks[4]*/}
            {/*  }}*/}
            {/*</FormattedMessage>}>*/}

            {/*</FormControlLabel>*/}
          </FormGroup>
          <Button
            type="submit"
            disabled={!regData.first_name || !regData.last_name || !regData.email || !regData.checkbox || emailSent}
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            <FormattedMessage id="get_magic_link" defaultMessage=""/>
          </Button>
        </Box>
      </div>

      <div></div>
      {/*has to be here because of flex */}
    </div>

    <div className="flex-1 flex items-center justify-center"
         style={{
           background: 'linear-gradient(109.4deg, #9A53CB 1.26%, #FF4EA1 96.4%)',
           padding: '40px'
         }}>
      <img src="/assets/images/login-people.png" alt="people"/>
    </div>
  </div>

};

export default withErrorBoundary(
  Register,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
