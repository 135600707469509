import {Button} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {FormattedMessage} from "react-intl";

const AlreadyFreelancer = ({handleStep}: {handleStep: any}) => {
  return <div>
    <Typography variant="h3">
      <FormattedMessage id="already_freelancer" />
    </Typography>

    <Card className="inline-flex rounded m-4" sx={{width: '350px', height:'220px'}}>
      <CardContent>
          <Typography sx={{mb:1}} variant="h5" component="div" align="left">
            <FormattedMessage id="no" />
          </Typography>
        <Button
          variant="contained"
          onClick={() => handleStep(2, {is_switch: false, status: "not_freelancer"})}
          sx={{ mt: 1, mr: 1 }}
        >
          <FormattedMessage id="i_want_you_register_me" />
        </Button>
      </CardContent>
    </Card>

    <Card className="inline-flex rounded m-4" sx={{width: '350px', height:'220px'}}>
      <CardContent>
          <Typography sx={{mb:1}} variant="h5" component="div" align="left">
            <FormattedMessage id="yes" />
          </Typography>
        <Button
          variant="contained"
          onClick={() => handleStep(2, {is_switch: true, status: "freelancer"})}
          sx={{ mt: 1, mr: 1 }}
        >
          <FormattedMessage id="i_will_upload_documents" />
        </Button>
      </CardContent>
    </Card>

  </div>
}

export default AlreadyFreelancer