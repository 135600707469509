import {withErrorBoundary} from 'react-error-boundary'
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import {FormattedDate, FormattedMessage} from "react-intl";
import {Button, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {markAllNotificationsAsRead, markNotificationAsRead, notificationsSelector} from "./NotificationsSlice";
import Menu from "../../common/components/Menu";

const Notifications = () => {
  const {notifications} = useSelector(notificationsSelector)
  const dispatch = useDispatch()

  const markAsRead = (id: any) => {
    dispatch(markNotificationAsRead(id) as any)
  }

  const markAllAsRead = () => {
    dispatch(markAllNotificationsAsRead() as any)
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800 capitalize"><FormattedMessage id="notifications"/></p>
      <LoggedInUserMenu/>
    </div>

    <div className="flex justify-end align-middle">
      <Button variant="contained" sx={{mr: 2}} onClick={() => markAllAsRead()}><FormattedMessage
        id='mark_all_read'/></Button>
    </div>

    <TableContainer>
      <Table
        sx={{minWidth: 750}}
        aria-labelledby="tableTitle"
        size='medium'
      >
        <TableBody>
          {
            notifications.length ?
              notifications.map((notification: any, i: any) => generateNotificationRow(notification, i, markAsRead)) :
              <TableRow><TableCell>No notifications</TableCell></TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>

  </div>
};

const generateNotificationRow = (notification: any, i: any, markAsRead: any) => {

  return <TableRow key={i}>
    <TableCell align="left">
      <a href={notification.link}>{titleForNotification(notification)}</a>
      <p>{subTitleForNotification(notification)}</p>
    </TableCell>
    <TableCell>
      <FormattedDate
        value={notification.created}
        year="numeric"
        month="long"
        day="2-digit"/>
    </TableCell>
    <TableCell align="right">
      <Menu sx={{color: "blue"}}>
        <div onClick={() => markAsRead(notification._id)}><FormattedMessage id='mark_read'/></div>
      </Menu>
    </TableCell>
  </TableRow>
}

const titleForNotification = (notification: any) => {
  switch (notification.type) {
    case 'ticket_answered':
      return <><FormattedMessage
        id='ticket_answered'/> # {notification.metadata.ticket_number || notification.metadata.ticket}</>
    default:
      return <FormattedMessage id={'notification_' + notification.type + '_title'}/>
  }
}

const subTitleForNotification = (notification: any) => {
  switch (notification.type) {
    case 'payment':
      return <><FormattedMessage
        id={'notification_' + notification.type + '_subtitle'}/> {notification.metadata.amount}€: {notification.metadata.description}.</>
    case 'ticket_answered':
      return <><FormattedMessage id='answer_from_ticket'/> # {notification.metadata.from || "Customer support"}</>
    default:
      return <FormattedMessage id={'notification_' + notification.type + '_subtitle'}/>
  }

}


export default withErrorBoundary(
  Notifications,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })