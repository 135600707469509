import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import CompanioButton from "./CompanioButton";
import Box from "@mui/material/Box";

type ConfirmationDialogProps = {
    id?: string;
    type: string;
    buttonText: string;
    buttonColor: string;
    title: string;
    text?: string;
    confirmText: string;
    declineText: string;
    onConfirm: Function
};

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
    return <Slide direction="up" ref={ref} {...props}/>;
});

// type = button || text || div
const ConfirmationDialog = (
  {
    type = 'button',
    buttonText = 'Confirmation dialog',
    buttonColor = 'black',
    title = 'Please confirm',
    text = '',
    confirmText = 'Yes',
    declineText = 'No',
    onConfirm = () => {}
  } : ConfirmationDialogProps
) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    setOpen(false);
    onConfirm()
  };

  let display
  switch (type){
    case "button":
    display = <Button variant="outlined" onClick={() => handleClickOpen()} sx={{color: buttonColor, borderColor: buttonColor}}>
      {buttonText}
    </Button>
      break;
    case "text":
    case "div":
      display = <div onClick={() => handleClickOpen()} style={{color: buttonColor, width: '100%'}}>{buttonText}</div>
      break;
    default:
      <Button variant="outlined" onClick={() => handleClickOpen()}>
        {buttonText}
      </Button>
  }

    return (
    <>
      {display}
      <Dialog
        open={open}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <p className="m-4 ml-6 laNord text-2xl font-bold">{title}</p>
        <DialogContent className="border border-x-0 border-t-0">
          <p className="text-[#9FA2B4] laNord text-lg mb-4">
            {text}
          </p>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
            }}
            className="w-2/3 md:w-[400px]"
          >
          </Box>
        </DialogContent>
        <DialogActions sx={{my: "8px"}}>
          <CompanioButton size="large" variant="outlined" onClick={() => handleClose()}>{declineText}</CompanioButton>
          <CompanioButton size="large" variant="contained" onClick={() => handleAccept()}>{confirmText}</CompanioButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmationDialog