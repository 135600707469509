import {Menu, IconButton, MenuItem, Avatar, Badge, Divider} from '@mui/material';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";
import {useNavigate} from "react-router-dom";
import {withErrorBoundary} from "react-error-boundary";
import {getInitials} from "../utils/utils";
import {notificationsSelector} from "../../features/Notifications/NotificationsSlice";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

const LoggedInUserMenu = () => {
  const navigate: Function = useNavigate()
  const {user} = useSelector(userSelector)
  const {notifications} = useSelector(notificationsSelector)

  const name: string = user.first_name + ' ' + user.last_name

  const initials: string = getInitials(name)

  return <div className="flex items-center">
    <IconButton type="button" sx={{ p: '4px 0'}} aria-label="search">
      <SearchIcon />
    </IconButton>
    <Badge color="error" badgeContent={notifications.length} className="cursor-pointer mx-5" overlap="circular" variant="dot"  onClick={() => navigate('notifications')}>
      <NotificationsNoneIcon className="text-gray-500"/>
    </Badge>
    <Divider sx={{ height: 28, m: 0.5, marginRight: 8 }} orientation="vertical" />
    <PopupState variant="popover" popupId="logged-in-user-popup-menu">
      {(popupState) => (
        <>
          <div className="text-blue-800 font-LaNord font-bold cursor-pointer" {...bindTrigger(popupState)}>{name}</div>
          <IconButton
            {...bindTrigger(popupState)}
            size="small"
          >
            <Avatar sx={{width: 32, height: 32}}
                    src={process.env.REACT_APP_API_URL + '/' + user.avatar}>{initials}</Avatar>
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={() => navigate('/settings/add_user/'+user._id)}>Profile</MenuItem>
            <Divider sx={{my: 1}}/>
            <MenuItem onClick={() => navigate('logout')}>Logout</MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
    <Avatar
      sx={{width: 25, height: 25, cursor: "pointer"}}
      onClick={() => navigate('/settings/users/')}
    >+</Avatar>
  </div>
}

export default withErrorBoundary(
  LoggedInUserMenu,
  {
    FallbackComponent: () => <div>Menu failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })