import {FormattedMessage} from "react-intl";
import React from "react";
import {Button, Checkbox, FormControlLabel, FormGroup, Menu} from "@mui/material";

type FilterMenuType = {
  filters: any;
  className?: string;
  intl: any;
  onClick: Function;
  open: boolean;
  filtersAnchorEl: any;
  handleChange: any;
}

export const FilterMenu = ({filters, className, intl, onClick, open, filtersAnchorEl, handleChange}: FilterMenuType) => {
  return <>
    {/*<Button*/}
    {/*  id="basic-button"*/}
    {/*  aria-controls={open ? 'basic-menu' : undefined}*/}
    {/*  aria-haspopup="true"*/}
    {/*  aria-expanded={open ? 'true' : undefined}*/}
    {/*  onClick={(e) => (onClick ? onClick(e) : null)}*/}
    {/*  className={className}*/}
    {/*>*/}
      <button className="flex bg-[#F7F8FC] text-sm font-LaNord font-semibold text-[#4B506D] rounded-lg items-center ml-4 h-[40px] px-4" aria-controls={open ? 'basic-menu' : undefined}
           aria-haspopup="true"
           aria-expanded={open ? 'true' : undefined}
           onClick={(e) => (onClick ? onClick(e) : null)}
           >
        <div className="mr-2">
          <img src="/assets/images/logos/Icon_filter.svg"
               alt="logo"
               width="16"
          />
        </div>
        <FormattedMessage id="filters"/>
      </button>

    {/*</Button>*/}
    <Menu
      id="basic-menu"
      anchorEl={filtersAnchorEl}
      open={open}
      onClose={(e) => (onClick ? onClick(e) : null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter1"
                    checked={filters.status.includes('uploaded')}
                    onChange={() => handleChange('status', 'uploaded')}/>}
                          label={intl.formatMessage({id: 'uploaded'})}/>
      </FormGroup>

      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter2"
                    checked={filters.status.includes('processing')}
                    onChange={() => handleChange('status', 'processing')}/>}
                          label={intl.formatMessage({id: 'processing'})}/>
      </FormGroup>

      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter3"
                    checked={filters.status.includes('processed')}
                    onChange={() => handleChange('status', 'processed')}/>}
                          label={intl.formatMessage({id: 'processed'})}/>
      </FormGroup>

      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter4"
                    checked={filters.matching.includes('matched')}
                    onChange={() => handleChange('matching', 'matched')}/>}
                          label={intl.formatMessage({id: 'matched'})}/>
      </FormGroup>

      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter5"
                    checked={filters.matching.includes('unmatched')}
                    onChange={() => handleChange('matching', 'unmatched')}/>}
                          label={intl.formatMessage({id: 'unmatched'})}/>
      </FormGroup>
    </Menu>
  </>
}