import {useParams} from "react-router-dom";
import {withErrorBoundary} from "react-error-boundary";
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clearState, userSelector, updateUser, updateAvatarImage, addUser} from './UserSlice';
import {FormattedMessage, useIntl} from 'react-intl';
import toast from "react-hot-toast";
import {readFileAsBase64} from "../../common/utils/utils";
import {findDOMNode} from "react-dom";
import DefaultInput from "../../common/components/DefaultInput";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CompanioButton from "../../common/components/CompanioButton";

const AddUser = () => {
  const dispatch = useDispatch()
  const {selected_user_id} = useParams()
  const intl = useIntl()
  const avatarRef: React.Ref<any> = useRef(null);

  const {isFetching, isError, isSuccess, user} = useSelector(userSelector)

  const initialUserState = selected_user_id ? {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    locale: user.locale,
    phone: user.phone,
    role: user.role,
    id: user._id
  } : emptyUserData

  const [userData, setUserData] = useState<any>(initialUserState)

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(intl.formatMessage({id: 'error_modifying_user'}));
    }
    if (isSuccess) {
      toast.success(intl.formatMessage({id: 'data_successfully_modified'}));
    }
    dispatch(clearState());
  }, [dispatch, isError, isSuccess, intl])

  const handleInputChange = (e: any, id: any) => {
    setUserData({
      ...userData,
      [e.target.id || id]: e.target.value
    })
  }

  const onSaveUserClicked = () => {
    if (selected_user_id) {
      dispatch(updateUser(userData) as any)
    }
    else{
      dispatch(addUser(userData) as any)
    }
  }

  const fileSelectedHandler = async (event: any) => {
    const base64File = await readFileAsBase64(event.target.files[0])
    if(selected_user_id){
      const avatarInfo = {
        id: selected_user_id,
        avatar: base64File
      }
      dispatch(updateAvatarImage(avatarInfo) as any)
    }
    else{
      setUserData({
        ...userData,
        'avatar': base64File
      })
    }
    (findDOMNode(avatarRef.current) as any).setAttribute('src', base64File)
  }

  return <>
    <div className="flex flex-col w-full border rounded-lg my-4">

      <p className="text-2xl text-left ml-6 font-bold text-blue-800"><FormattedMessage id="edit_user"/></p>

      <div className="flex flex-wrap pl-8 pb-4 justify-between pr-8">
        <DefaultInput
          value={userData.first_name}
          onChange={(e: any) => handleInputChange(e, e.target.id)}
          required
          id="first_name"
          label="First Name"
          className="w-full md:w-5/12 mb-4"
        />
        <DefaultInput
          value={userData.last_name}
          onChange={(e: any) => handleInputChange(e, e.target.id)}
          required
          id="last_name"
          label="Last Name"
          className="w-full md:w-5/12 mb-4"
        />
        <DefaultInput
          value={userData.email}
          onChange={(e: any) => handleInputChange(e, e.target.id)}
          required
          id="email"
          label="Email"
          className="w-full md:w-5/12 mb-4"
        />
        <div className="flex flex-col items-start w-full md:w-5/12 uppercase mb-4">
          <p className="text-xs font-LaNord text-[#9FA2B4] font-bold">locale</p>
          <div className="flex border rounded-lg px-4 bg-[#FCFDFE] justify-between justify-items-center items-center mt-2 w-full">
            <select
              name="locale"
              value={userData.locale}
              className="h-[40px] appearance-none text-sm font-LaNord text-[#9FA2B4] w-full"
              onChange={(e) => handleInputChange(e, "locale")}
            >
              <option value="en_US" key="en_US">en_US</option>
              <option value="es_ES" key="es_ES">es_ES</option>
            </select>
            <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
          </div>
        </div>
        <DefaultInput
          onChange={(e: React.MouseEvent) => handleInputChange(e, "locale")}
          value={userData.phone}
          id="phone"
          label="Phone Number"
          required
          className="w-full md:w-5/12 mb-20"
        />
        <div className="flex flex-col items-start w-full md:w-5/12 uppercase mb-4">
          <p className="text-xs font-LaNord text-[#9FA2B4] font-bold">Role</p>
          <div className="flex border rounded-lg px-4 bg-[#FCFDFE] justify-between justify-items-center items-center mt-2 w-full">
            <select
              name="role"
              id="role"
              value={userData.role}
              className="h-[40px] appearance-none text-sm font-LaNord text-[#9FA2B4] w-full"
              onChange={(e) => handleInputChange(e, "role")}
            >
              <option className="text-left" value="representative" key="representative">Representative</option>
              <option className="text-left" value="admin" key="admin">Admin</option>
              <option className="text-left" value="accountant" key="accountant">Accountant</option>
              <option className="text-left" value="human_resources" key="human_resources">HR</option>
              <option className="text-left" value="secretary" key="secretary">Secretary</option>
            </select>
            <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
          </div>
          <div className="flex w-full justify-end mt-4">
            <CompanioButton
              disabled={isFetching || !Object.values(userData).every(Boolean)}
              onClick={onSaveUserClicked}
              variant="contained">
              Submit
            </CompanioButton>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full justify-between border border-x-0 border-b-0">
        <div className="flex flex-col md:flex-row justify-center md:justify-end items-center">
          <img alt="avatar"
               style={{width: 44, height: 44, borderRadius: "50%", margin: "20px"}}
               src={process.env.REACT_APP_API_URL + '/' + user.avatar}
          />
          <p style={{
            fontSize: "12px",
            color: "#4B506D",
            fontStyle: "normal",
            lineHeight: "16px"
          }}
             className="LaNord"
          >
            Photo will be shown to your users when you send them messages or reply to them. Max Photo size 1 MB.
          </p>
        </div>

        <label htmlFor="contained-button-file">
          <input style={{display: "none"}} onChange={fileSelectedHandler} accept="image/*" id="contained-button-file"
                 ref={avatarRef}
                 multiple type="file"/>
          <div className="flex flex-col md:flex-row m-6">
            <CompanioButton variant="outlined" onClick={() => avatarRef.current.click()}>
              Update picture
            </CompanioButton>
          </div>

        </label>
      </div>
    </div>
  </>
};

const emptyUserData = {
  first_name: '',
  last_name: '',
  email: '',
  locale: 'es_ES',
  phone: '',
  role: '',
}

export default withErrorBoundary(
  AddUser,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });