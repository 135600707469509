import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {clearState, getReports, reportsSelector} from './ReportsSlice';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {withErrorBoundary} from 'react-error-boundary';

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Container,
  Box, TableHead,
} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import BadgeForDocumentStatus from "../../common/components/BadgeForDocuments";
import MonthYearFilter from "../../common/components/MonthYearFilter";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import CompanioButton from "../../common/components/CompanioButton";
import DefaultInput from "../../common/components/DefaultInput";
import DefaultTextArea from "../../common/components/DefaultTextArea";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SearchFilter from "../../common/components/SearchFilter";
import {FilterMenu} from "../../common/components/FilterMenu";

const Reports = () => {
  const [filters, setFilters] = useState({
    status: ['processed', 'processing', 'uploaded'],
    matching: ['matched', 'unmatched'],
    search: ''
  });
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);

  const intl = useIntl();
  const open = Boolean(filtersAnchorEl);

  const handleClick = (event: any) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setFiltersAnchorEl(null);
  };

  const handleFilterChange = (group: any, value: any) => {
    // @ts-ignore
    const isActive = filters[group].includes(value)
    // @ts-ignore
    const updatedFiltersGroup = isActive ? filters[group].filter((i: any) => i !== value) : filters[group].concat(value)
    const updatedFilters = {
      ...filters,
      [group]: updatedFiltersGroup
    }
    setFilters(updatedFilters)
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="reports"/></p>
      <LoggedInUserMenu />
    </div>
    <div className="flex flex-wrap mb-6">
      <div className="flex-col w-[38%] m-[1%]">
        <div className="text-center border rounded-lg h-[134px] mb-[30px] py-[25px]">
          <p className="text-lg font-LaNord font-bold text-[#9FA2B4]">Lorem ipsum dolor sit amet</p>
          <p className="text-4xl mt-4 font-LaNord font-bold text-[#252733]">605</p>
        </div>
        <div className="flex-col text-left p-[32px] border rounded-lg h-[500px]">
          <p className="text-[19px] font-LaNord font-bold text-[#252733]">
            {intl.formatMessage({id: "upload_document", defaultMessage:"Upload a document"})}
          </p>
          <div className="flex my-[25px]">
            <DefaultInput
              placeholder="Short descriptive title"
              label={intl.formatMessage({id: 'title'})}
              type="text"
              value=""
              onChange={() => console.log("changing")}
              className="w-full"
            />
          </div>
          <div className="flex my-[25px]">
            <DefaultTextArea
              placeholder="Any additional details ..."
              label={intl.formatMessage({id: 'description'})}
              value=""
              onChange={() => console.log("changing")}
              className="w-full"
            />
          </div>
          <div className="flex w-full justify-center md:justify-between items-center h-[20px] mt-[30px]">
            <div>
              <AttachFileIcon sx={{color: "#C5C7CD"}}/>
              <input type="file" className="hidden" id="upload_file" onChange={() => console.log("changing")}/>
              <label htmlFor="upload_file" className="font-LaNord text-sm text-[#C5C7CD] cursor-pointer md:ml-[12px]">
                Upload a file
              </label>
            </div>

            <CompanioButton className="h-[40px]" size="large" variant="contained" onClick={() => console.log("I'm working")}>
              <p className="text-[14px]">Loren Ipsum</p>
            </CompanioButton>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-[58%] m-[1%]">
        <div className="flex-row border rounded-lg h-[420px] mb-[30px] text-left p-[32px]">
          <div className="w-full">
            <p className="text-[19px] font-LaNord font-bold text-[#252733]">
              {intl.formatMessage({id: "current_documents", defaultMessage:"Your current documents"})}
            </p>
          </div>

          <div className="flex justify-between my-[30px]">
            <SearchFilter filters={filters} setFilters={setFilters} />
            <FilterMenu filters={filters}
                        className="mr-2-2"
                        intl={intl}
                        onClick={open ? () => {handleClose()} : (e: React.MouseEvent) => {handleClick(e)}}
                        open={open}
                        filtersAnchorEl={filtersAnchorEl}
                        handleChange={handleFilterChange}/>
          </div>
          <div className="flex mb-[32px]">
            <div className="flex flex-col w-[65%]">
              <p className="text-[16px] text-[#253292] font-LaNord font-bold mb-[14px]">Lorem ipsum dolor sit amet</p>
              <p className="text-[14px] text-[#4B506D] font-LaNord">Lorem ipsum dolor sit amet, in tritani mentitum mei, est veri consequat te. Ut vel esse tempor maiorum.</p>
            </div>
            <div className="flex flex-col w-[35%] justify-center items-end">
              <CompanioButton className="h-[40px] w-[80%]" size="large" variant="contained" onClick={() => console.log("I'm working")}>
                <p className="text-[14px]">Loren Ipsum</p>
              </CompanioButton>
            </div>
          </div>
          <div className="flex mb-[32px]">
            <div className="flex flex-col w-[65%]">
              <p className="text-[16px] text-[#253292] font-LaNord font-bold mb-[14px]">Lorem ipsum dolor sit amet</p>
              <p className="text-[14px] text-[#4B506D] font-LaNord">Lorem ipsum dolor sit amet, in tritani mentitum mei, est veri consequat te. Ut vel esse tempor maiorum.</p>
            </div>
            <div className="flex flex-col w-[35%] justify-center items-end">
              <CompanioButton className="h-[40px] w-[80%]" size="large" variant="contained" onClick={() => console.log("I'm working")}>
                <p className="text-[14px]">Loren Ipsum</p>
              </CompanioButton>
            </div>
          </div>
        </div>
        <div className="flex h-[213px] rounded-lg bg-[#018EFF] text-white text-left font-LaNord">
          <div className="flex-col w-1/2 items-center h-full pt-[34px] px-12">
            <p className="text-lg font-bold mb-[17px]">Loren ipsum dolor</p>
            <p className="text-[14px] mb-[22px]">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <CompanioButton className="h-[40px]" size="large" variant="contained" onClick={() => console.log("I'm working")}>
              <p className="text-[14px]">Loren Ipsum</p>
            </CompanioButton>
          </div>
          <div className="flex-row w-1/2 items-center h-full pt-[34px] px-12">
            <div className="flex items-center w-[189px] h-[176px] pb-[19px]">
              <img src="/assets/images/report-page-avatar.svg"/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
};

const formattedDate = (date: string) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const day = new Date(date).getDate()
  const month = new Date(date).getMonth()
  const year = new Date(date).getFullYear()

  return `${months[month]} ${day}, ${year}`
}

export default withErrorBoundary(
  Reports,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })
