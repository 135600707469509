import {Badge} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {withErrorBoundary} from "react-error-boundary";

type BadgeForTransactionProps = {
  id?: string;
  className?: string;
  transaction: object;
};

const BadgeForTransaction = ({className, transaction}: BadgeForTransactionProps) => {
  // @ts-ignore
  let net = (transaction && transaction.net && transaction.net.value) ||
      // @ts-ignore
    (transaction.amount && transaction.amount.value)

  let text = "unknown"

  if(net > 0) {
    text = 'credit'
  }
  if(net < 0) {
    text = 'debit'
  }
  // @ts-ignore
  return <span className={`${className} bg-[#F0F1F7] flex items-center uppercase h-6 border rounded-lg laNord justify-center p-1`} style={{fontSize: '10px', color: "#9FA2B4"}}><FormattedMessage id={text} /></span>
}

export default withErrorBoundary(
  BadgeForTransaction,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })