import {withErrorBoundary} from "react-error-boundary";
import React from "react";

const Logout = () => {

  return <>
    <p className="whitespace-pre-wrap text-left">Logout </p>
  </>
};

export default withErrorBoundary(
  Logout,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })