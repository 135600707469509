import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

export const getPurchases: any = createAsyncThunk<any, any, any>(
  'purchases/getPurchases',
  async ({ page, limit, year, month, filters }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/docs/year/' + year +
        '/month/'+month +
        '/paginated?page=' + page +
        '&limit=' + limit +
        '&type=purchase_invoice&filters=' + encodeURIComponent(JSON.stringify(filters)),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const uploadDocument: any = createAsyncThunk <any, any, any>(
  'purchases/uploadDocument',
  async ({type, filename, filedata }, thunkAPI) => {
    try {
      let description = type
      description = (description.replace('/_/g', ' ').trim()) + " " + filename
      const params = {
        name: filename,
        type: type,
        data: filedata,
        description: description,
        force: true
      }
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/docs/upload/',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)
export const updateDocOutOfPocket: any = createAsyncThunk <any, any, any>(
  'purchases/updateDocOutOfPocket',
  async ({docId, checked}, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/docs/'+ docId +'/out_of_pocket',
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({out_of_pocket: checked}),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)
export const updateDocumentWithData: any = createAsyncThunk <any, any, any>(
  'purchases/updateDocumentWithData',
  async ({docId, newData}, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/docs/'+ docId,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newData),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)
export const moveDocumentToMonth: any = createAsyncThunk <any, any, any>(
  'purchases/moveDocumentToMonth',
  async ({docId, monthChange}, thunkAPI) => {
    const destDate = new Date()
    destDate.setDate(1)
    destDate.setMonth(destDate.getMonth() + monthChange)
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/docs/'+ docId,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({date: destDate}),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)
export const deleteDocument: any = createAsyncThunk <any, any, any>(
  'purchases/deleteDocument',
  async ({docId}, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/docs/'+ docId,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const purchasesSlice = createSlice({
  name: 'purchases',
  initialState: {
    purchasesData: null,
    emailSent: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [getPurchases.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getPurchases.pending]: (state) => {
      state.isFetching = true;
    },
    [getPurchases.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.purchasesData = payload
    },
    [uploadDocument.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [uploadDocument.pending]: (state) => {
      state.isFetching = true;
    },
    [uploadDocument.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [updateDocOutOfPocket.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [updateDocOutOfPocket.pending]: (state) => {
      state.isFetching = true;
    },
    [updateDocOutOfPocket.fulfilled]: (state, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;

      state.purchasesData = {
        ...JSON.parse(JSON.stringify(state.purchasesData)),
        // @ts-ignore
        docs: [...JSON.parse(JSON.stringify(state.purchasesData.docs)).map((element: any) => {

          if(element._id === payload.document._id) {
            element = payload.document
          }
          return element
        })]
      }
    },
    [updateDocumentWithData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [updateDocumentWithData.pending]: (state) => {
      state.isFetching = true;
    },
    [updateDocumentWithData.fulfilled]: (state, {payload}) => {
      // @ts-ignore
      const newState = [...JSON.parse(JSON.stringify(state.purchasesData.docs))]
      for (let i = 0; i < newState.length; i++) {
        if(newState[i]._id === payload.document._id && payload.document.type === "purchase_invoice") {
          newState[i] = payload.document;
        }
        if(payload.document.type !== "purchase_invoice"){
          newState.splice(i, 1)
        }
      }

      state.isFetching = false;
      state.isSuccess = true;

      state.purchasesData = {
        // @ts-ignore
        ...JSON.parse(JSON.stringify(state.purchasesData)),
        // @ts-ignore
        docs: newState
      }
    },
    [moveDocumentToMonth.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [moveDocumentToMonth.pending]: (state) => {
      state.isFetching = true;
    },
    [moveDocumentToMonth.fulfilled]: (state, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.purchasesData = {
        // @ts-ignore
        ...JSON.parse(JSON.stringify(state.purchasesData)),
        // @ts-ignore
        docs: [...JSON.parse(JSON.stringify(state.purchasesData)).docs.filter((doc) => {
          return doc._id !== payload.document._id
        })]
      }
    },
    [deleteDocument.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [deleteDocument.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteDocument.fulfilled]: (state, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.purchasesData = {
        // @ts-ignore
        ...JSON.parse(JSON.stringify(state.purchasesData)),
        // @ts-ignore
        docs: [...JSON.parse(JSON.stringify(state.purchasesData)).docs.filter((doc) => {
          return doc._id !== payload.document._id
        })]
      }
    },
  },
});

export const { clearState } = purchasesSlice.actions;

export const purchasesSelector = (state: any) => state.purchases;
