import Cookies from "js-cookie";

export const getInitials = (str: string) =>
  str
    .split(' ')
    .map(([firstLetter]) => firstLetter)
    .filter((_, index, array) => index === 0 || index === array.length - 1)
    .join('')
    .toUpperCase();

export const downloadDocumentById = (id: string) => {
  let endpoint = process.env.REACT_APP_API_URL + '/docs/' + id + '/download'
  endpoint += '?Authorization=' + JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token
  window.open(endpoint, '_blank')
}

export const readFileAsBase64 = (file: any) => {
  return new Promise(resolve => {
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };
  });
}

export const dateToRelativeTime = (date: any) => {
  const elapsed = Math.floor((new Date(date).getTime() -new Date().getTime()) / 1000)

  if ((Math.abs(elapsed) - 31556952) > 0) {
    return {dateToRelativeTimeAmount: Math.floor(elapsed / 31556952), dateToRelativeTimeFormat: 'year'}
  }
  if ((Math.abs(elapsed) - 2628000) > 0) {
    return {dateToRelativeTimeAmount: Math.floor(elapsed / 2628000), dateToRelativeTimeFormat: 'month'}
  }
  if ((Math.abs(elapsed) - 604800) > 0) {
    return {dateToRelativeTimeAmount: Math.floor(elapsed / 604800), dateToRelativeTimeFormat: 'week'}
  }
  if ((Math.abs(elapsed) - 86400) > 0) {
    return {dateToRelativeTimeAmount: Math.floor(elapsed / 86400), dateToRelativeTimeFormat: 'day'}
  }
  if ((Math.abs(elapsed) - 3600) > 0) {
    return {dateToRelativeTimeAmount: Math.floor(elapsed / 3600), dateToRelativeTimeFormat: 'hour'}
  }
  if ((Math.abs(elapsed) - 60) > 0) {
    return {dateToRelativeTimeAmount: Math.floor(elapsed / 60), dateToRelativeTimeFormat: 'minute'}
  }

  return {dateToRelativeTimeAmount: elapsed, dateToRelativeTimeFormat: 'seconds'}
}

export const differenceInDaysBetweenDates = (from: any, to: any) => {
  const fromDate = new Date(from)
  const toDate = new Date(to)
  fromDate.setHours(0, 0, 0, 0)
  toDate.setHours(0, 0, 0, 0)
  return (toDate.getTime() - fromDate.getTime()) / (24 * 60 * 60 * 1000)
}

export const replacePrefixFromDocName = (name: string, fromType: string, toType: string) => {
  return name.replace(new RegExp('^' + fromType, 'i'), toType)
}

export const removeDocumentTypePrefix = (name: string, type: string) => {
  return name.replace(new RegExp(type + '_\\d+_', 'i'), "")
}

export const prefixFromDocName = (name: string, type: string) => {
  const found = new RegExp('(' + type + '_\\d+_)', 'i').exec(name)
  if (found) { return found[0] }
  else { return "" }
}