import {Badge} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {withErrorBoundary} from "react-error-boundary";

type BadgeBadgeForDocumentStatusProps = {
  id?: string;
  className: string;
  status: string;
}

const BadgeForDocumentStatus = ({className, status}: BadgeBadgeForDocumentStatusProps) => {
  let badgeColor = '#9FA2B4'

  switch (status) {
    case "uploaded":
      badgeColor = "#253292"
      break
    case "processing":
    case "requirements":
      badgeColor = "#FEA500"
      break
    case "processed":
    case "ready":
      badgeColor = "#00DA8B"
      break
    case "deleted":
      badgeColor = "#FE2142"
      break
    default:
      badgeColor = "#9FA2B4"
  }

  // @ts-ignore
  return <CheckCircleIcon sx={{color: badgeColor}} className={className} />
}

export default withErrorBoundary(
  BadgeForDocumentStatus,
  {
    FallbackComponent: () => <div></div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })