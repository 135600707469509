const emtak = [
	{
		"code": 63111,
		"description": "Alojamiento en la nube (Cloud hosting)",
		"includes": "Alojamiento en la nube (Cloud hosting)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Analista de la red (Web Analytics)",
		"includes": "Analista de la red (Web Analytics)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59121,
		"description": "Animación (Animation)",
		"includes": "Animación (Animation)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59121,
		"description": "Animación 3D (3D Animation)",
		"includes": "Animación 3D (3D Animation)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Anuncios en su sitio web (Ads on your website)",
		"includes": "Anuncios en su sitio web (Ads on your website)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69202,
		"description": "Análisis / Reducción de costos (Cost Analysis/Reduction)",
		"includes": "Análisis / Reducción de costos (Cost Analysis/Reduction)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Análisis competitivo (Competitive Analysis)",
		"includes": "Análisis competitivo (Competitive Analysis)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Análisis cuantitativo (Quantitative Analysis)",
		"includes": "Análisis cuantitativo (Quantitative Analysis)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Aplicaciones móviles (Mobile apps)",
		"includes": "Aplicaciones móviles (Mobile apps)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62021,
		"description": "Apoyo técnico (Technical Support)",
		"includes": "Apoyo técnico (Technical Support)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Aprendizaje automático (Machine Learning)",
		"includes": "Aprendizaje automático (Machine Learning)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71111,
		"description": "Arquitectura (Architecture)",
		"includes": "Arquitectura (Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71111,
		"description": "Arquitectura de la casa (House Architecture)",
		"includes": "Arquitectura de la casa (House Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71111,
		"description": "Arquitectura de oficinas (Office Architecture)",
		"includes": "Arquitectura de oficinas (Office Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71111,
		"description": "Arquitectura del Paisaje (Landscape Architecture)",
		"includes": "Arquitectura del Paisaje (Landscape Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Asesoramiento jurídico Redacción y traducciones (Legal Consulting Writing & Translations)",
		"includes": "Asesoramiento jurídico Redacción y traducciones (Legal Consulting Writing & Translations)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Asistente personal / virtual (Personal / Virtual Assistant)",
		"includes": "Asistente personal / virtual (Personal / Virtual Assistant)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Automatización de correo electrónico y marketing (Email & Marketing Automation)",
		"includes": "Automatización de correo electrónico y marketing (Email & Marketing Automation)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Chatbots (Chatbots)",
		"includes": "Chatbots (Chatbots)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Ciencia y análisis de datos y programación de bases de datos (Data Science & Analytics and database Programming)",
		"includes": "Ciencia y análisis de datos y programación de bases de datos (Data Science & Analytics and database Programming)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Comercio internacional (International Commerce)",
		"includes": "Comercio internacional (International Commerce)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 58121,
		"description": "Comprobación de hechos (Fact Checking)",
		"includes": "Comprobación de hechos (Fact Checking)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 72191,
		"description": "Computación científica (Scientific Computation)",
		"includes": "Computación científica (Scientific Computation)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Comunicación corporativa (Corporate Communication)",
		"includes": "Comunicación corporativa (Corporate Communication)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Conceptos publicitarios (Advertising Concepts)",
		"includes": "Conceptos publicitarios (Advertising Concepts)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Consultoría de gestión (Management Consulting)",
		"includes": "Consultoría de gestión (Management Consulting)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 63121,
		"description": "Contenido web (Web Content)",
		"includes": "Contenido web (Web Content)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Control de calidad y pruebas (QA & Testing)",
		"includes": "Control de calidad y pruebas (QA & Testing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "CopyWriting y traducciones (CopyWriting & Translations)",
		"includes": "CopyWriting y traducciones (CopyWriting & Translations)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Creadores de sitios web y CMS (Website Builders & CMS)",
		"includes": "Creadores de sitios web y CMS (Website Builders & CMS)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69202,
		"description": "Cuentas y finanzas (Accounting & Finance)",
		"includes": "Cuentas y finanzas (Accounting & Finance)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82191,
		"description": "Currículums y cartas de presentación (Resumes & Cover Letters)",
		"includes": "Currículums y cartas de presentación (Resumes & Cover Letters)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Data Science & Analytics y bases de datos (Data Science & Analytics and databases)",
		"includes": "Data Science & Analytics y bases de datos (Data Science & Analytics and databases)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Data Science & Analytics y diseño de bases de datos (Data Science & Analytics and database Design)",
		"includes": "Data Science & Analytics y diseño de bases de datos (Data Science & Analytics and database Design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69103,
		"description": "Derecho Corporativo (Corporate Law)",
		"includes": "Derecho Corporativo (Corporate Law)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69103,
		"description": "Derecho contractual (Contract Law)",
		"includes": "Derecho contractual (Contract Law)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69102,
		"description": "Derecho penal (Criminal Law)",
		"includes": "Derecho penal (Criminal Law)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Desarrollo de comercio electrónico (Ecommerce Development)",
		"includes": "Desarrollo de comercio electrónico (Ecommerce Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Desarrollo de consultoría empresarial (Business Consulting Development)",
		"includes": "Desarrollo de consultoría empresarial (Business Consulting Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Desarrollo de juegos (Game Development)",
		"includes": "Desarrollo de juegos (Game Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Desarrollo de liderazgo (Leadership Development)",
		"includes": "Desarrollo de liderazgo (Leadership Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82111,
		"description": "Desarrollo de listas de correo (Mailing List Development)",
		"includes": "Desarrollo de listas de correo (Mailing List Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Desarrollo de software como servicio (Software Development as a Service)",
		"includes": "Desarrollo de software como servicio (Software Development as a Service)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Desarrollo de software de escritorio (Desktop Software Development)",
		"includes": "Desarrollo de software de escritorio (Desktop Software Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Desarrollo de software empaquetado (Packaged Software Development)",
		"includes": "Desarrollo de software empaquetado (Packaged Software Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Desarrollo móvil (Mobile Development)",
		"includes": "Desarrollo móvil (Mobile Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Desarrollo profesional (Professional Development)",
		"includes": "Desarrollo profesional (Professional Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Desarrollo web (Web Development)",
		"includes": "Desarrollo web (Web Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Diseño de anuncios y diseño creativo / Sitio web (Ad Design & Creative design/Website)",
		"includes": "Diseño de anuncios y diseño creativo / Sitio web (Ad Design & Creative design/Website)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71129,
		"description": "Diseño de circuitos integrados y diseño creativo (Integrated Circuit Design & Creative design)",
		"includes": "Diseño de circuitos integrados y diseño creativo (Integrated Circuit Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Diseño de interfaz de usuario y diseño creativo (User Interface Design & Creative design)",
		"includes": "Diseño de interfaz de usuario y diseño creativo (User Interface Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74101,
		"description": "Diseño de interiores y diseño creativo (Interior Design & Creative design)",
		"includes": "Diseño de interiores y diseño creativo (Interior Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74101,
		"description": "Diseño de logotipos, diseño creativo y branding (Logo Design & Creative design & Branding)",
		"includes": "Diseño de logotipos, diseño creativo y branding (Logo Design & Creative design & Branding)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74201,
		"description": "Diseño de presentación y diseño creativo (Presentation Design & Creative design)",
		"includes": "Diseño de presentación y diseño creativo (Presentation Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74101,
		"description": "Diseño de producto y diseño creativo (Product Design & Creative design)",
		"includes": "Diseño de producto y diseño creativo (Product Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74101,
		"description": "Diseño de redes sociales y diseño creativo (Social Media Design & Creative design)",
		"includes": "Diseño de redes sociales y diseño creativo (Social Media Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Diseño de seguridad y diseño creativo / programación (Security Design & Creative design/Programming)",
		"includes": "Diseño de seguridad y diseño creativo / programación (Security Design & Creative design/Programming)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Diseño de sitios web y diseño creativo (Website Design & Creative design)",
		"includes": "Diseño de sitios web y diseño creativo (Website Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74101,
		"description": "Diseño de tarjetas de consultoría empresarial y diseño creativo (Business Consulting Card Design & Creative design)",
		"includes": "Diseño de tarjetas de consultoría empresarial y diseño creativo (Business Consulting Card Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74101,
		"description": "Diseño gráfico 3D y diseño creativo (3D Graphic Design & Creative design)",
		"includes": "Diseño gráfico 3D y diseño creativo (3D Graphic Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59121,
		"description": "Diseño gráfico y diseño creativo (Graphic Design & Creative design)",
		"includes": "Diseño gráfico y diseño creativo (Graphic Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Diseño web y móvil y diseño creativo (Web & Mobile Design & Creative design)",
		"includes": "Diseño web y móvil y diseño creativo (Web & Mobile Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 58111,
		"description": "EBooks (eBooks)",
		"includes": "EBooks (eBooks)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82191,
		"description": "Edición y corrección (Editing & Proofreading)",
		"includes": "Edición y corrección (Editing & Proofreading)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Entrada de análisis y ciencia de datos (Data Science & Analytics Entry)",
		"includes": "Entrada de análisis y ciencia de datos (Data Science & Analytics Entry)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Entrenamiento y educación de la memoria (Memory Training & Education)",
		"includes": "Entrenamiento y educación de la memoria (Memory Training & Education)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82111,
		"description": "Envío masivo (Bulk mailing)",
		"includes": "Envío masivo (Bulk mailing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 90031,
		"description": "Escritura creativa y traducciones (Creative Writing & Translations)",
		"includes": "Escritura creativa y traducciones (Creative Writing & Translations)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 90031,
		"description": "Escritura y traducciones de artículos y blogs (Article & Blog Writing & Translations)",
		"includes": "Escritura y traducciones de artículos y blogs (Article & Blog Writing & Translations)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Estrategia de mercadeo (Marketing Strategy)",
		"includes": "Estrategia de mercadeo (Marketing Strategy)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Extracción de análisis y ciencia de datos / ETL (Data Science & Analytics Extraction / ETL)",
		"includes": "Extracción de análisis y ciencia de datos / ETL (Data Science & Analytics Extraction / ETL)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Financiamiento de riesgo (Venture Funding)",
		"includes": "Financiamiento de riesgo (Venture Funding)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62021,
		"description": "Formación técnica y educación (Technical Training & Education)",
		"includes": "Formación técnica y educación (Technical Training & Education)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Formación y educación en gestión (Management Training & Education)",
		"includes": "Formación y educación en gestión (Management Training & Education)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Formación y educación en ventas y marketing (Sales & Marketings Training & Education)",
		"includes": "Formación y educación en ventas y marketing (Sales & Marketings Training & Education)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74201,
		"description": "Fotografía (Photography)",
		"includes": "Fotografía (Photography)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74201,
		"description": "Fotografía comercial / publicitaria (Commercial/Advertising Photography)",
		"includes": "Fotografía comercial / publicitaria (Commercial/Advertising Photography)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74201,
		"description": "Fotografía de noticias (News Photography)",
		"includes": "Fotografía de noticias (News Photography)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74201,
		"description": "Fotografía periodística (Journalistic Photography)",
		"includes": "Fotografía periodística (Journalistic Photography)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74201,
		"description": "Fotografías / Diseño y diseños creativos (Photographs/Design & Creative designs)",
		"includes": "Fotografías / Diseño y diseños creativos (Photographs/Design & Creative designs)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Fusiones y adquisiciones (Mergers & Acquisitions)",
		"includes": "Fusiones y adquisiciones (Mergers & Acquisitions)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Generación líder (Lead Generation)",
		"includes": "Generación líder (Lead Generation)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Gestión de consultoría empresarial (Business Consulting Management)",
		"includes": "Gestión de consultoría empresarial (Business Consulting Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62031,
		"description": "Gestión de productos (Product Management)",
		"includes": "Gestión de productos (Product Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Gestión de proyectos (Project Management)",
		"includes": "Gestión de proyectos (Project Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Gestión de publicidad (Advertising Management)",
		"includes": "Gestión de publicidad (Advertising Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Gestión del tiempo (Time Management)",
		"includes": "Gestión del tiempo (Time Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Gestión técnica de proyectos (Technical Project Management)",
		"includes": "Gestión técnica de proyectos (Technical Project Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59121,
		"description": "Gráficos y vectores web (Web graphics and vectors)",
		"includes": "Gráficos y vectores web (Web graphics and vectors)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Habilidades de presentación (Presentation Skills)",
		"includes": "Habilidades de presentación (Presentation Skills)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59121,
		"description": "Ilustración (Illustration)",
		"includes": "Ilustración (Illustration)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69202,
		"description": "Informes financieros (Financial Reporting)",
		"includes": "Informes financieros (Financial Reporting)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71122,
		"description": "Ingeniería química y arquitectura (Chemical Engineering & Architecture)",
		"includes": "Ingeniería química y arquitectura (Chemical Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71129,
		"description": "Ingeniería y Arquitectura Aeroespacial (Aerospace Engineering & Architecture)",
		"includes": "Ingeniería y Arquitectura Aeroespacial (Aerospace Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71122,
		"description": "Ingeniería y Arquitectura Ambiental (Environmental Engineering & Architecture)",
		"includes": "Ingeniería y Arquitectura Ambiental (Environmental Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 42991,
		"description": "Ingeniería y Arquitectura Civil y Estructural (Civil & Structural Engineering & Architecture)",
		"includes": "Ingeniería y Arquitectura Civil y Estructural (Civil & Structural Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71129,
		"description": "Ingeniería y Arquitectura Eléctrica (Electrical Engineering & Architecture)",
		"includes": "Ingeniería y Arquitectura Eléctrica (Electrical Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71122,
		"description": "Ingeniería y Arquitectura Geotécnica (Geotechnical Engineering & Architecture)",
		"includes": "Ingeniería y Arquitectura Geotécnica (Geotechnical Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74901,
		"description": "Ingeniería y Arquitectura Industrial (Industrial Engineering & Architecture)",
		"includes": "Ingeniería y Arquitectura Industrial (Industrial Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74901,
		"description": "Ingeniería y Arquitectura Mecánica (Mechanical Engineering & Architecture)",
		"includes": "Ingeniería y Arquitectura Mecánica (Mechanical Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74901,
		"description": "Ingeniería y Arquitectura de Materiales (Material Engineering & Architecture)",
		"includes": "Ingeniería y Arquitectura de Materiales (Material Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71129,
		"description": "Ingeniería y arquitectura analógicas (Analog Engineering & Architecture)",
		"includes": "Ingeniería y arquitectura analógicas (Analog Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Investigación / planificación de mercado (Market Research/Planning)",
		"includes": "Investigación / planificación de mercado (Market Research/Planning)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 63121,
		"description": "Investigación de la Web (Web Research)",
		"includes": "Investigación de la Web (Web Research)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Investigación de mercado y servicio al cliente (Market & Customer Service Research)",
		"includes": "Investigación de mercado y servicio al cliente (Market & Customer Service Research)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Juegos de computadora (Computer Games)",
		"includes": "Juegos de computadora (Computer Games)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 58291,
		"description": "Juegos en línea (Online games)",
		"includes": "Juegos en línea (Online games)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 72191,
		"description": "La investigación de operaciones (Operations Research)",
		"includes": "La investigación de operaciones (Operations Research)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Lectura veloz (Speed Reading)",
		"includes": "Lectura veloz (Speed Reading)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62021,
		"description": "Lenguajes de programación / herramientas (Programming Languages/Tools)",
		"includes": "Lenguajes de programación / herramientas (Programming Languages/Tools)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69109,
		"description": "Ley de Propiedad Intelectual (Intellectual Property Law)",
		"includes": "Ley de Propiedad Intelectual (Intellectual Property Law)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69103,
		"description": "Ley familiar (Family Law)",
		"includes": "Ley familiar (Family Law)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Manejo de conflictos (Conflict Management)",
		"includes": "Manejo de conflictos (Conflict Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Manejo del estrés (Stress Management)",
		"includes": "Manejo del estrés (Stress Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Marketing / publicidad de base (Grassroot Marketing/Advertising)",
		"includes": "Marketing / publicidad de base (Grassroot Marketing/Advertising)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Marketing de influencers (Influencer Marketing)",
		"includes": "Marketing de influencers (Influencer Marketing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Marketing de servicios digitales (Digital Marketing services)",
		"includes": "Marketing de servicios digitales (Digital Marketing services)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "Marketing directo / Publicidad (Direct Marketing/Advertising)",
		"includes": "Marketing directo / Publicidad (Direct Marketing/Advertising)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 0,
		"description": "Mi actividad no está en la lista (My activity is not listed)",
		"includes": "Mi actividad no está en la lista (My activity is not listed)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Minería y gestión de ciencia y análisis de datos (Data Science & Analytics Mining & Management)",
		"includes": "Minería y gestión de ciencia y análisis de datos (Data Science & Analytics Mining & Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71111,
		"description": "Modelado 3D y CAD (3D Modeling & CAD)",
		"includes": "Modelado 3D y CAD (3D Modeling & CAD)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71111,
		"description": "Modelos 3D y diseño de producto y diseño creativo (3D Models & Product Design & Creative design)",
		"includes": "Modelos 3D y diseño de producto y diseño creativo (3D Models & Product Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Orientación profesional (Career Advice)",
		"includes": "Orientación profesional (Career Advice)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69202,
		"description": "Otra Contabilidad y Financiamiento y Finanzas (Other Accounting & Financing & Finance)",
		"includes": "Otra Contabilidad y Financiamiento y Finanzas (Other Accounting & Financing & Finance)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Otra ciencia de datos y análisis Ciencia y análisis (Other Data Science & Analytics Science & Analytics)",
		"includes": "Otra ciencia de datos y análisis Ciencia y análisis (Other Data Science & Analytics Science & Analytics)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69109,
		"description": "Otra consultoría legal (Other Legal Consulting)",
		"includes": "Otra consultoría legal (Other Legal Consulting)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74901,
		"description": "Otra ingeniería y arquitectura (Other Engineering & Architecture)",
		"includes": "Otra ingeniería y arquitectura (Other Engineering & Architecture)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62031,
		"description": "Otras TI y redes (Other IT & Networking)",
		"includes": "Otras TI y redes (Other IT & Networking)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Otras consultorías comerciales (Other Business Consulting)",
		"includes": "Otras consultorías comerciales (Other Business Consulting)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82191,
		"description": "Otras escrituras y traducciones (Other Writing & Translations)",
		"includes": "Otras escrituras y traducciones (Other Writing & Translations)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Otras ventas, marketing y marketing (Other Sales & Marketings & Marketing)",
		"includes": "Otras ventas, marketing y marketing (Other Sales & Marketings & Marketing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Otro desarrollo de software (Other Software Development)",
		"includes": "Otro desarrollo de software (Other Software Development)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59121,
		"description": "Otro diseño y diseño creativo y creativo (Other Design & Creative design & Creative)",
		"includes": "Otro diseño y diseño creativo y creativo (Other Design & Creative design & Creative)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82991,
		"description": "Otro soporte administrativo (Other Admin support)",
		"includes": "Otro soporte administrativo (Other Admin support)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Otro- Formación y educación (Other- Training & Education)",
		"includes": "Otro- Formación y educación (Other- Training & Education)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62091,
		"description": "Otros servicios electrónicos (Other- E-services)",
		"includes": "Otros servicios electrónicos (Other- E-services)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62091,
		"description": "Otros- Servicios de servicios digitales (Other- Digital services)",
		"includes": "Otros- Servicios de servicios digitales (Other- Digital services)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 58291,
		"description": "Pantalla / Escritura de guiones y traducciones / Edición (Screen/Script Writing & Translations/Editing)",
		"includes": "Pantalla / Escritura de guiones y traducciones / Edición (Screen/Script Writing & Translations/Editing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59201,
		"description": "Pistas de musica (Music tracks)",
		"includes": "Pistas de musica (Music tracks)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82111,
		"description": "Planificacion Financiera (Financial Planning)",
		"includes": "Planificacion Financiera (Financial Planning)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Planificación / gestión de fabricación (Manufacturing Planning/Management)",
		"includes": "Planificación / gestión de fabricación (Manufacturing Planning/Management)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Planificación de consultoría empresarial (Business Consulting Planning)",
		"includes": "Planificación de consultoría empresarial (Business Consulting Planning)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69202,
		"description": "Planificación fiscal (Tax Planning)",
		"includes": "Planificación fiscal (Tax Planning)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 58291,
		"description": "Plantillas (por ejemplo, correos electrónicos, sitios web, etc.) (Templates (e.g. e-mails, websites etc))",
		"includes": "Plantillas (por ejemplo, correos electrónicos, sitios web, etc.) (Templates (e.g. e-mails, websites etc))",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59201,
		"description": "Podcasts (Podcasts)",
		"includes": "Podcasts (Podcasts)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Presupuesto / Previsión (Budgeting/Forecasting)",
		"includes": "Presupuesto / Previsión (Budgeting/Forecasting)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82191,
		"description": "Procesamiento de textos (Word Processing)",
		"includes": "Procesamiento de textos (Word Processing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59201,
		"description": "Producción de audio (Audio Production)",
		"includes": "Producción de audio (Audio Production)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59111,
		"description": "Producción de vídeo (Video Production)",
		"includes": "Producción de vídeo (Video Production)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Programación ERP / CRM (ERP/CRM Programming)",
		"includes": "Programación ERP / CRM (ERP/CRM Programming)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74201,
		"description": "Rastreo de vectores (Vector Tracing)",
		"includes": "Rastreo de vectores (Vector Tracing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 78301,
		"description": "Recursos humanos (Human Resources)",
		"includes": "Recursos humanos (Human Resources)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71121,
		"description": "Redacción CAD / Diseño y diseño creativo (CAD Drafting/Design & Creative design)",
		"includes": "Redacción CAD / Diseño y diseño creativo (CAD Drafting/Design & Creative design)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82191,
		"description": "Redacción académica, traducciones e investigación (Academic Writing & Translations & Research)",
		"includes": "Redacción académica, traducciones e investigación (Academic Writing & Translations & Research)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82191,
		"description": "Redacción médica y traducciones / edición (Medical Writing & Translations/Editing)",
		"includes": "Redacción médica y traducciones / edición (Medical Writing & Translations/Editing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 58291,
		"description": "Redacción técnica y traducciones (Technical Writing & Translations)",
		"includes": "Redacción técnica y traducciones (Technical Writing & Translations)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82191,
		"description": "Redacción y traducción / edición de boletines (Newsletters Writing & Translations/Editing)",
		"includes": "Redacción y traducción / edición de boletines (Newsletters Writing & Translations/Editing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Relaciones con inversionistas (Investor Relations)",
		"includes": "Relaciones con inversionistas (Investor Relations)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70211,
		"description": "Relaciones públicas (Public Relations)",
		"includes": "Relaciones públicas (Public Relations)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "SEM - Ingeniería de búsqueda y marketing (SEM - Search Engineering & Marketing)",
		"includes": "SEM - Ingeniería de búsqueda y marketing (SEM - Search Engineering & Marketing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 94129,
		"description": "SEO: optimización e ingeniería de búsqueda (SEO - Search Engineering & Optimization)",
		"includes": "SEO: optimización e ingeniería de búsqueda (SEO - Search Engineering & Optimization)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 73111,
		"description": "SMM: marketing en redes sociales (SMM - Social Media Marketing)",
		"includes": "SMM: marketing en redes sociales (SMM - Social Media Marketing)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Scripts y utilidades (Scripts & Utilities)",
		"includes": "Scripts y utilidades (Scripts & Utilities)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Seminarios web (Webinars)",
		"includes": "Seminarios web (Webinars)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82201,
		"description": "Servicio al Cliente (Customer Service)",
		"includes": "Servicio al Cliente (Customer Service)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 58291,
		"description": "Servicios VPN (VPN services)",
		"includes": "Servicios VPN (VPN services)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 66191,
		"description": "Servicios de CFO (CFO Services)",
		"includes": "Servicios de CFO (CFO Services)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69109,
		"description": "Servicios de consultoría ParaLegal (ParaLegal Consulting Services)",
		"includes": "Servicios de consultoría ParaLegal (ParaLegal Consulting Services)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 70221,
		"description": "Servicios de marca (Branding Services)",
		"includes": "Servicios de marca (Branding Services)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 63111,
		"description": "Servicios web (Web services)",
		"includes": "Servicios web (Web services)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "Soporte de TI (IT support)",
		"includes": "Soporte de TI (IT support)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82191,
		"description": "Subvenciones / Propuestas (Grants/Proposals)",
		"includes": "Subvenciones / Propuestas (Grants/Proposals)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Superación personal (Self Improvement)",
		"includes": "Superación personal (Self Improvement)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74901,
		"description": "Talento de voz (Voice Talent)",
		"includes": "Talento de voz (Voice Talent)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 85599,
		"description": "Team Building (Team Building)",
		"includes": "Team Building (Team Building)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82201,
		"description": "Telemarketing y Televentas y marketing (Telemarketing & TeleSales & Marketings)",
		"includes": "Telemarketing y Televentas y marketing (Telemarketing & TeleSales & Marketings)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 47191,
		"description": "Tienda online, comercio electrónico, Logística de Amazon o dropshipping (Online shop, e-Commerce, FBA or dropshipping)",
		"includes": "Tienda online, comercio electrónico, Logística de Amazon o dropshipping (Online shop, e-Commerce, FBA or dropshipping)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 72191,
		"description": "Trabajos de investigación (Research papers)",
		"includes": "Trabajos de investigación (Research papers)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74301,
		"description": "Traducción (Translation)",
		"includes": "Traducción (Translation)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 82191,
		"description": "Transcripción (Transcription)",
		"includes": "Transcripción (Transcription)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 69201,
		"description": "Valoración de empresas (Company Valuation)",
		"includes": "Valoración de empresas (Company Valuation)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 74201,
		"description": "Videografía comercial / publicitaria (Commercial/Advertising Videography)",
		"includes": "Videografía comercial / publicitaria (Commercial/Advertising Videography)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 59111,
		"description": "Videos educativos (Educational videos)",
		"includes": "Videos educativos (Educational videos)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 71209,
		"description": "Visualización de análisis y ciencia de datos (Data Science & Analytics Visualization)",
		"includes": "Visualización de análisis y ciencia de datos (Data Science & Analytics Visualization)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	},
	{
		"code": 62011,
		"description": "WordPress (WordPress)",
		"includes": "WordPress (WordPress)",
		"allowed": true,
		"needs_license": false,
		"warning": ""
	}
]

export default emtak