import {useStripe, useElements, Elements, PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import toast from 'react-hot-toast'
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import CompanioButton from "../../common/components/CompanioButton";
import {withErrorBoundary} from "react-error-boundary";
import {Avatar, Divider, Button} from "@mui/material";
import {useSelector} from "react-redux";
import {userSelector} from "../User/UserSlice";
import {getInitials} from "../../common/utils/utils";
import {FormattedMessage} from "react-intl";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || "");


const AddPaymentMethod = () => {
  const navigate = useNavigate()
  const { setup_intent } = useParams()

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between md:mb-12">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="billing"/></p>
      <LoggedInUserMenu />
    </div>
    <div className="flex items-start w-full">
      <Button variant="outlined"
              sx={{mr: "16px", color:"#9FA2B4", borderRadius: "8px"}}
              onClick={() => navigate('/billing')}>
        Billing
      </Button>
      <Button variant="contained"
              sx={{color: "#fff", borderRadius: "8px", backgroundColor: "#253292"}}
              onClick={() => window.location.reload()}>
        Add Card
      </Button>
    </div>

    <Elements stripe={stripePromise} options={{ clientSecret: setup_intent }}>
      <CheckoutForm/>
    </Elements>

  </div>
}

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate()
  const {user} = useSelector(userSelector)

  const name: string = user.first_name + ' ' + user.last_name
  const initials: string = getInitials(name)

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });


    if (result.error) {
      if (result.error.message) {
        toast(result.error.message);
      }
      return
    }

    navigate('/billing')
  };

  return (
      <div className="border border-solid mt-5 p-5" style={{borderColor: "#DFE0EB", borderRadius: "8px"}}>
        <div className="flex md:mt-3 md:ml-3 justify-between">
          <div className="flex">
            <div>
              <Avatar sx={{width: 48, height: 48}}
                      src={process.env.REACT_APP_API_URL + '/' + user.avatar}>{initials}
              </Avatar>
            </div>
            <div className="text-left">
              <p className="md:ml-4" style={{fontSize: "12px", color: "#9FA2B4"}}>{name}</p>
              <p className="md:ml-4" style={{fontSize: "16px", color: "#252733"}}>{user.company.name}</p>
            </div>
          </div>
          <div className="flex">
            <CompanioButton variant="outlined" size="small" className="h-10"> Loren ipsum </CompanioButton>
          </div>
        </div>
        <div className="flex md:mt-8">
          <div className="flex-col text-left uppercase mr-8 ml-3 md:w-80">
            <p style={{color:"#9FA2B4", fontSize: "12px"}}>Lorem ipsum dolor sit amet</p>
            <p className="">0</p>
          </div>
          <div className="flex-col text-left uppercase">
            <p style={{color:"#9FA2B4", fontSize: "12px"}}>Lorem ipsum dolor sit amet</p>
            <p className="">0</p>
          </div>
        </div>
        <Divider sx={{mt: '33px'}} />
        <form onSubmit={handleSubmit} className="mt-10">
          <PaymentElement />
          <div className="flex justify-start mt-10">
            <CompanioButton disabled={!stripe} variant="contained" type="submit">Save Payment method</CompanioButton>
          </div>
        </form>
      </div>
  )
};

export default withErrorBoundary(
  AddPaymentMethod,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })