import {Children} from 'react';
import {Menu as MUIMenu, MenuItem} from '@mui/material';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {withErrorBoundary} from 'react-error-boundary';
import * as React from "react";

// Pass prop horizontal for horizontal icon e.g. <Menu horizontal>

// Children accept sx, onClick, className
// <div sx={{color: 'red'}} onClick={() => console.log("primary")}>Set as primary</div>

type MenuProps = {
    id?: string;
    children: any;
    sx?: object;
    horizontal?: boolean;
}

const Menu = ({children, sx, horizontal}: MenuProps) => {
  const arrayChildren = Children.toArray(children)

  const handleClick = (onClickF: Function) => {
    if (onClickF) {
      onClickF()
    }
  }

  const MenuIcon = horizontal ? MoreHorizIcon : MoreVertIcon

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <MenuIcon sx={{color: "gray", cursor: 'pointer', ...sx}} {...bindTrigger(popupState)} />
          <MUIMenu {...bindMenu(popupState)}>
            {arrayChildren.map((child: any, i: number) =>
              <MenuItem
                key={i}
                sx={child.props.style}
                classes={child.props.className}
                onClick={() => handleClick(child.props.onClick)}>
                {child.props.children}
              </MenuItem>)}
          </MUIMenu>
        </>
      )}
    </PopupState>
  );
}

export default withErrorBoundary(
  Menu,
  {
    FallbackComponent: () => <div>Menu failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })