import {withErrorBoundary} from "react-error-boundary";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from "react-intl";
import {
  clearState,
} from '../Banks/BanksSlice';
import toast from "react-hot-toast";
import {getBusinessTravelsPaginated, travelsSelector} from "./TravelsSlice";
import CompanioButton from "../../common/components/CompanioButton";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import SearchFilter from "../../common/components/SearchFilter";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Menu from "../../common/components/Menu";
import Pagination from "../../common/components/Pagination";
import {useNavigate} from "react-router-dom";
import {countriesData} from "../../common/utils/countriesData";

const Travels = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {travelsData, isError, errorMessage} = useSelector(travelsSelector)
  const intl = useIntl()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    search: ''
  });

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);

  useEffect(() => {
    dispatch(getBusinessTravelsPaginated({
      page: page,
      limit: limit,
      filters: filters
    }) as any);
  }, [dispatch, filters, limit, page])

  const formattedDate = (date: string) => {
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
    const day = new Date(date).getDate()
    const month = new Date(date).getMonth()
    const year = new Date(date).getFullYear()

    return `${months[month]}/${day}/${year}`
  }

  const badgeForStatus = (status: string) => {
    switch (status) {
      case 'approved':
        return 'border rounded-lg bg-[#00DA8B] text-white uppercase'
      case 'pending':
        return 'border rounded-lg bg-[#FEA500] text-white uppercase'
      case 'rejected':
      case 'canceled':
        return 'border rounded-lg bg-red-700 text-white uppercase'
      case 'completed':
        return 'border rounded-lg bg-blue-700 text-white uppercase'
    }
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="travels"/></p>
      <LoggedInUserMenu />
    </div>
    <div className="flex flex-col w-full border rounded-lg my-6">
      <div className="flex justify-between items-center px-6 py-4">
        <p className="text-lg font-LaNord font-bold text-[#252733]">{intl.formatMessage({id: "all_travels", defaultMessage:"All travels"})}</p>
        <div className="flex justify-items-center items-center">
          <SearchFilter filters={filters} setFilters={setFilters} />
          <CompanioButton size="small" variant="contained" className="w-32 h-[40px]" onClick={() => navigate('/travels/add_travel')}>
            <FormattedMessage id="add_new" />
          </CompanioButton>
        </div>
      </div>
      <TableContainer sx={{height: "350px"}}>
        <Table
          sx={{minWidth: 550, width: "100%"}}
          aria-labelledby="tableTitle"
          size='medium'
        >
          <TableHead className="p-4">
            <TableRow>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4] pl-4"><FormattedMessage id="employee"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="source"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="destination"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="date"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="status"/></div></TableCell>
              <TableCell align="left" ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {travelsData.travels && travelsData.travels.map((travel: any, i: any) => <TableRow
              // hover
              role="checkbox"
              tabIndex={-1}
              key={i}
            >
              <TableCell
                component="th"
                scope="col"
                sx={{paddingLeft:4, paddingRight: 4}}
              >
                <div className="text-sm text-left font-bold text-[#253292]">
                  {travel.employee ? `${travel.employee.first_name} ${travel.employee.last_name}`  : (travel.board_member ? `${travel.board_member.first_name} ${travel.board_member.last_name}` : `unknown_name`)}
                </div>
                <div className="text-xs font-LaNord text-[#C5C7CD] pt-1">
                  {travel.employee ?
                    intl.formatMessage({id: "employee", defaultMessage: "Employee"})
                    : intl.formatMessage({id: "board_member", defaultMessage:"Board Member"})}
                </div>
              </TableCell>
              <TableCell>
                <div className="text-sm text-left font-bold text-[#253292]">
                  {getCountryData(travel.origin)}
                </div>
              </TableCell>
              <TableCell>
                <div className="text-sm text-left font-bold text-[#253292]">
                  {getCountryData(travel.destination)}
                </div>
              </TableCell>
              <TableCell align="left" >
                <div className="text-sm text-left text-[#253292]">{`From: ${formattedDate(travel.start_date)}`}</div>
                <div className="text-sm text-left text-[#253292]">{`To: ${formattedDate(travel.end_date)}`}</div>
              </TableCell>
              <TableCell align="left">
                <div className={`text-xs text-center font-LaNord p-1 ${badgeForStatus(travel.status)}`}>
                  <FormattedMessage id={travel.status} defaultMessage={travel.status}/>
                </div>
              </TableCell>
              <TableCell align="left">
                <Menu sx={{}}>
                  <div><FormattedMessage id="see_details"/></div>
                </Menu>
              </TableCell>
            </TableRow>)}

          </TableBody>
        </Table>
      </TableContainer>
      <div>

        <Pagination className="mt-4"
                    requestPaginationData={travelsData}
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setPage={setPage}
        />
      </div>
    </div>
  </div>
}

const getCountryData = (country: string) => {
  const countryData: any = countriesData.find((c) => c.code === country)
  return (
    <>
      <p>{countryData.name}</p>
      <img src={countryData.icon}/>
    </>
)
}

export default withErrorBoundary(
  Travels,
  {
    FallbackComponent: () => <div>Error to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })