import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {loginUser, userSelector, clearState, clearEmailSent} from './UserSlice';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {withErrorBoundary} from 'react-error-boundary';

import {Button, TextField, Box, Modal, MenuItem} from '@mui/material';
import SelectUserInput from "../../common/components/SelectUserInput";
import CompanioButton from "../../common/components/CompanioButton";
import {CheckBoxComponent} from "../../common/components/CheckBoxComponent";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {options, emailSent, isError, errorMessage} = useSelector(
    userSelector
  );
  const [modal, setModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [email, setEmail] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const intl = useIntl()

  const onSubmit = (e: any) => {
    e.preventDefault()
    dispatch(loginUser({email, rememberMe}) as any);
  };
  const handleModal = () => {
    setModal(true);
  }
  const handleSelectedUser = (e: any) => {
      setSelectedOption(e.target.value);
  }

  const handleClickCancel = () => {
      setModal(false);
      dispatch(clearState());
  }

  const handleClickOkay = () => {
      setModal(false);
      const option = options.find((op: any) => op.name === selectedOption)
      dispatch(loginUser({email, userId: option.id, rememberMe}) as any);
  }

  useEffect(() => {
      if(options.length > 1) {
          handleModal()
      }
  }, [options])

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
    if (emailSent) {
      toast.success(intl.formatMessage({id: "we_sent_you_magic_link_html"}))
      setTimeout(() => dispatch(clearEmailSent()), 5000);
    }
  }, [dispatch, isError, errorMessage, navigate, emailSent, intl]);

  useEffect(() => {
      dispatch(clearState());
  }, [dispatch]);

    return <div className="flex min-h-screen">
    <div className="flex-1 flex flex-col justify-between ml-8 p-5 md:p-10" >
        <div>
            <img src="/assets/images/companio-logo.png" alt="logo" width="149" style={{maxHeight: "40px"}}/>
        </div>

      <div>
          <div className="pr-16">
              <p className="text-4xl font-extrabold text-left mb-5" style={{ color: '#253292' }}>
                  <FormattedMessage id="welcome" defaultMessage="Welcome" />
              </p>
              <p className="mt-5 mb-10 text-left" style={{color: '#9FA2B4'}}>
                  <FormattedMessage id="enter_name_representative" defaultMessage=""/>
              </p>
          </div>

        <Box component="form"
             onSubmit={(e) => onSubmit(e)} noValidate sx={{mt: 1}}>
          <TextField
            sx={{
                marginBottom: '24px',
                width: '86%',
                display: 'flex',
                align: 'left',
                padding: '0px',
                borderRadius: '8px',

            }}
            onChange={e => setEmail(e.target.value)}
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            label={<FormattedMessage id="enter_your_email" defaultMessage="Enter your email"/>}
            autoComplete="email"
            autoFocus
            size="small"
          />
          <CompanioButton
            className="w-64 h-12"
            type="submit"
            variant="contained"
            disabled={emailSent}
          >
            <FormattedMessage id="get_magic_link" defaultMessage="Get Magic Link"/>
          </CompanioButton>
            <CheckBoxComponent checked={rememberMe} label={'keep_me_logged_in'} defaultMessage={"Remember me"} onChange={(e: any) => setRememberMe(e.target.checked)}/>
            <div className="flex justify-start mt-5 mb-16" style={{fontSize: '14px', color: '#9FA2B4'}}>
                <FormattedMessage id="dont_have_an_account" defaultMessage="Don't have an account?" />
                <a className="pl-1 cursor-pointer" onClick={() => navigate('/register')}><FormattedMessage id="sing_up" defaultMessage="Sign up" /></a>
            </div>
            {/*has to be here because of flex */}
          <Modal open={modal}
                 sx={{
                   height: '45%',
                   width: '45%',
                   margin: 'auto',
                   display: 'flex',
                   justifyContent: 'center',
                   borderRadius: '12px',
                 }}>
            <Box
                className="p-8"
                component="form"
                sx={{
                  borderRadius: '12px',
                  backgroundColor: 'white',
                  width: '100%',
                  height: '100%'
                }}>
              <div>
                <div className="flex justify-center text-xl text-center" style={{color: '#9FA2B4'}}>
                    <FormattedMessage id="several_users_email" defaultMessage="There are several accounts registered for that email. Please, choose one."/>
                </div>
                <div className="md:flex justify-center md:mt-8 md:mb-8">
                  <SelectUserInput id="select_user" value={selectedOption} onChange={(e:React.SyntheticEvent) => handleSelectedUser(e)} label='Select one user' className="md:ml-3 w-full mt-5" >
                      {options ? options.map((option: any) => (
                          <MenuItem key={option.id} id={option.id} value={option.name}>
                              {' '}
                              {option.name}{' '}
                          </MenuItem>
                      )): <MenuItem value="none"> None </MenuItem>}
                  </SelectUserInput>
                </div>
                  <div className="flex items-center justify-end" style={{ padding: '18px' }}>
                      <Button
                          onClick={(e) => {
                            handleClickCancel()
                          }}
                          sx={{
                              maxWidth: '95px',
                              borderRadius: '31px',
                              width: '100%',
                              background: 'none',
                              color: '#9FA2B4',
                              fontFamily: 'TEEE',
                              fontSize: '14px',
                              fontWeight: 500,
                              textTransform: 'none',
                              marginRight: '10px',
                              ':hover': {
                                  backgroundColor: 'none'
                              }
                          }}>
                          <FormattedMessage id="cancel" defaultMessage="Cancel" />
                      </Button>
                      <Button
                          onClick={(e) => {
                              handleClickOkay()
                          }}
                          sx={{
                              maxWidth: '95px',
                              width: '100%',
                              borderRadius: '31px',
                              backgroundColor: '#FF4EA1',
                              fontFamily: 'TEEE',
                              color: 'white',
                              fontSize: '14px',
                              fontWeight: 500,
                              textTransform: 'none',
                              ':hover': {
                                  backgroundColor: '#FF4EA1',
                                  opacity: 0.7
                              },
                              ':disabled': {
                                  color: 'white',
                                  opacity: 0.3
                              }
                          }}>
                          <p>Okay</p>
                      </Button>
                  </div>
              </div>
            </Box>
          </Modal>
        </Box>
      </div>
    </div>

    <div className="flex-1 hidden md:flex items-center justify-center"
         style={{
           background: 'linear-gradient(109.4deg, #9A53CB 1.26%, #FF4EA1 96.4%)',
           padding: '40px'
         }}>
      <img src="/assets/images/login-people.png" alt="people"/>
    </div>
  </div>

};

export default withErrorBoundary(
  Login,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
