import React from 'react';
import './App.css';
import {Routes, Route} from 'react-router-dom';
import Login from './features/User/Login';
import LoginConfirm from "./features/User/LoginConfirm";
import Index from './features';
import Layout from "./common/components/Layout";
import Sales from "./features/Sales/Sales";
import Purchases from "./features/Purchases/Purchases"
import Billing from "./features/Billing/Billing"
import Customers from "./features/Customers/Customers"
import BankMovements from "./features/BankMovements/BankMovements";
import AddCustomer from "./features/Customers/AddCustomer"
import AddPaymentMethod from "./features/Billing/AddPaymentMethod";
import Notifications from "./features/Notifications/Notifications";
import Logout from "./features/User/Logout";
import Users from "./features/Settings/Users";
import NoMatch404Page from "./common/components/NoMatch404Page";
import Reports from "./features/Reports/Reports"
import AddUser from "./features/User/AddUser";
import Settings from "./features/Settings/Settings";
import Profile from "./features/Settings/Profile";
import Other from "./features/Settings/Other";
import Security from "./features/Settings/Security";
import EmailAutomation from "./features/Settings/EmailAutomation";
import {default as NotificationSettings} from "./features/Settings/Notifications";
import Tickets from "./features/Tickets/Tickets";
import Ticket from "./features/Tickets/Ticket";
import Banks from "./features/Banks/Banks";
import AddBank from "./features/Banks/AddBank";
import Register from "./features/Register/Register";
import RegisterSteps from "./features/Register/RegisterSteps";
import RegistrationPayment from "./features/Payment/RegistrationPayment";
import RegistrationPaymentStatus from "./features/Payment/RegistrationPaymentStatus";
import RegisterThankYou from "./features/Register/RegisterThankYou";
import RegisterOnboarding from "./features/Register/RegisterOnboarding";
import VerifyDocuments from "./features/Register/VerifyDocuments";
import AddBankAccount from "./features/Register/AddBankAccount";
import CreteSales from  "../src/features/Sales/CreateSales";
import UploadInvoice from "./features/Sales/UploadInvoice";
import Customer from "./features/Customers/Customer";
import AddBankList from "./features/Banks/AddBankList";
import AddLocalBank from "./features/Banks/AddLocalBank";
import Travels from "./features/Travels/Travels";
import AddTravel from "./features/Travels/AddTravel";
import VirtualOffice from "./features/VirtualOffice/VirtualOfiice";
import Report from "./features/Reports/Report";
import Employees from "./features/Employee/Employees";
import HelpVideos from "./features/HelpVideo/HelpVideos";

const App = () => {
  return (
    <div className="App">
      <Routes>

        <Route path="register">
          <Route element={<Register/>} index />
          <Route element={<RegisterSteps/>} path='steps/:token/:email'/>
          <Route element={<RegistrationPayment/>} path='payment/:data'/>
          <Route element={<RegistrationPaymentStatus/>} path='payment/status/:payment_intent_id'/>
          <Route element={<RegisterThankYou/>} path='thank_you'/>
          <Route element={<RegisterOnboarding/>} path='onboarding'/>
          <Route element={<VerifyDocuments/>} path='verify_documents'/>
          <Route element={<AddBankAccount/>} path='bank_account'/>
        </Route>

        <Route path="login">
          <Route element={<Login/>} index/>
          <Route element={<LoginConfirm/>} path="confirm/*"/>
          <Route element={<LoginConfirm/>} path="confirm"/>
        </Route>

        <Route element={<Layout/>} path="/">
          <Route element={<Index/>} index/>
          <Route path='/help_videos'>
            <Route element={<HelpVideos/>} index />
          </Route>


          <Route path='/customers'>
            <Route element={<Customers/>} index/>
            <Route element={<AddCustomer/>} path='add_customer/:customer_id'/>
            <Route element={<AddCustomer/>} path='add_customer'/>
            <Route element={<Customer />} path='customer' />
            <Route element={<Customer />} path=':customer_id' />
          </Route>

          <Route element={<Sales/>} path='/sales'/>

          <Route element={<Purchases/>} path='/purchases'/>

          <Route path={'/banks'}>
            <Route element={<Banks/>} index/>
            <Route element={<AddBank/>} path='add_bank'/>
            <Route element={<AddBankList />} path='add_bank_list' />
            <Route element={<AddLocalBank />} path='add_local_bank' />
          </Route>

          <Route element={<BankMovements/>} path='/bank_movements'/>

          <Route path={'/reports'}>
            <Route element={<Reports/>} index />
            <Route element={<Report/>} path='/reports/report/:id' />
          </Route>

          <Route path={'/employees'}>
            <Route element={<Employees />} index />
          </Route>

          <Route path={'/virtual_office'}>
            <Route element={<VirtualOffice />} index />
            <Route element={<VirtualOffice />} path='/virtual_office/:param' />
            <Route element={<VirtualOffice />} path='/virtual_office/add/:param' />
          </Route>

          <Route path={'/travels'}>
            <Route element={<Travels />} index />
            <Route element={<AddTravel />} path='/travels/add_travel' />
          </Route>

          <Route path='/billing'>
            <Route element={<Billing/>} index/>
            <Route element={<AddPaymentMethod/>} path='add_payment_method/:setup_intent'/>
          </Route>

          <Route path='/tickets'>
            <Route element={<Tickets/>} index/>
            <Route element={<Ticket/>} path=':ticket_id'/>
          </Route>

          <Route path='/sales'>
            <Route element={<Sales />} index/>
            <Route element={<CreteSales />} path={'create-sales'} />
            <Route element={<UploadInvoice />} path={'upload-invoice'} />
          </Route>

          <Route element={<Settings/>} path='settings'>
            <Route element={<Profile/>} index/>

            <Route element={<NotificationSettings/>} path='notifications'/>

            <Route element={<EmailAutomation/>} path='email_automation'/>

            <Route element={<Security/>} path='security'/>

            <Route element={<Users/>} path='users'/>
            <Route element={<AddUser/>} path='add_user/:selected_user_id/'/>
            <Route element={<AddUser/>} path='add_user'/>

            <Route element={<Other/>} path='other'/>
          </Route>

          <Route element={<Logout/>} path='/logout'/>
          <Route element={<Notifications/>} path='/notifications'/>

          <Route path="*" element={<NoMatch404Page/>}/>
        </Route>

        <Route path="*" element={<NoMatch404Page/>}/>

      </Routes>
    </div>
  );
}

export default App;
