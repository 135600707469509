import {withErrorBoundary} from "react-error-boundary";
import React from "react";
import {currencyData} from "../../common/utils/currencyData";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CompanioButton from "../../common/components/CompanioButton";

const AddBank = () => {
  return (<>
      <div className="flex gap-6 mt-6 px-7">
        <div className="flex flex-col items-start w-6/12">
          <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">BANK NAME</div>
          <input placeholder="Your email" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                 value={''}
                 // onChange={handleInputChange}
                 required
                 id="email"
          />
        </div>
        <div className="flex flex-col items-start w-6/12">
          <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">BANK ACCOUNT HOLDER NAME</div>
          <input placeholder="Your phone number" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                 // onChange={handleInputChange}
                 value={''}
                 id="phone"
                 required
          />
        </div>
      </div>
      <div className="flex gap-6 mt-6 px-7">
        <div className="flex flex-col items-start w-6/12">
          <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">IBAN OR ACCOUNT NUMBER</div>
          <input placeholder="Your email" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                 value={''}
              // onChange={handleInputChange}
                 required
                 id="email"
          />
        </div>
        <div className="flex flex-col items-start w-6/12">
          <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">CURRENCY</div>
          <div className="flex border rounded-lg px-4 bg-[#FCFDFE] justify-between justify-items-center items-center mt-2 w-full">
            <select name="Currency" className="h-[40px] appearance-none text-sm font-LaNord text-[#9FA2B4] w-full"
                    id="currency"
            >
              {currencyData.map((c, i) => {
                return <option className="text-left" value={c.name} key={i}>{c.name}</option>
              })}
            </select>
            <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
          </div>
        </div>
      </div>
      <div className="flex justify-end border-t p-6 mt-6">
        <button className="font-bold font-LaNord text-[#9FA2B4] text-sm pr-4">Cancel</button>
        <CompanioButton size="small" variant="contained" className="w-32 h-[40px]"
                        >Next</CompanioButton>
      </div>
    </>
  )
};

export default withErrorBoundary(
  AddBank,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
