import {withErrorBoundary} from "react-error-boundary";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import {FormattedDate, FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTicket, addMessage, ticketsSelector, clearState} from "./TicketsSlice";
import {
  Avatar,
  Box, Button,
  Container, Divider
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {useNavigate, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {getInitials, readFileAsBase64} from "../../common/utils/utils";
import {userSelector} from "../User/UserSlice";
import {EditorState} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertToHTML} from 'draft-convert';
import Cookies from "js-cookie";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckIcon from '@mui/icons-material/Check';
import EastIcon from '@mui/icons-material/East';
import Menu from "../../common/components/Menu";

const Ticket = () => {
  const dispatch = useDispatch()
  const {ticket_id} = useParams()
  const navigate = useNavigate()
  const {isError, errorMessage, isSuccess, selectedTicket} = useSelector(ticketsSelector)
  const {user: loggedInUser} = useSelector(userSelector)

  const [fileToUpload, setFileToUpload] = useState<any>(emptyFileData)

  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );

  useEffect(() => {
    console.log('SelectedTicket--------->' , selectedTicket)
  } ,[selectedTicket]) ;

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
    if (isSuccess) {
      toast.error('Message sent!');
      dispatch(clearState());
      setFileToUpload(emptyFileData)
    }
  }, [dispatch, isError, errorMessage, navigate, isSuccess]);

  useEffect(() => {
    dispatch(getTicket(ticket_id) as any);
  }, [dispatch, ticket_id]);

  if (!selectedTicket) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  const fileSelectedHandler = async (event: any) => {
    const base64File = await readFileAsBase64(event.target.files[0])
    setFileToUpload({
      filename: event.target.files[0].name,
      filedata: base64File
    })
  }

  const sendMessage = () => {
    const data = fileToUpload.filename ?
      {
        description: convertToHTML(editorState.getCurrentContent()),
        ...fileToUpload
      } :
      {
        description: convertToHTML(editorState.getCurrentContent())
      }
    dispatch(addMessage({ticketId: ticket_id, ticketData: data}) as any)
  }

  // has to create new array, otherwise it is stateful mutation
  const sortedMessages = [...selectedTicket.messages].sort((a, b) => {
    return new Date(b.created).getTime() - new Date(a.created).getTime()
  })

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <button className="flex justify-items-center items-center text-base" onClick={() => navigate("/tickets/")}>
        <ArrowBackIosIcon className="text-blue-800" sx={{height: '15px'}}/>
        <p className="text-left py-5 font-bold text-blue-800">Back to tickets</p>
      </button>
      <LoggedInUserMenu/>
    </div>
    <div className="border rounded-lg">
      <div className="p-6">
        <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage
            id="Ticket"/> {selectedTicket.ticket_number}</p>
      </div>
      <div className="flex justify-between align-middle px-6 py-4">
        <div className="text-lg font-LaNord font-bold">Can I Install it locally?</div>
        <div className="flex justify-items-center items-center">
          <div className="font-LaNord text-xs text-[#9FA2B4]">{selectedTicket.created}</div>
          <span className="bg-[#FE2142] ml-3 px-4 py-2 text-xs text-base text-white rounded-lg">{selectedTicket.status}</span>
          <Divider sx={{ height: 28, m: 0.5, marginRight: 2 , marginLeft: 2 }} orientation="vertical" />
          <div className="text-sm text-[#4B506D] font-LaNord font-semibold">{selectedTicket.admin_user.first_name} {selectedTicket.admin_user.last_name}</div>
          <div className="w-[44px] h-[44px] ml-4 mr-3 rounded-full">
            <img src="/assets/images/user.svg" alt="" width="44"/>
          </div>
        </div>

      </div>

      <div className="flex p-6">
        <Editor
            editorStyle={{height: 'auto', minHeight: '250px', background: 'white', padding: '4px', border: '1px solid #EBEDF0', borderRadius: '8px'}}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(e) => setEditorState(e)}
        />
        <div className="flex flex-col ml-4">
          {fileToUpload.filename ?
              <div className="flex">
                File: {fileToUpload.filename}
                <span
                    onClick={() => setFileToUpload(emptyFileData)}
                    className="cursor-pointer text-red-600 font-bold ml-2"
                >x
            </span>
              </div> :
              <label htmlFor="contained-button-file">
                <input style={{display: "none"}} onChange={fileSelectedHandler} accept="*" id="contained-button-file"
                       multiple type="file"/>
                <Button variant="contained" sx={{ borderRadius: 30, backgroundColor: '#253292', height: 48, fontSize: 14, boxShadow: '0px 4px 12px rgba(55, 81, 255, 0.24)', paddingLeft: '24px', paddingRight: '24px'}} component="span">
                  add attachment
                </Button>
              </label>}

          <Button variant="contained" sx={{ marginTop: 2, borderRadius: 30, backgroundColor: '#253292', height: 48, fontSize: 14, boxShadow: '0px 4px 12px rgba(55, 81, 255, 0.24)', paddingLeft: '24px', paddingRight: '24px'}} onClick={sendMessage}>Send message</Button>
          <div className="flex mt-2 rounded-full border font-LaNord text-sm text-white py-[10px] px-[24px] justify-between shadow-[0px 4px 12px rgba(55, 81, 255, 0.24)] bg-[#253292] flex">Resolved <CheckIcon/></div>
          <div className="flex mt-2 rounded-full border font-LaNord text-sm text-[#9FA2B4] py-[10px] px-[24px] justify-between" >Assign <EastIcon/></div>
        </div>
      </div>

      {
        sortedMessages
            .map((message, i) => {
              const user = message.admin_user || loggedInUser

              return <div key={i} className="border-t p-6">
                <div className="flex justify-between">
                  <div className="flex justify-items-center items-center">
                    <Avatar sx={{width: 32, height: 32}}
                            src={process.env.REACT_APP_API_URL + '/' + user.avatar}>
                      {getInitials(user.first_name + ' ' + user.last_name)}
                    </Avatar>
                    <p className="ml-4 text-base font-LaNord font-semibold text-[#253292]">{user.first_name + ' ' + user.last_name}</p>
                  </div>

                  <Menu sx={{maxWidth: 20}}>
                    <div>
                      <div><FormattedMessage id="see_details"/></div>
                      <div><FormattedMessage id="see"/></div>
                    </div>
                  </Menu>
                </div>

                <div className="flex justify-between mt-4">
                  <div dangerouslySetInnerHTML={{__html: message.text}} className="text-sm text-[#4B506D] font-LaNord pl-12"></div>
                  {message.attachment &&
                      <Button
                          onClick={() => window.open(process.env.REACT_APP_API_URL + '/' + message.attachment + '?Authorization=' + JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token, "_blank")}>
                        <FormattedMessage id="download"/> {message.attachment.split('/').pop()}
                      </Button>
                  }
                  <div className="flex">
                    <FormattedDate
                        value={message.created}
                        hour='numeric'
                        minute='numeric'
                        year="numeric"
                        month="long"
                        day="2-digit"/>
                    <span className="bg-[#4B506D] ml-4 px-4 py-1 text-sm text-white font-bold rounded-lg">Unanswered</span>
                  </div>

                </div>




              </div>
            })
      }
    </div>


  </div>
};

const emptyFileData = {
  filename: '',
  filedata: ''
}

export default withErrorBoundary(
  Ticket,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });