import {withErrorBoundary} from 'react-error-boundary';
import AddBankList from "../Banks/AddBankList";

const AddBankAccount = () => {
  return <div className="flex min-h-screen">
    <div className="flex-1 flex flex-col justify-between" style={{padding: '40px'}}>
      <img src="/assets/images/companio-logo.png" alt="logo" width="149" style={{maxHeight: "40px"}}/>

      <div className="text-left">
        <AddBankList />
      </div>

      <div></div>
      {/*has to be here because of flex */}

    </div>

    <div className="flex items-center justify-center"
         style={{
           background: 'linear-gradient(109.4deg, #9A53CB 1.26%, #FF4EA1 96.4%)',
           padding: '40px',
           width: '350px'
         }}>
      <img src="/assets/images/login-people.png" alt="people"/>
    </div>
  </div>
};

export default withErrorBoundary(
  AddBankAccount,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
