import * as React from 'react';
import {Navigate} from 'react-router-dom';
import Cookie from 'js-cookie'
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";

type PrivateRouteProps = {
  id ?: string;
  children: React.ReactComponentElement<any>;
};

export const PrivateRoute = ({children}: PrivateRouteProps) => {
  const { user } = useSelector(userSelector);
  const authCookie = Cookie.get('tuempresaenestonia_auth') || Cookie.get('tuempresaenestonia_admin')
  if (!authCookie) {
    return <Navigate
        to={{pathname: '/login'}}
    />
  }
  let currentAuth = {
    expires: null
  }

  try{
    currentAuth = JSON.parse(decodeURIComponent(authCookie))
  }
  catch{}
  if(currentAuth.expires && new Date() > currentAuth.expires) {
    return <Navigate
      to={{pathname: '/login'}}
    />
  }

  if (!user) {
    return <Navigate
      to={{pathname: '/login'}}
    />
  }

  return children
}
