import {withErrorBoundary} from "react-error-boundary";
import {Typography, Box, Radio, RadioGroup, FormControlLabel} from "@mui/material";
import {FormattedMessage} from "react-intl";
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from "react-intl";
import {
  clearState,
  userSelector,
  updateUserSettings,
} from '../User/UserSlice';
import toast from "react-hot-toast";

const Notifications = () => {
  const dispatch = useDispatch()
  const {isSuccess, isError, user} = useSelector(userSelector)
  const intl = useIntl()

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(intl.formatMessage({id: 'error_modifying_data'}));
    }
    if (isSuccess) {
      toast.success(intl.formatMessage({id: 'data_successfully_modified'}));
    }
    dispatch(clearState());
  }, [dispatch, isError, isSuccess, intl])

  const updateSettings = (option: any) => {
    dispatch(updateUserSettings(option) as any)
  }

  const defaultValue = (user.company.settings
    && user.company.settings.find((i: any) => i.key === 'reminder_emails_frequency')
    && user.company.settings.find((i: any) => i.key === 'reminder_emails_frequency').value) || ''

  return <Box component="form" sx={{m: 7, color: "#94A3B8"}} noValidate
              autoComplete="off">
    <h1 className="text-slate-400 text-2xl"><FormattedMessage id={"invoice_reminder_email_frequency"}/></h1>
    <RadioGroup
      sx={{ml: 16, mt: 4}}
      value={defaultValue}
      name="radio-buttons-group"
    >
      <FormControlLabel
        id="weekly"
        value="weekly"
        control={<Radio onClick={() => updateSettings("weekly")}/>}
        label={<Typography sx={{fontSize: 23}}
                           gutterBottom align="left"
                           className="break-words">
          <FormattedMessage id={"weekly"}/>
        </Typography>}/>

      <FormControlLabel
        id="biweekly"
        value="biweekly"
        control={<Radio onClick={() => updateSettings("biweekly")}/>}
        label={<Typography sx={{fontSize: 23}}
                           gutterBottom align="left"
                           className="break-words">
          <FormattedMessage id={"two_weeks"}/>
        </Typography>}/>

      <FormControlLabel
        id="monthly"
        value="monthly"
        control={<Radio onClick={() => updateSettings("monthly")}/>}
        label={<Typography sx={{fontSize: 23}}
                           gutterBottom align="left"
                           className="break-words">
          <FormattedMessage id={"monthly"}/>
        </Typography>}/>
    </RadioGroup>
  </Box>
}

export default withErrorBoundary(
  Notifications,
  {
    FallbackComponent: () => <div>Setting to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })