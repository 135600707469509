import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clearState, customersSelector, getCustomer} from './CustomersSlice';
import {FormattedMessage, useIntl} from 'react-intl';

import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Menu from "../../common/components/Menu";
import Pagination from "../../common/components/Pagination";
import CompanioButton from "../../common/components/CompanioButton";
import {ticketsSelector} from "../Tickets/TicketsSlice";

const Customer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {customer_id} = useParams()
    const intl = useIntl()

    const {isFetching, isError, isSuccess, selectedCustomer, customersData} = useSelector(
        customersSelector
    );

    const {ticketsData} = useSelector(ticketsSelector)

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [filters, setFilters] = useState({
        search: ''
    })

   // const selectedCustomer = customersData.contacts.find((i: any) => i._id === customer_id)


    useEffect(()=>{
        console.log('selectedCustomerData--------------->', customer_id, selectedCustomer)
    },[selectedCustomer])

    useEffect(() => {
        dispatch(getCustomer({page, limit, filters, customer_id}) as any)
    }, [dispatch, limit, page, filters, customer_id])

    useEffect(() => {
        if (isError) {
            toast.error(intl.formatMessage({id: 'error_modifying_user'}));
        }
        if (isSuccess) {
            toast.success(intl.formatMessage({id: 'data_successfully_modified'}));
        }
        dispatch(clearState());
    }, [dispatch, isError, isSuccess, intl])


    return <div className="flex flex-col w-full px-4">
        <div className="flex justify-between mb-5">
            <button className="flex justify-items-center items-center text-base" onClick={() => navigate("/customers/")}>
                <ArrowBackIosIcon className="text-blue-800" sx={{height: '15px'}}/>
                <p className="text-left py-5 font-bold text-blue-800">Back to customers</p>
            </button>
            <LoggedInUserMenu/>
        </div>
        <div className="flex gap-6">
            <div className="border rounded-lg w-8/12">
                <div className="flex justify-between p-6 items-center">
                    <p className="text-lg text-left font-LaNord font-bold text-[#252733]">Recent support messages</p>
                    <div className="flex">
                        <div className="flex">
                            <img src="/assets/images/logos/Icon_sort.svg"
                                 alt="logo"
                                 width="16"
                            />
                            <div className="font-LaNord font-bold text-sm text-[#4B506D] px-2">Sort</div>
                        </div>
                        <div className="flex pl-8">
                            <img src="/assets/images/logos/Icon_filter.svg"
                                 alt="logo"
                                 width="16"
                            />
                            <div className="font-LaNord font-bold text-sm text-[#4B506D] px-2">Filter</div>
                        </div>
                    </div>
                </div>
                {ticketsData.tickets.length ? <>
                        <TableContainer>
                            <Table
                                sx={{minWidth: 750}}
                                aria-labelledby="tableTitle"
                                size='medium'
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">
                                            <div className="font-bold text-[#9FA2B4] pl-4">Ticket detail</div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div className="font-bold text-[#9FA2B4]">Date</div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div className="font-bold text-[#9FA2B4]">Priority</div>
                                        </TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {
                                        ticketsData.tickets.map((c: any, i: any) => <TableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={i}
                                        > { customer_id === c.id ? <>
                                            <TableCell align="left">
                                                <div className="flex items-center">
                                                    <div className="w-[44px] h-[44px] ml-4 mr-3 rounded-full">
                                                        <img src="/assets/images/user.svg" alt="" width="44"/>
                                                    </div>
                                                    <div>
                                                        <div
                                                            className="text-sm text-left font-bold text-blue-800 max-w-[100px]">Ticket {c.number}</div>
                                                        <div className="text-[#C5C7CD] font-xs">{c.status}</div>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">
                                                <div
                                                    className="text-sm text-left font-bold text-blue-800">{c.created}</div>
                                                <div className="text-[#C5C7CD] font-xs">{c.created}</div>
                                            </TableCell>
                                            <TableCell align="left">
                                                {c.priority == 1 ? <span
                                                        className="bg-[#FE2142] px-4 py-2 text-sm text-white font-bold rounded-lg">HIGH</span> :
                                                    <span
                                                        className="bg-[#018EFF] px-4 py-2 text-sm text-white font-bold rounded-lg">Low</span>}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Menu sx={{}}>
                                                    <div onClick={() => navigate("/tickets/" + c._id)}><FormattedMessage
                                                        id="see_details"/></div>
                                                </Menu>
                                            </TableCell> </>: <></> }
                                        </TableRow>)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div>

                            <Pagination className="my-2"
                                        requestPaginationData={20}
                                        limit={limit}
                                        setLimit={setLimit}
                                        page={page}
                                        setPage={setPage}
                            />
                        </div>
                    </> :
                    <FormattedMessage id='no_contacts_found'
                                      values={{
                                          span: ((chunks: any) => <span className="text-blue-500 cursor-pointer"
                                                                        onClick={() => navigate('add_customer')}>{chunks}</span>) as any
                                      }}>
                        {(chunks: any) => {
                            return <div>{chunks[0]} {React.cloneElement(chunks[1])} {chunks[2]}</div>
                        }}
                    </FormattedMessage>


                }
            </div>
            <div className="border rounded-lg p-6 w-4/12">
                <div className="flex items-center">
                    <div>
                        <img src="/assets/images/user.svg" alt="" width="96"/>
                    </div>
                    <div className="pl-6">
                        <div className="flex p-2">
                            <img src="/assets/images/logos/Icon_global_map.svg" />
                            {customersData.contacts.map((c: any, i: any) => <>
                                    { customer_id === c.id ? <div className="pl-2 font-LaNord text-sm text-[#9FA2B4] text-left">{c.address}</div> : <></>}
                                </>
                            )}
                        </div>
                        <div className="flex p-2">
                            <img src="/assets/images/logos/Icon_clock.svg" />
                            {customersData.contacts.map((c: any, i: any) => <>
                                    { customer_id === c.id ? <div className="pl-2 font-LaNord text-sm text-[#9FA2B4] text-left">{c.created}</div> : <></>}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="pt-2 flex flex-col">
                    <div className="flex flex-col items-start pt-8">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">Full name</div>
                        {customersData.contacts.map((c: any, i: any) => <>
                            { customer_id === c.id ? <div className="text-base font-LaNord text-[#4B506D]">{c.name}</div> : <></>}
                        </>
                        )}
                    </div>
                    <div className="flex flex-col items-start pt-8">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">Email</div>
                        {customersData.contacts.map((c: any, i: any) => <>
                                { customer_id === c.id ? <div className="text-base font-LaNord text-[#4B506D]">{c.email}</div> : <></>}
                            </>
                        )}
                    </div>
                    <div className="flex flex-col items-start pt-8">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">TAX ID</div>
                        {customersData.contacts.map((c: any, i: any) => <>
                                { customer_id === c.id ? <div className="text-base font-LaNord text-[#4B506D]">{c.tax_id}</div> : <></>}
                            </>
                        )}
                    </div>
                    <div className="flex flex-col items-start pt-8">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">VAT number</div>
                        {customersData.contacts.map((c: any, i: any) => <>
                                { customer_id === c.id ? <div className="text-base font-LaNord text-[#4B506D]">{c.vat_number}</div> : <></>}
                            </>
                        )}
                    </div>
                    <div className="pt-8">
                        <CompanioButton size="small" variant="contained" className="w-full h-[40px]">Send new invoice</CompanioButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Customer
