import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import {useNavigate, useParams} from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Divider} from "@mui/material";
import CompanioButton from "../../common/components/CompanioButton";

const CreatSales =()=> {
    const navigate = useNavigate()

    return(
        <div className="flex flex-col w-full px-4">
            <div className="flex justify-between mb-5">
                <button className="flex justify-items-center items-center text-base" onClick={() => navigate("/sales/")}>
                    <ArrowBackIosIcon className="text-blue-800" sx={{height: '15px'}}/>
                    <p className="text-left py-5 font-bold text-blue-800">Back to Sales</p>
                </button>
                <LoggedInUserMenu/>
            </div>
            <div className="border rounded-lg">
                <div className="p-6 mb-6">
                    <div>
                        <p className="text-lg text-left font-LaNord font-bold text-[#252733]">New Invoice</p>
                    </div>
                    <div className="mt-12">
                        <div className="flex gap-8">
                            <div className="flex flex-col items-start w-6/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">CHOOSE A CUSTOMER</div>
                                <div className="flex border rounded-lg px-4 bg-[#FCFDFE] justify-between justify-items-center items-center mt-2 w-full">
                                    <select name="Umbrella Corporation" className="h-[40px] appearance-none text-sm font-LaNord text-[#9FA2B4] w-full">
                                        <option>Umbrella Corporation</option>
                                        <option>Umbrella Corporation1</option>
                                        <option>Umbrella Corporation2</option>
                                        <option>Umbrella Corporation3</option>
                                    </select>
                                    <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
                                </div>
                            </div>
                            <div className="flex flex-col items-start w-3/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">INVOICE NUMBER</div>
                                <input placeholder="13" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"/>
                            </div>
                            <div className="flex flex-col items-start w-3/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">CURRENCY</div>
                                <div className="flex border rounded-lg px-4 bg-[#FCFDFE] justify-between justify-items-center items-center mt-2 w-full">
                                    <select name="Umbrella Corporation" className="h-[40px] appearance-none text-sm font-LaNord text-[#9FA2B4] w-full">
                                        <option>EURO</option>
                                        <option>USD</option>
                                        <option>YEAN</option>
                                    </select>
                                    <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-6 gap-8">
                            <div className="flex flex-col items-start w-6/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">SUBJECT</div>
                                <input placeholder="Online marketing and SEO services" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"/>
                            </div>
                            <div className="flex flex-col items-start w-3/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">INVOICE DATE</div>
                                <input placeholder="19/07/2021" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"/>
                            </div>
                            <div className="flex flex-col items-start w-3/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">DUE DATE</div>
                                <input placeholder="26/07/2021" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-6 border-t mb-6">
                    <div className="flex justify-between">
                        <p className="text-base text-left font-LaNord font-semibold text-[#9FA2B4]">Invoice items</p>
                        <a className="font-bold font-LaNord text-sm text=[#253292]">Add more</a>
                    </div>
                    <div className="mt-12">
                        <div className="flex gap-8">
                            <div className="flex flex-col items-start w-6/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">NAME</div>
                                <input placeholder="Online marketing" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"/>
                            </div>
                            <div className="flex flex-col items-start w-3/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">PRICE</div>
                                <input placeholder="10.000" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"/>
                            </div>
                            <div className="flex flex-col items-start w-3/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">VAT’S</div>
                                <div className="flex border rounded-lg px-4 bg-[#FCFDFE] justify-between justify-items-center items-center mt-2 w-full">
                                    <select name="Umbrella Corporation" className="h-[40px] appearance-none text-sm font-LaNord text-[#9FA2B4] w-full">
                                        <option>20</option>
                                        <option>15</option>
                                        <option>10</option>
                                    </select>
                                    <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-6 gap-8">
                            <div className="flex flex-col items-start w-6/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">NAME</div>
                                <input placeholder="Online marketing" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"/>
                            </div>
                            <div className="flex flex-col items-start w-3/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">INVOICE DATE</div>
                                <input placeholder="5.000" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"/>
                            </div>
                            <div className="flex flex-col items-start w-3/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">DUE DATE</div>
                                <div className="flex border rounded-lg px-4 bg-[#FCFDFE] justify-between justify-items-center items-center mt-2 w-full">
                                    <select name="Umbrella Corporation" className="h-[40px] appearance-none text-sm font-LaNord text-[#9FA2B4] w-full">
                                        <option>20</option>
                                        <option>15</option>
                                        <option>10</option>
                                    </select>
                                    <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-6 border-t mb-6">
                    <div className="flex justify-between">
                        <p className="text-base text-left font-LaNord font-semibold text-[#9FA2B4]">Total (Including VAT)</p>
                    </div>
                    <div className="mt-12">
                        <div className="flex gap-8 justify-between items-end">
                            <div className="flex flex-col items-start w-6/12">
                                <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">CHOOSE A CUSTOMER</div>
                                <input placeholder="18000.00" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"/>
                            </div>
                            <div className="flex items-center">
                                <div className="text-sm font-bold font-LaNord text-[#9FA2B4] px-6">EUR</div>
                                <CompanioButton size="small" variant="contained" className="h-[40px]">Generate invoice</CompanioButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        </div>
    )
}

export default CreatSales