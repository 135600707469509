import React, { useState, useContext, useCallback, useEffect } from "react";
import { Multiselect } from 'multiselect-react-dropdown';
import './Employees.css'
import CompanioButton from "../../common/components/CompanioButton";


const AddEmployee =()=> {
    const [selectedValue, setselectedValue] = useState(null);
    const [onSelect, setonSelect] = useState()
    const [onRemove, setonRemove] = useState()


    return (<>
            <div className="px-7 mb-6">
                <div className="font-LaNord font-bold text-[#252733] text-2xl">Invite new employees</div>
                <div>
                    <div className="mx-auto container pt-3">
                        <div className="text-sm text-[#9FA2B4]">Type in the email address of the employees you'd like to invite, then select their role.</div>
                        <div className="flex flex-wrap items-center lg:justify-between justify-center pt-6">
                            <div className="w-full">
                                <Multiselect
                                    options={Mails} // Options to display in the dropdown
                                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                                    onSelect={onSelect} // Function will trigger on select event
                                    onRemove={onRemove} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                    closeIcon="cancel"
                                    placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-between px-7 py-6 border-t">
                <div className="flex items-center">
                    <input type="radio" id="full" name="full_access"
                           value="full_access"
                           />
                    <label htmlFor="html" className="pl-3 font-LaNord font-semibold text-sm">Full Access</label>
                </div>
                <div className="text-sm font-LaNord text-[#4B506D] w-[318px]">Best for administrators and managers who will need access to sensitive data.</div>
            </div>
            <div className="flex items-center justify-between px-7 py-6 border-t">
                <div className="flex items-center">
                    <input type="radio" id="limited" name="limited_access"
                           value="limited_access"
                           checked />
                    <label htmlFor="html" className="pl-3 font-LaNord font-semibold text-sm">Limited Access</label>
                </div>
                <div className="text-sm font-LaNord text-[#4B506D] w-[318px]">Best for associate employees who shouldn't have access to sensitive data.</div>
            </div>
            <div className="flex items-center justify-between px-7 py-6 border-t">
                <div className="text-xs font-LaNord text-[#9FA2B4]">
                    An invite will be send to 2 users.
                </div>
                <div className="flex">
                    <button className="font-bold font-LaNord text-[#9FA2B4] text-sm pr-4" >Cancel</button>
                    <CompanioButton size="small" variant="contained" className="w-32 h-[40px]"
                                    >Invite</CompanioButton>
                </div>
            </div>

        </>
    )
}

export default AddEmployee

const Mails = [
    { id: 1, name: "mariovisnjic@hotmail.com" },
    { id: 2, name: "mario.visnjic@yourcompanyinestonia.com" },
    { id: 3, name: "testuser4@digitalleaves.com" },
    { id: 4, name: "testuser1@digitalleaves.com" },
    { id: 5, name: "mariovisnjic@hotmail.com" },
];

