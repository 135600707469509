import {Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {withErrorBoundary} from "react-error-boundary";
import {readFileAsBase64} from "../../../common/utils/utils";
import {FormattedMessage, useIntl} from "react-intl";

import emtak from "../../../translations/emtak/emtak_summary_es";
import {countriesData} from "../../../common/utils/countriesData";

const BasicFields = ({updateBusinessInfo, handleStep, registrationData}: {updateBusinessInfo: any, handleStep: any, registrationData: any}) => {
  const intl = useIntl()

  const idHandler = async (e: any) => {
    const base64File = await readFileAsBase64(e.target.files[0])

    updateBusinessInfo({
      id: 'passport_pic',
      type: 'File',
      filename: e.target.files[0].name,
      mime_type: e.target.files[0].type,
      value: base64File
    })
  }

  const idNameField = registrationData.business_information.find((i: any) => i.id === 'passport_pic')

  return <div>
    <div className="flex justify-between">
      <Typography><FormattedMessage id="dni_pic"/></Typography>

      <label htmlFor="contained-button-file">
        <input style={{display: "none"}}
               onChange={idHandler}
               accept="*"
               id="contained-button-file"
               type="file"/>
        <Button variant="contained" component="span">
          {idNameField.filename || <FormattedMessage id="upload"/>}
        </Button>
      </label>
    </div>

    <div className="flex justify-between mt-2">
      <Typography><FormattedMessage id="activity"/></Typography>
      <FormControl>
        <InputLabel id="activity"><FormattedMessage id="activity"/></InputLabel>
        <Select
          labelId="activity"
          className='text-left'
          id="activity"
          label={intl.formatMessage({id: 'activity'})}
          sx={{width: "350px"}}
          onChange={(e) => updateBusinessInfo({
            id: 'activity',
            type: 'Options',
            value: e.target.value
          })}
          defaultValue=""
        >
          <MenuItem sx={{width: "350px"}} className='text-left' value="" disabled><FormattedMessage id="choose_option"/></MenuItem>
          {emtak.map((e, i) => {
            return <MenuItem sx={{width: "350px"}} className='text-left' value={e.code} key={i}>{e.description}</MenuItem>
          })}
        </Select>
      </FormControl>
    </div>

    <div className="flex justify-between mt-2">
      <Typography><FormattedMessage id="your_legal_address"/></Typography>

      <TextField
        id="address"
        label={intl.formatMessage({id: 'your_legal_address'})}
        onChange={(e) => updateBusinessInfo({
          id: 'address',
          type: 'String',
          value: e.target.value
        })}
        required/>
    </div>

    <div className="flex justify-between mt-2">
      <Typography><FormattedMessage id="city"/></Typography>

      <TextField
        id="city"
        label={intl.formatMessage({id: 'city'})}
        onChange={(e) => updateBusinessInfo({
          id: 'city',
          type: 'String',
          value: e.target.value
        })}
        required/>
    </div>

    <div className="flex justify-between mt-2">
      <Typography><FormattedMessage id="country"/></Typography>

      <FormControl>
        <InputLabel id="country"><FormattedMessage id="country"/></InputLabel>
        <Select
          labelId="country"
          className="text-left"
          id="country"
          sx={{width: "350px"}}
          label={intl.formatMessage({id: 'country'})}
          onChange={(e) => updateBusinessInfo({
            id: 'country',
            type: 'Options',
            value: e.target.value
          })}
          defaultValue=""
        >
          <MenuItem sx={{width: "350px"}} className="text-left" value="" disabled><FormattedMessage id="choose_option"/></MenuItem>
          {countriesData.map((c, i) => {
            return <MenuItem className="text-left" value={c.name} key={i}>{c.name}</MenuItem>
          })}

        </Select>
      </FormControl>
    </div>


    <Button
      variant="outlined"
      onClick={() => handleStep(1)}
      sx={{ mt: 1, mr: 1 }}
    >
      <FormattedMessage id="back" />
    </Button>

    <Button
      variant="contained"
      onClick={() => handleStep(3)}
      sx={{ mt: 1, mr: 1 }}
    >
      <FormattedMessage id="next" />
    </Button>


  </div>
}

export default withErrorBoundary(
  BasicFields,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
