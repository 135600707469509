import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {TextField} from "@mui/material";
import React from "react";

type DefaultDatePickerT = {
  id: string;
  date: Date;
  name: string;
  onChange: any;
  width?: string;
  minDate?: Date;
}

const DefaultDatePicker = ({id, date, name, onChange, width, minDate}: DefaultDatePickerT) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        inputFormat="DD/MM/YYYY"
        defaultCalendarMonth={new Date()}
        value={date}
        onChange={onChange}
        minDate={minDate}
        renderInput={(params: any) => {
          const props = {
            ...params,
            ...{
              size: 'small',
              sx: {
                width: width,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#FCFDFE',
                  borderRadius: '8px',
                  color: '#9FA2B4',
                  '& fieldset': {
                    borderColor: '#F0F1F7'
                  },
                  '&:hover fieldset': {
                    border: 'none'
                  },
                  '& .Mui-focused fieldset': {
                    borderColor: '#F0F1F7'
                  }
                }
              },
              id: id,
              name: name
            }
          };
          // @ts-ignore
          return <TextField {...props} />;
        }}
      />
    </LocalizationProvider>
  )

}

export default DefaultDatePicker