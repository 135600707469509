import {FormattedMessage} from "react-intl";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import React, {useEffect, useState, useContext} from "react";
import SearchFilter from "../../common/components/SearchFilter";
import CompanioButton from "../../common/components/CompanioButton";
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Container,
} from '@mui/material';
import Pagination from "../../common/components/Pagination";
import {useDispatch, useSelector} from "react-redux";
import {employeesSelector} from "./EmployeeSlice";
import toast from "react-hot-toast";
import {clearState, getCustomers} from "../Customers/CustomersSlice";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {useNavigate} from "react-router-dom";
import Menu from "../../common/components/Menu";
import Modal from "@mui/material/Modal";
import AddEmployee from "./AddEmployee";
import AddExternalEmployee01 from "./AddExternalEmployee01";
import AddExternalEmployee02 from "./AddExternalEmployee02";



const Employees =()=> {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const {isError, errorMessage, employeesData} = useSelector(employeesSelector)

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [filters, setFilters] = useState({
        search: ''
    })

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "546px",
        bgcolor: 'background.paper',
        borderRadius: '12px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
    };

    useEffect(() => {
        console.log('employeesData33333', employeesData)
    },[employeesData])

    useEffect(() => {
        if (isError) {
            toast.error(errorMessage)
            dispatch(clearState())
        }
    }, [dispatch, isError, errorMessage, navigate])

    useEffect(() => {
        dispatch(getCustomers({page, limit, filters}) as any)
    }, [dispatch, limit, page, filters])

    if (!employeesData) {
        return <Container maxWidth="xs">
            <Box sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box>
        </Container>
    }

    return (
        <div className="flex flex-col w-full px-4">
            <div className="flex justify-between mb-5 items-center">
                <p className="text-2xl text-left font-LaNord text-blue-800 font-bold"><FormattedMessage id="employees"/></p>
                <div className="flex">
                    <LoggedInUserMenu/>
                </div>
            </div>
            <div className="border rounded-lg">
                <div className="flex justify-between p-6 items-center">
                    <p className="text-lg text-left font-LaNord font-bold text-[#252733]">All employees</p>
                    <div className="flex">
                        <SearchFilter filters={filters} setFilters={setFilters}/>
                        <CompanioButton size="small" variant="contained" className="h-[40px]" onClick={handleOpen}>New employee</CompanioButton>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="pt-6">
                                    <AddEmployee />
                                    {/*<AddExternalEmployee01 />*/}
                                    {/*<AddExternalEmployee02 />*/}
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
                {employeesData.length ? <>
                        <TableContainer>
                            <Table sx={{minWidth: 750}}
                                   aria-labelledby="tableTitle"
                                   size='medium'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">
                                            <div className="font-bold text-[#9FA2B4]">Full name</div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div className="font-bold text-[#9FA2B4]">Position</div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div className="font-bold text-[#9FA2B4]">Salary</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className="font-bold text-[#9FA2B4]">Scheduled</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className="font-bold text-[#9FA2B4]">Status</div>
                                        </TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {employeesData.map((c: any, i: any) => <>
                                    {c.activity === 'test' ?
                                        <TableRow role="checkbox"
                                                  tabIndex={-1}
                                                  key={i}>
                                            <TableCell align="left">
                                                <div className="flex items-center">
                                                    <div className="w-[44px] h-[44px] ml-4 mr-3 rounded-full">
                                                        <img src="/assets/images/user.svg" alt="" width="44"/>
                                                    </div>
                                                    <div
                                                        className="cursor-pointer hover:text-red-600 text-sm text-left font-bold text-[#253292]">{c.first_name} {c.last_name}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">
                                                <div className="text-sm text-left font-bold text-blue-800"><FormattedMessage id={c.type}/></div>
                                            </TableCell>
                                            <TableCell align="left">
                                                <div className="text-[#253292] font-sm font-semibold font-LaNord">{c.salary.from} {c.salary.currency === 'EUR' ? <span>€</span> : <span>$</span>}</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                {c.schedule_salary === true ? <span className="bg-[#00DA8B] font-LaNord font-bold text-white text-xs px-3 py-1 rounded-full">YES</span>: <span className="bg-[#FE2142] font-LaNord font-bold text-white text-xs px-3 py-1 rounded-full">NO</span>}
                                            </TableCell>
                                            <TableCell align="center">
                                                {c.status === 'approved' ? <span className="bg-[#00DA8B] font-LaNord font-bold text-white text-xs px-4 py-1 rounded-lg">APPROVED</span>: <span className="bg-[#FEA500] font-LaNord font-bold text-white text-xs px-4 py-1 rounded-lg">PENDING</span>}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Menu sx={{}}>
                                                    <div><FormattedMessage id="see_details"/></div>
                                                </Menu>
                                            </TableCell>
                                        </TableRow> : <></> } </>)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div>

                            <Pagination className="my-2"
                                        requestPaginationData={employeesData}
                                        limit={limit}
                                        setLimit={setLimit}
                                        page={page}
                                        setPage={setPage}
                            />
                        </div>
                    </> :
                    <FormattedMessage id='no_contacts_found'
                                      values={{
                                          span: ((chunks: any) => <span className="text-blue-500 cursor-pointer"
                                                                        onClick={() => navigate('add_customer')}>{chunks}</span>) as any
                                      }}>
                        {(chunks: any) => {
                            return <div>{chunks[0]} {React.cloneElement(chunks[1])} {chunks[2]}</div>
                        }}
                    </FormattedMessage>
                }
            </div>
        </div>
    )
}

export default Employees