import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import React, { CSSProperties } from 'react';

function SelectUserInput({
    id,
    value,
    onChange,
    label,
    style,
    className,
    children
    }: {
    id: string;
    value: string;
    onChange: Function;
    label: string;
    style?: CSSProperties;
    className: string;
    children: any;
}) {
    return (
        <div
            data-testid="select-user-input"
            className={`d-flex flex-col items-start justify-center ${className}`}
            style={style}>
            {label && (
                <p
                    className="text-start uppercase"
                    style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
                    {label}
                </p>
            )}
            <FormControl variant="standard" fullWidth size="medium">
                <Select
                    id={id}
                    name={id}
                    value={value}
                    data-testid="select-user-select"
                    onChange={(e: SelectChangeEvent) => onChange(e)}
                    inputProps={{
                        'aria-label': 'Without label'
                    }}
                    MenuProps={{
                        sx: {
                            '& .MuiPaper-root': {
                                maxHeight: '400px',
                                borderBox: '8px',
                                '& ul': {
                                    '& li': {
                                        fontWeight: '500 !important',
                                        color: '#253292',
                                        fontSize: '14px',
                                        ':hover': {
                                            backgroundColor: '#DDE2FF'
                                        }
                                    }
                                }
                            }
                        }
                    }}>
                    {children}
                </Select>
            </FormControl>
        </div>
    );
}

export default SelectUserInput;
