import {withErrorBoundary} from "react-error-boundary";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Box, Button,
  Container,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {useNavigate} from "react-router-dom";
import {clearState} from "../Sales/SalesSlice";
import toast from "react-hot-toast";
import {banksSelector, getBanks} from "./BanksSlice";
import BankCard from "./BankCard";
import SearchFilter from "../../common/components/SearchFilter";
import CompanioButton from "../../common/components/CompanioButton";

const Banks = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {isError, errorMessage, banks} = useSelector(banksSelector)

  const [filters, setFilters] = useState({
    search: ''
  })

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);

  useEffect(() => {
    dispatch(getBanks() as any);
  }, [dispatch]);

  if (!banks) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="banks"/></p>
      <div></div>
      <LoggedInUserMenu/>
    </div>

    <div className="border rounded-lg">
      <div className="flex justify-between px-7 pt-6 pb-2 items-center">
        <p className="text-lg text-left font-LaNord font-bold text-[#252733]">Bank statements</p>
        <div className="flex">
          <SearchFilter filters={filters} setFilters={setFilters}/>
          <CompanioButton size="small" variant="contained" className="w-32 h-[40px]"
                          onClick={() => navigate('add_bank_list')}>Add new</CompanioButton>
        </div>
      </div>
      <div className="flex flex-wrap px-3">
        {
          banks.length ? banks.map((bank: any, i: any) => <BankCard bank={bank} key={i}/>) : 'No banks added'
        }
      </div>
    </div>


    <footer className="h-auto bg-blue-800 my-10 rounded-md text-slate-50">
      <h2 className="text-xl pt-4 font-LaNord"><FormattedMessage id="connect_bank_accounts_title"/></h2>
      <div className="flex">
        <div className="flex w-1/6 h-28 ml-12">
          <img src="/assets/images/add-employees.png" alt="add bank"/>
        </div>
        <div className="flex w-4/6">
          <p className="p-3 text-left"><FormattedMessage id="connect_bank_accounts_text"/></p>
        </div>
        <div className="flex w-1/6 h-1/6 mt-10 mb-3">
          <Button
            onClick={() => navigate('/banks/add_bank_list/')}
            variant="contained" sx={{mr: 3, mt: 2, mb: 5, bgcolor: '#fff', color: '#1E40AF'}}><FormattedMessage
            id="add_new"/></Button>
        </div>
      </div>
    </footer>

  </div>
};

export default withErrorBoundary(
  Banks,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });