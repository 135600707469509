import {withErrorBoundary} from "react-error-boundary";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import {FormattedMessage, useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import Pagination from "../../common/components/Pagination";
import {useDispatch, useSelector} from "react-redux";
import {getTickets, ticketsSelector} from "./TicketsSlice";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Menu as MuiMenu,
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {useNavigate} from "react-router-dom";
import {clearState} from "../Sales/SalesSlice";
import toast from "react-hot-toast";
import Menu from "../../common/components/Menu";

const Tickets = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {isError, errorMessage, ticketsData} = useSelector(ticketsSelector)

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    status: ['processing', 'open', 'solved'],
    search: null
  });
  useEffect(() => {
    console.log('ticketData--->' , ticketsData)
  },[ticketsData])

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);

  useEffect(() => {
    dispatch(getTickets({
      page: page,
      limit: limit,
      filters: filters
    }) as any);
  }, [dispatch, limit, page, filters]);

  if (!ticketsData) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="tickets"/></p>
      <div></div>
      <LoggedInUserMenu/>
    </div>

    {/*<div className="flex justify-end align-middle">*/}
    {/*  <FilterMenu filters={filters} setFilters={setFilters}/>*/}
    {/*</div>*/}

    <div className="border rounded-lg">
      <div className="flex justify-between p-6 items-center">
        <p className="text-lg text-left font-LaNord font-bold text-[#252733]">All Tickets</p>
        <div className="flex">
          <div className="flex">
            <img src="/assets/images/logos/Icon_sort.svg"
                 alt="logo"
                 width="16"
            />
            <div className="font-LaNord font-bold text-sm text-[#4B506D] px-2">Sort</div>
          </div>
          <div className="flex pl-8">
            <img src="/assets/images/logos/Icon_filter.svg"
                 alt="logo"
                 width="16"
            />
            <div className="font-LaNord font-bold text-sm text-[#4B506D] px-2">Filter</div>
          </div>
        </div>
      </div>

      {ticketsData.tickets.length? <>
            <TableContainer>
              <Table
                  sx={{minWidth: 750}}
                  aria-labelledby="tableTitle"
                  size='medium'
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" ><div className="font-bold text-[#9FA2B4]">Ticket detail</div></TableCell>
                    <TableCell align="left" ><div className="font-bold text-[#9FA2B4]">Customer name</div></TableCell>
                    <TableCell align="left" ><div className="font-bold text-[#9FA2B4]">Date</div></TableCell>
                    <TableCell align="left" ><div className="font-bold text-[#9FA2B4]">Priority</div></TableCell>
                    <TableCell align="left" ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    ticketsData.tickets.map((ticket: any, i: any) => <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={i}
                    >
                      <TableCell align="left">
                        <div className="flex items-center">
                          <div className="w-[44px] h-[44px] ml-4 mr-3 rounded-full">
                            <img src="/assets/images/user.svg" alt="" width="44"/>
                          </div>
                          <div>
                            <div className="text-sm text-left font-bold text-blue-800 max-w-[100px]">Ticket {ticket.ticket_number}</div>
                            <div className="text-[#C5C7CD] font-xs">{ticket.description}</div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="text-sm text-left font-bold text-blue-800">{ticket.admin_user.first_name} {ticket.admin_user.last_name}</div>
                        <div className="text-[#C5C7CD] font-xs ">{ticket.created}</div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="text-sm text-left font-bold text-blue-800">{ticket.created}</div>
                        <div className="text-[#C5C7CD] font-xs">{ticket.created}</div>
                      </TableCell>
                      <TableCell align="left">
                        {ticket.priority ==1 ? <span className="bg-[#FE2142] px-4 py-2 text-sm text-white font-bold rounded-lg">HIGH</span> : <span className="bg-[#018EFF] px-4 py-2 text-sm text-white font-bold rounded-lg">Low</span>}
                      </TableCell>
                      <TableCell align="left">
                        <Menu sx={{}}>
                          <div onClick={() => navigate("/tickets/" + ticket._id)}><FormattedMessage id="see_details"/></div>
                        </Menu>
                      </TableCell>
                    </TableRow>)
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <div>

              <Pagination className="my-2"
                          requestPaginationData={ticketsData}
                          limit={limit}
                          setLimit={setLimit}
                          page={page}
                          setPage={setPage}
              />
            </div>
          </> :
          <FormattedMessage id='no_contacts_found'
                            values={{
                              span: ((chunks: any) => <span className="text-blue-500 cursor-pointer"
                                                            onClick={() => navigate('add_customer')}>{chunks}</span>) as any
                            }}>
            {(chunks: any) => {
              return <div>{chunks[0]} {React.cloneElement(chunks[1])} {chunks[2]}</div>
            }}
          </FormattedMessage>
      }
    </div>

    {/*<TableContainer>*/}
    {/*  <Table*/}
    {/*    sx={{minWidth: 750}}*/}
    {/*    aria-labelledby="tableTitle"*/}
    {/*    size='medium'*/}
    {/*  >*/}
    {/*    <TableBody>*/}
    {/*      {ticketsData.tickets.map((ticket: any, i: any) => <TicketRow ticket={ticket} key={i} />)}*/}
    {/*    </TableBody>*/}
    {/*  </Table>*/}
    {/*</TableContainer>*/}

    {/*<Pagination className="mt-4"*/}
    {/*            requestPaginationData={ticketsData}*/}
    {/*            limit={limit}*/}
    {/*            setLimit={setLimit}*/}
    {/*            page={page}*/}
    {/*            setPage={setPage}*/}
    {/*/>*/}
  </div>
};

const FilterMenu = ({filters, setFilters}: any) => {
  const intl = useIntl();
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
  const open = Boolean(filtersAnchorEl);
  const handleClick = (event: any) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setFiltersAnchorEl(null);
  };

  const handleChange = (group: any, value: any) => {
    const isActive = filters[group].includes(value)
    const updatedFiltersGroup = isActive ? filters[group].filter((i: any) => i !== value) : filters[group].concat(value)
    const updatedFilters = {
      ...filters,
      [group]: updatedFiltersGroup
    }
    setFilters(updatedFilters)
  }

  return <>
    <Button
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      <FormattedMessage id="filters"/>
    </Button>
    <MuiMenu
      id="basic-menu"
      anchorEl={filtersAnchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter1"
                    checked={filters.status.includes('open')}
                    onChange={() => handleChange('status', 'open')}/>}
                          label={intl.formatMessage({id: 'open'})}/>
      </FormGroup>

      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter2"
                    checked={filters.status.includes('processing')}
                    onChange={() => handleChange('status', 'processing')}/>}
                          label={intl.formatMessage({id: 'processing'})}/>
      </FormGroup>

      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter3"
                    checked={filters.status.includes('solved')}
                    onChange={() => handleChange('status', 'solved')}/>}
                          label={intl.formatMessage({id: 'solved'})}/>
      </FormGroup>
    </MuiMenu>
  </>
}

export default withErrorBoundary(
  Tickets,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });