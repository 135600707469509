import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Cookies from "js-cookie";
import {getTicket} from "../Tickets/TicketsSlice";

export const getCustomers: any = createAsyncThunk<any, any, any>(
  'customers/getCustomers',
  async ({page, limit, filters}, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/contacts/paginated?page=' + page +
        '&limit=' + limit +
        '&filters=' + encodeURIComponent(JSON.stringify(filters)),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      )
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  })

export const getCustomer: any = createAsyncThunk<any, any, any>(
    'customers/getCustomer',
    async (customerId, thunkAPI) => {
      try {
        const response = await fetch(
            process.env.REACT_APP_API_URL+'/customers/'+customerId,
            {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
                'Content-Type': 'application/json',
              },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
);

export const addCustomer: any = createAsyncThunk<any, any, any>(
  'customers/createCustomer',
  async ({name, email, country, address, taxId, vatNumber, phone, invoiceNumber}, thunkAPI) => {
    const bodyData = {
      name: name,
      email: email,
      country: country,
      address: address,
      tax_id: taxId,
      vat_number: String(vatNumber),
      phone: phone,
      invoice_number: invoiceNumber
    }
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/contacts',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData)
        }
      )
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)
export const editCustomer: any = createAsyncThunk<any, any, any>(
  'customers/editCustomer',
  async ({id, name, email, country, address, taxId, vatNumber, phone, invoiceNumber}, thunkAPI) => {
    const bodyData = {
      name: name,
      email: email,
      country: country,
      address: address,
      tax_id: taxId,
      vat_number: String(vatNumber),
      phone: phone,
      invoice_number: invoiceNumber
    }
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/contacts/' + id,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData)
        }
      )
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }

    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const deleteCustomer: any = createAsyncThunk<any, any, any>('customers/deleteCustomer', async (id, thunkAPI) => {
  try {
    const response = await fetch(process.env.REACT_APP_API_URL + '/contacts/' + id,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
          'Content-Type': 'application/json',
        }
      }
    )
    let data = await response.json()
    if (response.status === 200) {
      return id
    } else {
      return thunkAPI.rejectWithValue(data)
    }
  } catch (e: any) {
    thunkAPI.rejectWithValue(e.response.data);
  }
})

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    selectedCustomer: null,
    customersData: null,
    emailSent: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isFetching = false

      return state
    },
  },
  extraReducers: {
    [getCustomers.rejected]: (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [getCustomers.pending]: (state) => {
      state.isFetching = true
    },
    [getCustomers.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false
      state.success = true
      state.customersData = payload
    },
    [addCustomer.rejected]: (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [addCustomer.pending]: (state) => {
      state.isFetching = true
    },
    [getCustomer.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.selectedCustomer = payload.customer
    },
    [addCustomer.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.customersData = {
        ...state.customersData,
        contacts: state.customersData.contacts.concat(payload.contact)
      }
    },
    [editCustomer.rejected]: (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [editCustomer.pending]: (state) => {
      state.isFetching = true
    },
    [editCustomer.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.customersData = {
        ...state.customersData,
        contacts: state.customersData.contacts.map((item: any) => {
          if (item._id === payload.contact.id) {
            return payload.contact
          } else {
            return item
          }
        })
      }
    },
    [deleteCustomer.rejected]: (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [deleteCustomer.pending]: (state) => {
      state.isFetching = true
    },
    [deleteCustomer.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false
      state.isSuccess = true
      state.customersData = {
        ...state.customersData,
        contacts: state.customersData.contacts.filter((i: any) => i.id !== payload)
      }
    }
  }
})

export const {clearState} = customersSlice.actions
export const customersSelector = (state: any) => state.customers
