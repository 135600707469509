import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {clearState, getCustomers, customersSelector, deleteCustomer, editCustomer, addCustomer} from './CustomersSlice';
import toast from 'react-hot-toast';
import {useNavigate, useParams} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Container,
} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "../../common/components/Pagination";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import SearchFilter from "../../common/components/SearchFilter";
import SelectBox from "../../common/components/SelectBox";
import CompanioButton from "../../common/components/CompanioButton";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {countriesData} from "../../common/utils/countriesData";

const Customers = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {customer_id} = useParams()
  const intl = useIntl()

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({
    search: ''
  })

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "546px",
    bgcolor: 'background.paper',
    borderRadius: '12px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
    p: 4,
  };

  const {isFetching, isError, errorMessage, isSuccess, customersData} = useSelector(customersSelector)

  const selectedCustomer = customersData.contacts.find((i: any) => i._id === customer_id)

  const initialCustomerState = customer_id ? {
    name: selectedCustomer.name,
    email: selectedCustomer.email,
    country: countriesData[204].name,
    address: selectedCustomer.address,
    taxId: selectedCustomer.tax_id,
    vatNumber: selectedCustomer.vat_number,
    phone: selectedCustomer.phone,
    invoiceNumber: selectedCustomer.invoice_number,
    id: customer_id
  } : emptyCustomerData

  const [customerData, setCustomerData] = useState(initialCustomerState)

  const handleInputChange = (e: any) => {
    setCustomerData({
      ...customerData,
      [e.target.id]: e.target.value
    })
  }

  const onSaveCustomerClicked = () => {
    if(customer_id) {
      dispatch(editCustomer(customerData) as any)
    } else {
      dispatch(addCustomer(customerData) as any)
      setCustomerData(emptyCustomerData)
    }
  }

  useEffect(() => {
    if (isError) {
      toast.error(intl.formatMessage({id: 'error_modifying_user'}));
    }
    if (isSuccess) {
      toast.success(intl.formatMessage({id: 'data_successfully_modified'}));
    }
    dispatch(clearState());
  }, [dispatch, isError, isSuccess, intl])


  useEffect(() => {
    console.log('customerData222222', customersData)
  },[customersData])

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  })

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage)
      dispatch(clearState())
    }
  }, [dispatch, isError, errorMessage, navigate])

  useEffect(() => {
    dispatch(getCustomers({page, limit, filters}) as any)
  }, [dispatch, limit, page, filters])

  if (!customersData) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  return (
    <div className="flex flex-col w-full px-4">
      <div className="flex justify-between mb-5 items-center">
        <p className="text-2xl text-left font-LaNord text-blue-800 font-bold"><FormattedMessage id="customers"/></p>
        <div className="flex">
          <LoggedInUserMenu/>
        </div>
      </div>

      <div className="border rounded-lg">
        <div className="flex justify-between p-6 items-center">
          <p className="text-lg text-left font-LaNord font-bold text-[#252733]">All customers</p>
          <div className="flex">
            <SearchFilter filters={filters} setFilters={setFilters}/>
            <CompanioButton size="small" variant="contained" className="h-[40px]" onClick={handleOpen}>Add customer</CompanioButton>
            <div>
              <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title">
                    <div className="font-LaNord font-bold text-[#252733] text-2xl">Add customer</div>
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                    <div className="flex flex-col items-start">
                      <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">FULL NAME</div>
                      <input placeholder="Company name" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                             value={customerData.name}
                             onChange={handleInputChange}
                             required
                             id="name"
                      />
                    </div>
                    <div className="flex gap-6 mt-6">
                      <div className="flex flex-col items-start">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">ENAIL</div>
                        <input placeholder="Your email" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                               value={customerData.email}
                               onChange={handleInputChange}
                               required
                               id="email"
                        />
                      </div>
                      <div className="flex flex-col items-start">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">PHONE NUMBER(OPTIONAL)</div>
                        <input placeholder="Your phone number" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                               onChange={handleInputChange}
                               value={customerData.phone}
                               id="phone"
                               required
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 mt-6">
                      <div className="flex flex-col items-start">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">TAX ID</div>
                        <input placeholder="2354357546" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                               value={customerData.taxId}
                               onChange={handleInputChange}
                               required
                               id="taxId"
                        />
                      </div>
                      <div className="flex flex-col items-start">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">VAT NUMBER</div>
                        <input placeholder="US7364820487" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                               value={customerData.vatNumber}
                               onChange={handleInputChange}
                               required
                               id="vatNumber"
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 mt-6 mb-6">
                      <div className="flex flex-col items-start w-6/12">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">CITY</div>
                        <input placeholder="Chicago" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                               value={customerData.address}
                               onChange={handleInputChange}
                               required
                               id="address"
                        />
                      </div>
                      <div className="flex flex-col items-start w-6/12">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">COUNTRY</div>
                        <div className="flex border rounded-lg px-4 bg-[#FCFDFE] justify-between justify-items-center items-center mt-2 w-full">
                          <select name="Umbrella Corporation" className="h-[40px] appearance-none text-sm font-LaNord text-[#9FA2B4] w-full"
                                  id="country"
                                  value={customerData.country}
                                  onChange={handleInputChange}
                          >
                            {countriesData.map((c, i) => {
                              return <option className="text-left" value={c.name} key={i} style={{maxWidth:200}}>{c.name}</option>
                            })}
                          </select>
                          <ArrowDropDownIcon sx={{color: '#9FA2B4'}}/>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end border-t pt-6">
                      <button className="font-bold font-LaNord text-[#9FA2B4] text-sm pr-4" onClick={handleClose}>Cancel</button>
                      <CompanioButton size="small" variant="contained" className="w-32 h-[40px]" disabled={isFetching || !Object.values(customerData).every(Boolean)}
                                      onClick={onSaveCustomerClicked}>Add</CompanioButton>
                    </div>
                  </Typography>
                </Box>
              </Modal>
            </div>
          </div>
        </div>

        {customersData.contacts.length ? <>
              <TableContainer>
              <Table
                  sx={{minWidth: 750}}
                  aria-labelledby="tableTitle"
                  size='medium'
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" ><div className="font-bold text-[#9FA2B4]">Full name</div></TableCell>
                    <TableCell align="left" ><div className="font-bold text-[#9FA2B4]">Location</div></TableCell>
                    <TableCell align="left" ><div className="font-bold text-[#9FA2B4]">TAX ID and VAT</div></TableCell>
                    <TableCell align="left" ><div className="font-bold text-[#9FA2B4]">Email and phone</div></TableCell>
                    <TableCell align="left" ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    customersData.contacts.map((c: any, i: any) =>
                        <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={i}
                    >
                      <TableCell align="left">
                        <div className="flex items-center">
                          <div className="w-[44px] h-[44px] ml-4 mr-3 rounded-full">
                            <img src="/assets/images/user.svg" alt="" width="44"/>
                          </div>
                          <div>
                            <div className="cursor-pointer hover:text-red-600 text-sm text-left font-bold text-[#253292] max-w-[100px]" onClick={() => navigate( ''+ c.id)}>{c.name}</div>
                            <div className="text-[#C5C7CD] font-xs">Description</div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="text-sm text-left font-bold text-blue-800">{c.address}</div>
                        <div className="text-[#C5C7CD] font-xs">Country</div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="text-[#4B506D] font-xs">TAX ID: {c.tax_id}</div>
                        <div className="text-[#C5C7CD] font-xs">VAT: {c.vat_number}</div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="text-[#4B506D] font-xs">{c.email}</div>
                        <div className="text-[#C5C7CD] font-xs">{c.phone}</div>
                      </TableCell>
                      <TableCell align="left">
                        <SelectBox />
                      </TableCell>
                    </TableRow>)
                  }
                </TableBody>
              </Table>
            </TableContainer>

              <div>

                <Pagination className="my-2"
                            requestPaginationData={customersData}
                            limit={limit}
                            setLimit={setLimit}
                            page={page}
                            setPage={setPage}
                />
              </div>
            </> :
            <FormattedMessage id='no_contacts_found'
                              values={{
                                span: ((chunks: any) => <span className="text-blue-500 cursor-pointer"
                                                              onClick={() => navigate('add_customer')}>{chunks}</span>) as any
                              }}>
              {(chunks: any) => {
                return <div>{chunks[0]} {React.cloneElement(chunks[1])} {chunks[2]}</div>
              }}
            </FormattedMessage>
        }
      </div>


    </div>
  )
}

const emptyCustomerData = {
  name: '',
  email: '',
  country: countriesData[204].name,
  address: '',
  taxId: '',
  vatNumber: '',
  phone: '',
  invoiceNumber: '1'
}

export default Customers


