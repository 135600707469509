import React, {useState} from "react";
import {Box, Button, TextField} from "@mui/material";

const Contract = ({registrationTokenUser, handleStep}: {registrationTokenUser: any, handleStep: any}) => {
  const [signature, setSignature] = useState('')
  const {first_name, last_name} = registrationTokenUser

  return <div>
    <p className="text-left max-h-40 overflow-scroll">
    Carefully read the following contract, as it describes the terms and conditions under which the accounting and business consulting services offered by CompanioEstonia OÜ will be governed.

    It is your responsibility, as user(s), client(s) or potential client(s) to read this contract, as well as the legal terms and conditions, and the privacy policy, before accepting and contracting the services of CompanioEstonia OÜ.

    1. Subjects of the contract
    On the one hand, Mario visdhg, with email address mario.visnjic+cuss@companio.email, acting on his or her own behalf and as legal representative of his business (from now on, the CLIENT).

    On the other, CompanioEstonia OÜ, registered in Estonia, registration number 14522788, and address Lõõtsa 2A, Tallinn, 11415, Harju maakond, Estonia, represented by the board member Ignacio Nieto Carvajal, born 06/01/1980 and with personal code of e-Resident 38001060164. (from now on, the PROVIDER).

    2. Terms and conditions of service
    Your registration on the website of “Companio” (https://companio.co and associated sites such as https://companio.es) as a customer and its use, including freelance registration, monthly accounting and any other consulting services of CompanioEstonia OÜ constitutes your acceptance of being subject to these terms, which establishes a contractual relationship between you and CompanioEstonia OÜ.

    If you do not accept this contract, you can not access or make use of the services of “Companio” offered by CompanioEstonia OÜ. These terms expressly replace previous agreements or contracts with you. No other contract, either verbal or written, will be applied except when agreed and authorized in writing by CompanioEstonia OÜ.

    3. Definitions
    The terms “we“, “our” and “us“, and “PROVIDER” refer to CompanioEstonia OÜ and the activity offered by CompanioEstonia OÜ in the framework of the services of “Companio”, through the website https://companio.co and associated sites such as https://companio.es.

    The terms “client(s)“, “you“, or “CLIENT“, refer to the site’s customers, users, and any other financial investor of our services.

    The term “Your company” refers to your company or financial institution, of which the CLIENT (you) is the representative, shareholder or member of the board of directors.

    The term “Management of your business” refers to all activities and services offered by CompanioEstonia OÜ to you as a freelancer, your business, or your company, including, but not limited to, one or several of the following activities: accounting, business consulting, presentation of annual, quarterly, or monthly taxes, VAT declarations, annual activities report, company and business administrative tasks, taxes and tax reports, and any other product or service you may have acquired from us.

    The term “content” refers to any text, images, products, publications, links, software, applications and external programs necessary for the development of the activity to manage your business.

    4. Object
    The purpose of the contract is the agreement consisting of the provision, by the PROVIDER, of the following works or services:

    Monthly accounting services and tax reports
    Quarterly tax reports
    Annual tax reports
    Administrative tasks related to business or financial management of your business, your freelance activity, or your company
    Business consulting
    Any other service included in our products.
    The provision of services under this contract will be performed by the PROVIDER following the guidelines set out from the needs of the CLIENT, these will, in turn, necessarily determine the scope, form, and content of the provision of the service contracted.

    In any case, the PROVIDER will provide the service with total autonomy and independence with respect to the CLIENT and in accordance with the degree of specialization and diligence that characterizes him, according to the uses and customs of his activity sector.

    The responsibilities and obligations of both parties to carry out the specified activities and services are described in section 6.

    5. Modifications or extensions of the object
    If during the term of this contract the CLIENT and/or the PROVIDER considers it appropriate to modify and/or extend the provision of services that are the object of this contract, both Parties must negotiate the scope of said modifications or extensions in the provision.

    Where appropriate, if the CLIENT or the PROVIDER considers it necessary to modify or alter the services that are the object of this contract, the latter must notify the other Party in order to negotiate and/or agree on the new price for the provision of services.

    The provision of any additional or complementary service to those described here, and that has been so requested by the CLIENT, will be governed by the stipulations of this contract.

    6. Obligations of the parties
    6.1. Obligations of the PROVIDER
    The PROVIDER undertakes to provide the services for which he is hired in a diligent manner and in accordance with the professional uses and customs of his sector of activity.

    Likewise, the PROVIDER agrees to perform the provision of services within the dates and/or deadlines agreed with the CLIENT.

    In addition, the PROVIDER acknowledges having informed the CLIENT, through the information collected on the website “Companio” (https://companio.co and associated sites such as https://companio.es), or by direct communication with the CLIENT, about the essential characteristics of the provision of services covered by this contract and/or all information on how and/or under what conditions are provided or performed, in addition to any other information that may be necessary.

    The PROVIDER agrees to inform the client at least fifteen days in advance if there are significant changes in the conditions and terms of use, as well as this contract, including the price for the services offered by the PROVIDER.

    The obligations of the PROVIDER for the monthly accounting service include:

    record the cash receipts, sales invoices, and purchase invoices of the client.
    prepare and record taxes corresponding to the revenue, expenses, and any other relevant factor of the business of the customer.
    perform the bank reconciliation and record bank transactions.
    import and access bank statements and movements of the client for such bank reconciliation (under express authorization of the CLIENT).
    prepare and send tax and VAT payment reports to the Tax Office and Customs Board.
    Prepare the necessary reports for submission to the Tax Authorities with the activities of the business of the customer, monthly, quarterly, and annually.
    Prepare and record payments to the Tax Office derived from the obligations of the CLIENT in their bank accounts (under express authorization of the CLIENT).
    6.2. Obligations of the CLIENT
    The CLIENT undertakes to inform or deliver all useful, relevant and truthful information for the correct provision of services by the PROVIDER. Especially, all data or information related to their particular needs and that would contribute to an optimal provision of services by the PROVIDER. The CLIENT agrees on delivering that information in the way and format specified by the PROVIDER. The deadline for such delivery is the 5th day of the following month for which the services are being provided.

    This includes, but is not limited to: the personal data of the CLIENT(s) and their business, activity as freelancer, or company, all sales and purchase invoices of the company in the periods of the provision of the service, and the bank statements and movements necessary to make the bank reconciliation for the company.

    Likewise, the CLIENT commits to collaborate with the PROVIDER during the provision of its services, not hindering, hampering or obstructing them.

    It is the responsibility of the CLIENT(s) to guarantee that their company, and its activities, strictly adhere to legality and the current legislation, both local and (when applicable) European. The CLIENT(s) is ultimately responsible for any claim, sanction, fine or any other legal process directed to their company. In no case shall the PROVIDER be liable for the actions or consequences derived from the actions of the CLIENT(s), their business, their activity as freelancers, or their company.

    The CLIENT(s) agrees to pay the fee for the services provided by the PROVIDER as part of this contract, as specified in the pricing page.

    The prices specified in this contract do not include VAT.

    It is the responsibility of the CLIENT to guarantee that the documentary support of the transactions, invoices and other financial data provided to the PROVIDER is correct. This includes (but is not limited to) bank movements and transactions, the data of customers, their VAT numbers and the amounts specified in the invoices. If a failure to provide the right information from the CLIENT or a mistake in the invoices provided by the CLIENT forces the PROVIDER to redo or resubmit the reports and/or ledger annotations for one accountancy period, the CLIENT should compensate the PROVIDER with one extra monthly accountancy fee based on the prices defined previously in this section.

    The CLIENT must pay the monthly invoice in due time. This invoice will be sent to the client starting from the first day of the following month for which the service is being provided.

    The obligations of the CLIENT include also the submission of any reports that the PROVIDER cannot submit to the authorities itself for any reason.

    7. Follow-up of the execution of the agreement
    The PROVIDER undertakes, in order to facilitate the monitoring of the provision of services included in this contract, to periodically send the CLIENT information and/or documentation on the status of the activities carried out in the execution of this contract.

    However, the CLIENT reserves the right to require from the PROVIDER any additional information or documentation that may be necessary to perform a proper follow-up of the provision of services and, where appropriate, under the proper justification in compliance with the applicable regulations.

    The PROVIDER reserves the right to discuss and revise the prices of this contract by notifying the other party at least one month in advance.

    8. Duration
    The PROVIDER will start to provide its services to the client from the moment the contract between the parties becomes effective. The accountancy services will be provided from the moment CompanioEstonia OÜ, its representatives or employees have been given the required documents and permissions to take over the accounting of the activity of the CLIENT as freelancer, his or her business, or his or her company. The monthly accountancy fee will be charged starting from the month when the accountancy services are being provided to the customer. The CLIENT’s financial period is from January 1 to December 31.

    The services and functions described in the clause relating to the object of the contract will be provided by the PROVIDER to the CLIENT for as long as the CLIENT deems necessary. The CLIENT agrees to notify the PROVIDER at least before the end of the month of the termination of the contract, in order to complete the pending activities that are part of the services offered by the PROVIDER. The current month will be charged if the accounting services offered by the PROVIDER have been provided to the customer.

    The CLIENT must pay for any pending invoice or any services provided by the PROVIDED that were left unpaid.

    9. Payment method
    Payment for the services provided by CompanioEstonia OÜ are final, and non-refundable. This includes the payment for the registration of the activity of the CLIENT as freelancer, his or her business, or his or her company, even if CLIENT decides to discontinue the process, or it is not granted by the authorities, or any other situation preventing the CLIENT from finishing the company registration process.

    The price for the services contracted will be billed, always upon presentation of the appropriate invoice, in accordance with the following instructions:

    From the first day of the month following the month for which the services are being provided, depending on the services actually provided and upon presentation of the corresponding invoice.

    The PROVIDER will invoice the amount of the price due according to the payment terms of the services as stated in the previous paragraph, and must break down in each of the invoices the works or the concepts of the services for which they are issued. The amount of the invoices will be understood to be made effective by the CLIENT as soon as the payment invoice or proof of payment is presented.

    The payment must be performed by bank card payment, deposit or bank transfer to the account of which the PROVIDER is the representative. The PROVIDER will provide the CLIENT with the appropriate online payment mechanisms to allow payment by means of a bank card, deposit or bank transfer.

    10. Resources and/or material for the provision of services
    The PROVIDER shall use, for the provision of the services agreed here, the appropriate material means and, where appropriate, its own staff, who would perform their duties using the most appropriate resources, and following the instructions issued by the PROVIDER accordingly.

    The PROVIDER also undertakes to ensure that the staff assigned to the provision of the contracted services knows the particular needs of the CLIENT in relation to said contracted services.

    In particular, the PROVIDER undertakes that the members of the staff or employees assigned to the development of the contracted services have the appropriate qualifications and experience to carry out the required work. Additionally, the PROVIDER undertakes that these employees know the content of the clauses relating to intellectual property, confidentiality, GDPR, and the treatment of personal data that are established in the contract, as well as their personal obligation to respect them.

    11. Responsibility
    The PROVIDER undertakes to carry out its work diligently and with due quality, committing itself to assume responsibility for errors, defects or delays produced in its execution, or its incorrect execution or non-execution.

    However, the PROVIDER will not be responsible for errors, defects or delays produced in the execution, or incorrect execution or non-execution of the contract, when this emanates from the omission or falsification of any information, document or data provided by the CLIENT; The PROVIDER is also not obliged to verify the authenticity and suitability of said data or information.

    Finally, it is the responsibility of the CLIENT to guarantee that his business complies with the law in Estonia as well as in Europe.

    12. Force majeure
    Neither Party can be considered responsible for a delay, defect or error in the execution of its professional obligations when those are due or caused by a cause of force majeure. However, the Parties are obliged to notify the other Party when they have knowledge that a cause of this nature has occurred and will affect the correct execution of their obligations.

    “Force majeure” shall mean: flood, fire, explosion, damage to the production plant, lockout, strike, civil disturbance, blockade, embargo, mandate, law, order, regulation, ordinance, demand or request of any Government or of any subdivision or representative of this, or any other cause, that is beyond the control of the party involved. Under no circumstances, the lack of funds constitutes a cause of force majeure.

    13. The duty of secrecy and confidentiality
    The Parties undertake that the development of this contract is governed by the utmost confidentiality, respecting the duty of diligence and professional secrecy. Thus, both Parties undertake not to disclose any information relating to this contract, whether relating to negotiations, transactions or any matter of the parties, or the content or existence of the agreement subscribed here, or about the activities or services that are going to be provided.

    In particular, the PROVIDER agrees that any information or documentation that the CLIENT delivers to him or to which he has access for any reason, directly or indirectly, will be used only to serve the fulfillment of the provision of services object of this contract and, in Any case, to perform their services respecting the principles of good faith, diligence and the duty of secrecy.

    Consequently, it may not provide third parties or use for its own benefit the information obtained in the exercise of the provision of services, provided that it may favor them or when said disclosure could harm the CLIENT in any way.

    Consequently, the PROVIDER expressly undertakes not to make copies, record, reproduce, manipulate, disclose to third parties, or make available to them the information or documentation that may be received directly or indirectly from the CLIENT, unless it is strictly necessary for the fulfillment of the services that the PROVIDER offers to the CLIENT.

    14. Data protection
    The Parties are aware that by signing this contract they consent that their personal data collected on the website of Companio (https://companio.co and associated sites such as https://companio.es), as well as any information that could be collected in the future for the provision of the services provided by the PROVIDER, could be incorporated by the other Party to its own automated file or not of data collection in order to correctly execute the professional relationship and, eventually, for an administrative and/or commercial management.

    In any case, the Parties undertake that these personal data will not be communicated in any case to third parties unless it is necessary for the fulfillment of the services offered by the PROVIDER to the client. If it were the case that some type of communication of personal data were to be made, they always commit themselves and previously, to request the express, informed, and unequivocal consent of the Party that is the holder of said personal data.

    This clause does not result in any limitation or restriction for the Parties regarding the exercise of rights of access, rectification, deletion, limitation, portability or opposition with which they may have.

    Regarding personal data to which the PROVIDER has access as a result of the provision of the service object of this contract, they are the exclusive property of the CLIENT and shall be understood as voluntarily provided by him, and shall only be used for the purpose of providing the services required in the contract, the PROVIDER pledging not to apply them or use them for purposes other than those agreed upon and not to communicate them to other people, not even for conservation purposes, and also to return the files, automated or not, to the CLIENT. of a personal nature to those who would have had access, when required to do so by the CLIENT, and/or proceed to the destruction of the media and/or documents containing said data at the end of this contract, and, in any case, at the expiration of this contract.

    In any case, the PROVIDER agrees that, the processing of personal data to which he has access by reason of the provision of the services object of this contract, and of any information, in general, is provided by the CLIENT, be carried out maintaining the strictest professional secrecy and absolute confidentiality with respect to the data of the same, as well as to diligently fulfill the duty of guardianship and custody over them imposed by the GDPR.

    These duties shall be applicable to the PROVIDER during the term of this contract and even after the termination for any reason thereof, being liable to the CLIENT for breach of the obligations assumed herein.

    Likewise, the PROVIDER undertakes to adopt the necessary technical and/or organizational measures to protect the personal data to which it has access and to prevent its alteration, loss, treatment and unauthorized access, and this in accordance with the GDPR and its complementary development regulations.

    The PROVIDER will respond to the CLIENT for the breach of the obligations assumed under this clause, even when said breach is attributable, in its case, to the working personnel of which he must respond legally.

    15. Causes of resolution
    The Parties undertake to fulfill the obligations that emanate from this contract for each of them under the terms and conditions established throughout it. In the event that any of the Parties breached any of the obligations of the contract, or fulfilled them in a defective manner, the Party that in turn did comply with its own may consider that there has been a breach of the contract contained in this document, being empowered to rescind the same.

    In addition, the contract will be considered solved by the definitive or provisional insolvency, the suspension of payments, the bankruptcy and/or the liquidation of any of the Parties.

    It will also be cause for early termination of the contract when there are demonstrable deficiencies in the services provided by the PROVIDER, or after two months of non-payment for the services performed by the CLIENT.

    Likewise, it will be cause for termination of the contract when the PROVIDER stops providing the services or part of the services that are part of the object of this contract. It will be understood that the PROVIDER has stopped providing the services when this activity is not carried out regularly or with the material and personal means appropriate to it.

    Likewise, the contract may be terminated at the request of any of the Parties, provided that the Party that so wishes, notifies the other Party in writing and in accordance with the notification procedure of this contract, at least two months in advance.

    In the latter case, when the contract is terminated by the CLIENT, it must, however, pay the PROVIDER any invoice accrued and not paid for the services rendered during the time the contract has been in force, as well as a possible compensation for damages if you exercise your will without respecting the notice and method of notification established here.

    Likewise, when the contract is resolved by the will of the PROVIDER, the latter must provide the CLIENT with any document, element, good, material, and/or production that could have resulted from his activity of rendering services up to that moment, in addition to a possible compensation for damages that could derived from not respecting the notice, times and notification methods provided herein.

    16. Notifications
    To make any notification between the Parties subject to this contract, it will be used, by the PROVIDER, the email address with which the CLIENT made the registration for the services of Companio through the website https://companio.co and associated sites such as https://companio.es, and by the CLIENT, the address contact@companio.co.

    17. Legal actions, applicable legislation, and jurisdiction
    This contract replaces any previous contract for the same services between the CLIENT and the PROVIDER.

    Any relative litigation, especially, but not limited, to the formation, validity, interpretation, signature, provision, execution or termination of this contract and, in general, to the relationship established between the Parties, will be subject to Estonian law and the courts of Estonia. If any clause of this contract is considered invalid, this fact will not affect the validity of the remaining clauses that form this contract.

    In case it is proven that the actions of the PROVIDER incur any deficiency or defect by the authorities, courts or juries of Estonia, the responsibility of the PROVIDER will be limited to three months of payment for the accounting services. The CUSTOMER shall indemnify the LENDER for any risk or liability derived from false, invalid, illegal or forged documents delivered by the CUSTOMER to the PROVIDER.

      {signature}
  </p>

    Add your signature (Type: {first_name} {last_name})

    <TextField
      value={signature}
      onChange={(e) => setSignature(e.target.value)}
      id="singature"
      label="signature"
      sx={{mt:2}}
    />

    <Box sx={{ mb: 2 }}>
      <div>
        <Button
          variant="contained"
          onClick={() => handleStep(1, {})}
          disabled={signature !== (first_name + ' ' + last_name)}
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </Button>
      </div>
    </Box>


  </div>
}

export default Contract