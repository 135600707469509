import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clearState, addCustomer, customersSelector, editCustomer} from './CustomersSlice';
import {countriesData} from '../../common/utils/countriesData';
import {FormattedMessage, useIntl} from 'react-intl';

import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import BackButton from "../../common/components/BackButton";
import {useNavigate, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";

const AddCustomer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {customer_id} = useParams()
  const intl = useIntl()

  const {isFetching, isError, isSuccess, customersData} = useSelector(
    customersSelector
  );

  const selectedCustomer = customersData.contacts.find((i: any) => i._id === customer_id)

  const initialCustomerState = customer_id ? {
    name: selectedCustomer.name,
    email: selectedCustomer.email,
    country: countriesData[204].name,
    address: selectedCustomer.address,
    taxId: selectedCustomer.tax_id,
    vatNumber: selectedCustomer.vat_number,
    phone: selectedCustomer.phone,
    invoiceNumber: selectedCustomer.invoice_number,
    id: customer_id
  } : emptyCustomerData


  const [customerData, setCustomerData] = useState(initialCustomerState)

  const handleInputChange = (e: any) => {
    setCustomerData({
      ...customerData,
      [e.target.id]: e.target.value
    })
  }

  const onSaveCustomerClicked = () => {
    if(customer_id) {
      dispatch(editCustomer(customerData) as any)
    } else {
      dispatch(addCustomer(customerData) as any)
      setCustomerData(emptyCustomerData)
    }
  }

  useEffect(() => {
    if (isError) {
      toast.error(intl.formatMessage({id: 'error_modifying_user'}));
    }
    if (isSuccess) {
      toast.success(intl.formatMessage({id: 'data_successfully_modified'}));
    }
    dispatch(clearState());
  }, [dispatch, isError, isSuccess, intl])


  return (
    <div className="flex flex-col w-full px-4">
      <div className="flex justify-between mb-5">
        <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="customers"/></p>
        <LoggedInUserMenu />
      </div>


      <BackButton className="mr-auto" onClick={() => navigate('/customers')}/>

      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': {m: 2, width: '50ch'},
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          value={customerData.name}
          onChange={handleInputChange}
          required
          id="name"
          label="Customer Name"
        />
        <TextField
          value={customerData.email}
          onChange={handleInputChange}
          required
          id="email"
          label="Email"
        />
        <FormControl sx={{m: 2, width: '50ch'}}>
          <InputLabel id="country">Country</InputLabel>
          <Select
            labelId="country"
            className="text-left"
            id="country"
            value={customerData.country}
            label="Select Country"
            onChange={handleInputChange}
          >
            {countriesData.map((c, i) => {
              return <MenuItem className="text-left" value={c.name} key={i}>{c.name}</MenuItem>
            })}

          </Select>
        </FormControl>
        <TextField
          value={customerData.address}
          onChange={handleInputChange}
          required
          id="address"
          label="Address"
        />
        <TextField
          value={customerData.taxId}
          onChange={handleInputChange}
          required
          id="taxId"
          label="Tax ID"
        />
        <TextField
          value={customerData.vatNumber}
          onChange={handleInputChange}
          required
          id="vatNumber"
          label="VAT Number"
        />
        <TextField onChange={handleInputChange} value={customerData.phone} id="phone" label="Phone Number"
                   required/>
        <TextField
          value={customerData.invoiceNumber}
          onChange={handleInputChange}
          id="invoiceNumber"
          label="Invoice Number"
        />

      </Box>
      <div className="flex justify-end">
        <Button
          disabled={isFetching || !Object.values(customerData).every(Boolean)}
          onClick={onSaveCustomerClicked}
          variant="contained" sx={{mr: 6}}>Submit</Button>
      </div>

    </div>
  )
}

const emptyCustomerData = {
  name: '',
  email: '',
  country: countriesData[204].name,
  address: '',
  taxId: '',
  vatNumber: '',
  phone: '',
  invoiceNumber: '1'
}

export default AddCustomer
