import {CardContent, Chip} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {banksSelector, getLinkNordigenBank} from "./BanksSlice";
import {withErrorBoundary} from "react-error-boundary";
import {useDispatch, useSelector} from "react-redux";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import React, {useEffect, useState} from "react";
import SearchFilter from "../../common/components/SearchFilter";
import CompanioButton from "../../common/components/CompanioButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {clearState} from "../Customers/CustomersSlice";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import AddBank from "./AddBank";

const AddBankList = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {isError, errorMessage, isSuccess} = useSelector(banksSelector)

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "546px",
    bgcolor: 'background.paper',
    borderRadius: '12px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
  };

  const [filters, setFilters] = useState({
    search: ''
  })

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);



  useEffect(() => {
    if (isError) {
      toast.error(intl.formatMessage({id: 'error_modifying_user'}));
    }
    if (isSuccess) {
      toast.success(intl.formatMessage({id: 'data_successfully_modified'}));
    }
    dispatch(clearState());
  }, [dispatch, isError, isSuccess, intl])


  const linkToNordigenBank = (bankId: string) => {
    dispatch(getLinkNordigenBank(bankId) as any)
      .then((e: any) => {
        window.location.href = e.payload.link
      })
  }

  return <>
    <div className="flex flex-col w-full px-4">
      <div className="flex justify-between mb-5">
        <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="banks"/></p>
        <div></div>
        <LoggedInUserMenu/>
      </div>
      <div className="border rounded-lg">
        <div className="flex justify-between px-6 pt-6 pb-2 items-center">
          <SearchFilter filters={filters} setFilters={setFilters}/>
          <CompanioButton size="small" variant="contained" className="h-[40px]"
                          onClick={handleOpen}>Add bank account</CompanioButton>
          <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title">
                <div className="font-LaNord font-bold text-[#252733] text-2xl px-7 pt-8">Add bank account</div>
              </Typography>
              <Typography id="modal-modal-description">
                <div className="text-sm font-LaNord text-[#9FA2B4] px-7 mt-6">
                  Unfortunately, we cannot access the statements for this type of bank account automatically. You need to access your bank, download the statements in CSV (oir Excel) and PDF, and upload them in the ‘Bank movements¡ section at least once a month.
                </div>
                <AddBank />
              </Typography>
            </Box>
          </Modal>
        </div>
        <div className="flex flex-wrap gap-8 p-7">
          <div className="mb-4 border rounded-lg w-[330px] p-2.5">
            <CardContent className="w-full flex-col text-left">
              <div className="flex justify-between">
                <Chip sx={{borderRadius: '8px'}} label={intl.formatMessage({id: 'recommended'})} color="success"/>
                <img alt='logo' src="/assets/images/banks/transferwise.png" style={{maxWidth: '64px'}}/>
              </div>
              <p className="font-semibold text-base font-LaNord text-[#252733] pt-4">Wise</p>
              <div className="font-LaNord text-xs text-[#9FA2B4] pt-4">Your Stripe account is configured but no API is avaliable.</div>
              <div className="flex justify-between pt-6">
                <p className="cursor-pointer text-sm font-LaNord font-semibold text-[#253292]" onClick={() => linkToNordigenBank('WISE_TRWIGB22')}>
                  <FormattedMessage id='link'/></p>
                <a href="https://wise.com/gb/business" target="_blank" rel="noreferrer" className="text-sm font-LaNord font-semibold text-[#253292]"><FormattedMessage
                    id='open_your_account'/> </a>
              </div>
            </CardContent>
          </div>

          <div className="mb-4 border rounded-lg w-[330px] p-2.5">
            <CardContent className="w-full flex-col text-left">
              <div className="flex justify-between">
                <Chip sx={{borderRadius: '8px', backgroundColor: '#F0F1F7', color: '#9FA2B4', fontWeight: 'bold'}} label={intl.formatMessage({id: 'manual'})}/>
                <img alt='logo' src="/assets/images/banks/revolut_business.png" style={{maxWidth: '64px'}}/>
              </div>
              <p className="font-semibold text-base font-LaNord text-[#252733] pt-4">Revolut Business</p>
              <div className="font-LaNord text-xs text-[#9FA2B4] pt-4">Lorem ipsum dolor sit amet, ad imperdiet liberavisse cum, an modus ignota possim per.</div>
              <div className="flex justify-between pt-6">
                <p className="cursor-pointer text-sm font-LaNord font-semibold text-[#253292]" onClick={() => linkToNordigenBank('REVOLUT_REVOGB21')}>
                  <FormattedMessage id='link'/></p>
                <a href="https://www.revolut.com/business/business-account" target="_blank"
                   rel="noreferrer" className="text-sm font-LaNord font-semibold text-[#253292]"><FormattedMessage id='open_your_account'/> </a>
              </div>
            </CardContent>
          </div>

          <div className="mb-4 border rounded-lg w-[330px] p-2.5">
            <CardContent className="w-full flex-col text-left">
              <div className="flex justify-between">
                <Chip sx={{borderRadius: '8px', backgroundColor: '#F0F1F7', color: '#9FA2B4', fontWeight: 'bold'}} label={intl.formatMessage({id: 'manual'})}/>
                <img alt='logo' src="/assets/images/banks/stripe.png" style={{maxWidth: '64px'}}/>
              </div>
              <p className="font-semibold text-base font-LaNord text-[#252733] pt-4">Stripe</p>
              <div className="font-LaNord text-xs text-[#9FA2B4] pt-4">Your Stripe account is configured but no API is avaliable.</div>
              <div className="flex justify-between pt-6">
                <p className="cursor-pointer text-sm font-LaNord font-semibold text-[#253292]" onClick={() => console.log('stripe')}><FormattedMessage
                    id='link'/></p>
                <a href="https://stripe.com/en-bg" target="_blank" rel="noreferrer" className="text-sm font-LaNord font-semibold text-[#253292]"><FormattedMessage
                    id='open_your_account'/> </a>
              </div>
            </CardContent>
          </div>
          <div className="mb-4 border rounded-lg w-[330px] p-2.5">
            <CardContent className="w-full flex-col text-left">
              <div className="flex justify-between">
                <Chip sx={{borderRadius: '8px', fontWeight: 'bold'}} label={intl.formatMessage({id: 'no_api_access'})} color="warning"/>
                <img alt='logo' src="/assets/images/banks/mollie.png" style={{maxWidth: '64px'}}/>
              </div>
              <p className="font-semibold text-base font-LaNord text-[#252733] pt-4">Mollie</p>
              <div className="font-LaNord text-xs text-[#9FA2B4] pt-4">Your Mollie account is configured & the API has been linked.</div>
              <div className="flex justify-between pt-6">
                <p className="cursor-pointer text-sm font-LaNord font-semibold text-[#253292]" onClick={() => console.log('mollie')}><FormattedMessage
                    id='link'/></p>
                <a href="https://www.mollie.com/en" target="_blank" rel="noreferrer" className="text-sm font-LaNord font-semibold text-[#253292]"><FormattedMessage
                    id='open_your_account'/> </a>
              </div>
            </CardContent>
          </div>

          <div className="mb-4 border rounded-lg w-[330px] p-2.5">
            <CardContent className="w-full flex-col text-left">
              <div className="flex justify-between">
                <Chip sx={{borderRadius: '8px', fontWeight: 'bold'}} label={intl.formatMessage({id: 'no_api_access'})} color="warning"/>
                <img alt='logo' src="/assets/images/banks/payoneer.png" style={{maxWidth: '64px'}}/>
              </div>
              <p className="font-semibold text-base font-LaNord text-[#252733] pt-4">Payoneer</p>
              <div className="font-LaNord text-xs text-[#9FA2B4] pt-4">Your Payoneer account is configured but no API is available.</div>
              <div className="flex justify-between pt-6">
                <p className="cursor-pointer text-sm font-LaNord font-semibold text-[#253292]"
                   onClick={() => console.log('add_first_bank_account.html/payoneer')}><FormattedMessage id='link'/></p>
                <a href="https://www.payoneer.com/enterprise/" target="_blank" rel="noreferrer" className="text-sm font-LaNord font-semibold text-[#253292]"><FormattedMessage
                    id='open_your_account'/> </a>
              </div>
            </CardContent>
          </div>

          <div className="mb-4 border rounded-lg w-[330px] p-2.5">
            <CardContent className="w-full flex-col text-left">
              <div className="flex justify-between">
                <Chip sx={{borderRadius: '8px', fontWeight: 'bold'}} label={intl.formatMessage({id: 'no_api_access'})} color="warning"/>
                <img alt='logo' src="/assets/images/banks/paysera.png" style={{maxWidth: '64px'}}/>
              </div>
              <p className="font-semibold text-base font-LaNord text-[#252733] pt-4">Paysera</p>
              <div className="font-LaNord text-xs text-[#9FA2B4] pt-4">Your Paysera account is configured but no API is available.</div>
              <div className="flex justify-between pt-6">
                <p className="cursor-pointer text-sm font-LaNord font-semibold text-[#253292]"
                   onClick={() => console.log('add_first_bank_account.html/paysera')}><FormattedMessage id='link'/></p>
                <a href="https://www.paysera.bg/v2/en-BG/business-solutions" target="_blank"
                   rel="noreferrer" className="text-sm font-LaNord font-semibold text-[#253292]"><FormattedMessage id='open_your_account'/> </a>
              </div>
            </CardContent>
          </div>

          <div className="mb-4 border rounded-lg w-[330px] p-2.5">
            <CardContent className="w-full flex-col text-left">
              <div className="flex justify-between">
                <Chip sx={{borderRadius: '8px', fontWeight: 'bold'}} label={intl.formatMessage({id: 'not_recommended'})} color="error"/>
                <img alt='logo' src="/assets/images/banks/paypal.png" style={{maxWidth: '64px'}}/>
              </div>
              <p className="font-semibold text-base font-LaNord text-[#252733] pt-4">Paypal</p>
              <div className="font-LaNord text-xs text-[#9FA2B4] pt-4">Your Paypal account is configured but no API is available.</div>
              <div className="flex justify-between pt-6">
                <p className="cursor-pointer text-sm font-LaNord font-semibold text-[#253292]" onClick={() => console.log('paypal')}><FormattedMessage
                    id='link'/></p>
                <a href="https://www.mollie.com/en" target="_blank" rel="noreferrer" className="text-sm font-LaNord font-semibold text-[#253292]"><FormattedMessage
                    id='open_your_account'/> </a>
              </div>
            </CardContent>
          </div>

          {/*<div className="mb-4 border rounded-lg w-[330px] p-2.5">*/}
          {/*  <CardContent className="w-full flex-col text-left">*/}
          {/*    <div className="flex justify-between">*/}
          {/*      <Chip sx={{borderRadius: '8px', fontWeight: 'bold'}} label={intl.formatMessage({id: 'not_recommended'})} color="error"/>*/}
          {/*      <img alt='logo' src="/assets/images/banks/paypal.png" style={{maxWidth: '64px'}}/>*/}
          {/*    </div>*/}
          {/*    <p className="font-semibold text-base font-LaNord text-[#252733] pt-4"><FormattedMessage id='custom_banking_solution'/></p>*/}
          {/*    <div className="flex justify-between pt-6">*/}
          {/*      <p className="cursor-pointer text-sm font-LaNord font-semibold text-[#253292]" onClick={() => console.log('other')}><FormattedMessage*/}
          {/*          id='link'/></p>*/}
          {/*    </div>*/}
          {/*  </CardContent>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>



  </>
}

export default withErrorBoundary(
  AddBankList,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });

