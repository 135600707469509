import {FormattedMessage, useIntl} from "react-intl";
import {Button, Card, Chip} from "@mui/material";
import Menu from "../../common/components/Menu";
import React, {useEffect, useState} from "react";
import {withErrorBoundary} from "react-error-boundary";
import {differenceInDaysBetweenDates} from "../../common/utils/utils";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import {useDispatch, useSelector} from "react-redux";
import {banksSelector, deleteBank, disableBank, getLinkableBanksForCountry, getLinkNordigenBank} from "./BanksSlice";

const BankCard = ({bank, key}: {bank: any, key: any}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const fileSelectedHandler = (e: any) => {
    console.log(e)
  }

  const handleDisableBank = () => {
    dispatch(disableBank(bank._id) as any)
  }

  const handleDeleteBank = () => {
    dispatch(deleteBank(bank._id) as any)
  }

  const {badge, cardText, cardFooter} = cardDisplayData(bank, fileSelectedHandler, intl, handleDeleteBank)


  return (
  <div key={key} className="w-[330px] px-6 py-8 mx-4 my-6 border rounded-lg text-left">
    <div className="flex justify-between mb-6">
      <Chip  sx={{borderRadius: '8px'}} label={intl.formatMessage({id: badge.text})} color={badge.color as any}/>

      <Menu sx={{horizontal: true}}>
        <div>
          {/*<div><FormattedMessage id='reconnect'/></div>*/}
          {/*<div><FormattedMessage id='upload_statement'/></div>*/}
          <div>
            <ConfirmationDialog
              type='text'
              buttonText={intl.formatMessage({id: 'delete'})}
              buttonColor="red"
              title='Are you sure you want to delete'
              text='delete'
              confirmText='Yes'
              declineText='No'
              onConfirm={handleDisableBank}
            />
          </div>
        </div>
      </Menu>
    </div>
    <div>

      <p className="font-semibold text-base font-LaNord text-[#252733]">{bank.name}</p>

      <p className="pt-2">
        <FormattedMessage id='bank_account_configured_pre'/> {cardText}
      </p>
      <div className="font-bold text-[#253292] text-sm pt-4">{cardFooter}</div>
    </div>

  </div>
  )}

const cardDisplayData = (bank: any, fileSelectedHandler: any, intl: any, handleDeleteBank: any) => {
  const now = new Date()
  const inSevenDays = new Date()

  inSevenDays.setDate(inSevenDays.getDate() + 7)
  const needsReconnectionNow = bank.hasOwnProperty('api_enabled_until') &&
    new Date(bank.api_enabled_until).getTime() < now.getTime()

  const needsReconnectionSoon = !needsReconnectionNow && bank.hasOwnProperty('api_enabled_until') &&
    new Date(bank.api_enabled_until).getTime() < inSevenDays.getTime()

  let badge
  switch (true) {
    case !!(bank.active && bank.api_key && needsReconnectionNow):
      badge = {color: 'error', text: 'EXPIRED'}
      break;
    case !!(bank.active && bank.api_key && needsReconnectionSoon):
      badge = {color: 'warning', text: 'EXPIRES_SOON'}
      break;
    case !!(bank.active && bank.api_key):
      badge = {color: 'success', text: 'LINKED'}
      break;
    case !!(bank.active):
      badge = {color: '', text: 'MANUAL'}
      break;
    default:
      badge = {color: 'error', text: 'DELETED'}
  }

  let cardText
  switch (true) {
    case !!(bank.api_enabled && bank.active && bank.api_key && needsReconnectionNow):
      cardText = <><FormattedMessage id='bank_account_configured_post'/> <FormattedMessage id='_and_linked'/>
        <FormattedMessage id='expired'/></>
      break;
    case !!(bank.api_enabled && bank.active && bank.api_key):
      const days = Math.floor(differenceInDaysBetweenDates(now, bank.api_enabled_until))
      cardText = <><FormattedMessage id='bank_account_configured_post'/> <FormattedMessage id='_and_linked'/>
        <FormattedMessage
          id='authorization_expires'
          values={{
            span: <span>{days} d</span>
          }}
        >
          {chunks => <>{chunks[0]} {React.cloneElement(chunks[1] as any)}</>}
        </FormattedMessage>
      </>
      break;
    case !!(bank.active):
      cardText = <FormattedMessage id='_but_not_linked' />
      break;
    default:
      cardText = <FormattedMessage id='bank_account_deleted' />
  }

  let cardFooter
  switch (true) {
    case !!(needsReconnectionSoon || needsReconnectionNow):
      cardFooter = <div className="cursor-pointer"><FormattedMessage id='reconnect'/></div>
      break;
    case  !!(bank.active):
      cardFooter = <>
        <label htmlFor={"statement_input_" + bank._id}>
          <input
            style={{display: "none"}}
            onChange={fileSelectedHandler}
            accept=".pdf,.xls,.xlsx,.csv,.txt"
            id={"statement_input_" + bank._id}
            multiple type="file"
          />
          <a className="text-[#253292] cursor-pointer">
            <FormattedMessage id="upload_statement"/>
          </a>
        </label>
      </>
      break;
    default:
      cardFooter = <div className="text-red-600 mt-5 cursor-pointer">
        <ConfirmationDialog
          type='text'
          buttonText={intl.formatMessage({id: 'delete_permanently'})}
          buttonColor="red"
          title='Are you sure you want to delete'
          text='delete'
          confirmText="Yes"
          declineText="No"
          onConfirm={handleDeleteBank}
        /></div>
  }
  return {badge, cardText, cardFooter}
}

export default withErrorBoundary(
  BankCard,
  {
    FallbackComponent: () => <div></div>, // (Failed to load)we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });