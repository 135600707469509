import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

export const getNotifications: any = createAsyncThunk(
  'notifications/getNotifications',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/notifications/',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const markNotificationAsRead: any = createAsyncThunk<any, any, any>(
  'notifications/markAsRead',
  async (id, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/notifications/'+id+'/read',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const markAllNotificationsAsRead: any = createAsyncThunk(
  'notifications/markAllAsRead',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/notifications/read',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [getNotifications.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getNotifications.pending]: (state) => {
      state.isFetching = true;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.notifications = payload.notifications
    },
    [markNotificationAsRead.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [markNotificationAsRead.pending]: (state) => {
      state.isFetching = true;
    },
    [markNotificationAsRead.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.notifications = state.notifications.filter((n: any) => n._id !== payload.notification._id)
    },
    [markAllNotificationsAsRead.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [markAllNotificationsAsRead.pending]: (state) => {
      state.isFetching = true;
    },
    [markAllNotificationsAsRead.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.notifications = payload.notifications
    },
  },
});

export const { clearState } = notificationsSlice.actions;

export const notificationsSelector = (state: any) => state.notifications;
