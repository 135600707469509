import {useEffect, useState} from "react";
import { IntlProvider } from "react-intl";
import * as React from "react";

// @ts-ignore
import messages_es from "../../translations/es.json";
// @ts-ignore
import messages_en from "../../translations/en.json";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";

type IntlProviderWrapperProps = {
  id?: string;
  children: React.ReactComponentElement<any>;
};

const messages: any = {
  'es': messages_es,
  'en': messages_en
};

const IntlProviderWrapper = ({children}: IntlProviderWrapperProps) => {
  const [locale, setLocale]: React.ComponentState = useState("es")
  const {user} = useSelector(userSelector)

  if (user) {
    const userLocale = user.locale.split("_")[0]
    if(userLocale !== locale){
      setLocale(userLocale)
    }
  }

  return <IntlProvider
      key={locale}
      locale={locale}
      messages={messages[locale]}
      defaultLocale="en"
    >
      {children}
    </IntlProvider>
}

export default IntlProviderWrapper