import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

export const getBusinessTravelsPaginated: any = createAsyncThunk<any, any, any>(
  'travels/getBusinessTravelsPaginated',
  async ({ page, limit, filters }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/travels/paginated?page=' + page +
        '&limit=' + limit +
        '&filters=' + encodeURIComponent(JSON.stringify(filters)),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addTravel: any = createAsyncThunk<any, any, any>(
  "travels/addTravel",
  async (travelData , thunkAPI) => {
    if(travelData.board_member === '') {
      delete travelData.board_member
    }
    try {
      const response: any = await fetch(
        process.env.REACT_APP_API_URL+'/travels',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(travelData)
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const travelsSlice = createSlice({
  name: "travels",
  initialState: {
    travelsData: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [getBusinessTravelsPaginated.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getBusinessTravelsPaginated.pending]: (state) => {
      state.isFetching = true;
    },
    [getBusinessTravelsPaginated.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.travelsData = payload
    },
    [addTravel.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [addTravel.pending]: (state) => {
      state.isFetching = true;
    },
    [addTravel.fulfilled]: (state, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = payload.message;
    },
  },
});

export const { clearState } = travelsSlice.actions;

export const travelsSelector = (state: any) => state.travels;
