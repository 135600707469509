import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {FormattedMessage} from "react-intl";
import {withErrorBoundary} from "react-error-boundary";

interface typeOfProps {
    onClick: any,
    className: string
}
const BackButton = ({onClick, className}: typeOfProps) => {
  return <div onClick={onClick} className={"cursor-pointer " + className}><ArrowBackIcon/> <FormattedMessage id="back" /></div>
}

export default withErrorBoundary(
  BackButton,
  {
    FallbackComponent: () => <div>Button failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })