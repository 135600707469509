import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {withErrorBoundary} from "react-error-boundary";
import CompanioButton from "./CompanioButton";
import Slide from "@mui/material/Slide";

type InputModalT = {
  id?: string;
  open: boolean;
  onClose: any;
  onChange: any;
  onConfirm: any;
  title: string;
  subtitle: string;
  type?: string;
  value: string;
  children?: any;
}

const InputModal = ({
  id,
  open,
  onClose,
  onChange,
  onConfirm,
  title,
  subtitle,
  type,
  value,
  children
}: InputModalT) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        // @ts-ignore
        TransitionComponent={Transition}
      >
        <p className="m-4 ml-6 laNord text-2xl font-bold">{title}</p>
        <DialogContent className="border border-x-0 border-t-0">
          <p className="text-[#9FA2B4] laNord text-lg mb-4">
            {subtitle}
          </p>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
            }}
            className="w-2/3 md:w-[400px]"
          >
            <div
              className={`flex flex-col items-start justify-center`}
              data-testid="registration_text_input_wrapper">
              <input
                className={`border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full text-[#9FA2B4]`}
                data-testid="text_input"
                type={type || "text"}
                id={id}
                value={value}
                onChange={onChange}
              >
                {children}
              </input>
            </div>
          </Box>
        </DialogContent>
        <DialogActions sx={{my: "8px"}}>
          <CompanioButton size="large" variant="outlined" onClick={onClose}>Close</CompanioButton>
          <CompanioButton size="large" onClick={onConfirm}>Confirm</CompanioButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props}/>;
});

export default withErrorBoundary(
  InputModal,
  {
    FallbackComponent: () => <div>Menu failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })
