import React, { CSSProperties } from 'react';

function DefaultSelect({
  id,
  value,
  onChange,
  label,
  style,
  className,
  children
}: {
  id: string;
  value: string;
  onChange: any;
  label: string;
  style?: CSSProperties;
  className?: string;
  children: any;
}) {
  return (
    <div
      data-testid="select-user-input"
      className={`d-flex flex-col items-start justify-center ${className}`}
      style={style}>
      {label && (
        <p
          className="text-start uppercase mb-2"
          style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
          {label}
        </p>
      )}
        <select
          id={id}
          name={id}
          value={value}
          className="border rounded-lg p-3 mb-4 w-full text-sm text-[#9FA2B4] laNord"
          data-testid="select"
          onChange={(e: any) => onChange(e)}>
          {children}
        </select>
    </div>
  );
}

export default DefaultSelect;
