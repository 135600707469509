import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import * as React from "react";
import {withErrorBoundary} from "react-error-boundary";

type MonthYearFilterProps = {
  id?: string;
  monthYear: React.ComponentState;
  setMonthYear: React.ComponentState;
}

const MonthYearFilter = ({monthYear, setMonthYear}: MonthYearFilterProps) => {
  const date: Date = new Date(monthYear.year, monthYear.month - 1, 1)
  const monthToDisplay: string = date.toLocaleString('es',  {month: "short"})

  const isCurrentMonth =
    (date.getMonth() === new Date().getMonth()) &&
    (date.getFullYear() === new Date().getFullYear())

  const increaseMonth = () => {
    if (monthYear.month === 12) {
      setMonthYear({
        month: 1,
        year: Number(monthYear.year) + 1
      })
      saveToStorage({month: 1, year: Number(monthYear.year) + 1})
      return
    }

    setMonthYear({
      month: Number(monthYear.month) + 1,
      year: monthYear.year
    })
    saveToStorage({month: Number(monthYear.month) +1, year: monthYear.year})

  }
  const decreaseMonth = () => {
    if (monthYear.month === 1) {
      setMonthYear({
        month: 12,
        year: Number(monthYear.year) - 1
      })
      saveToStorage({month:12, year: Number(monthYear.year) - 1})
      return
    }

    setMonthYear({
      month: Number(monthYear.month) - 1,
      year: monthYear.year
    })
    saveToStorage({month: Number(monthYear.month) - 1, year: monthYear.year})

  }

  return <button className="flex bg-[#F7F8FC] px-2 rounded-lg items-center h-[40px] ml-4">
    <ArrowBackIcon onClick={decreaseMonth} sx={{color: '#4B506D'}}/>
    <p className="capitalize m-0 text-[#4B506D] text-sm font-LaNord font-semibold">{monthToDisplay} {monthYear.year}</p>
    <ArrowForwardIcon className={isCurrentMonth ? 'pointer-events-none opacity-50' : 'pointer-events-auto'} onClick={increaseMonth} sx={{color: '#4B506D'}}/>
  </button>
}

const saveToStorage = ({month, year}: {month: number, year: number}) => {
  localStorage.setItem('teee_selected_year', year.toString())
  localStorage.setItem('teee_selected_month', month.toString())
}

export default withErrorBoundary(
  MonthYearFilter,
  {
    FallbackComponent: () => <div>Filter failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })
