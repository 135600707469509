
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js";
import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {Button, CircularProgress} from "@mui/material";
import {FormattedMessage} from "react-intl";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || "");

const RegistrationPayment = () => {
  const {data} = useParams()

  let decodedData: any = decodeURI(data || "")

  try {
    decodedData = JSON.parse(decodedData)
  } catch (e) {
  }

  return <div className="whitespace-pre text-left">
    Reg payment

    <Elements stripe={stripePromise} options={{clientSecret: decodedData.client_secret}}>
      <CheckoutForm/>
    </Elements>
  </div>
}

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate()
  const {data} = useParams()
  const [isFetching, setIsFetching] = useState(false)

  let decodedData: any = decodeURI(data || "")

  try {
    decodedData = JSON.parse(decodedData)
  } catch (e) {
  }
  const isSetupIntent = decodedData.client_secret.substring(0, 4) === 'seti'

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsFetching(true)

    if (!stripe || !elements) {
      return setIsFetching(false);
    }

    const result = isSetupIntent ?
      await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      }) :
      await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      })


    if (result.error) {
      if (result.error.message) {
        toast(result.error.message);
      }
      return setIsFetching(false);
    }

    const secretSplit = decodedData.client_secret.split("_secret_")
    const setupIntent = secretSplit[0]

    const verificationData = isSetupIntent ?
      await verifySetupIntent(setupIntent, navigate) :
      await verifyPaymentIntent(setupIntent, navigate)

    console.log(verificationData)
    return setIsFetching(false);
  };

  return (
    <form onSubmit={handleSubmit} className="mt-10">
      <PaymentElement/>
      <Button disabled={!stripe || isFetching} variant="contained" type="submit" sx={{mt: 4}}>
        {isFetching ? <CircularProgress/> : <FormattedMessage id="submit"/>}
      </Button>
    </form>
  )
};

const verifySetupIntent = async (intentId: any, navigate: any) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + '/payments/verify_registration_setup_intent/'+intentId,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    let data = await response.json();

    if (response.status === 200 && data.magicLink && data.status === 'succeeded') {
      window.location.href = data.magicLink
    }
    else if (response.status === 200 && (data.status === 'requires_action' || data.status === 'processing')) {
      navigate('/payment/status/'+intentId)
    } else {
      throw new Error('Error verifying setup')
    }
  } catch (e: any) {
    toast.error('Error verifying setup ' + e.message)
  }
}

const verifyPaymentIntent = async (intentId: any, navigate: any) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + '/payments/verify_registration_paid/'+intentId,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    let data = await response.json();

    if (response.status === 200 && data.magicLink && data.status === 'succeeded') {
      window.location.href = data.magicLink
    }
    else if (response.status === 200 && (data.status === 'requires_action' || data.status === 'processing')) {
      navigate('/register/payment/status/'+intentId)
    } else {
      throw new Error('Error verifying setup')
    }
  } catch (e: any) {
    toast.error('Error verifying setup ' + e.message)
  }
}

export default RegistrationPayment
