import {withErrorBoundary} from "react-error-boundary";
import {CardContent, Typography, Card, Avatar, Container, Box, Button} from "@mui/material";
import Menu from "../../common/components/Menu";
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {clearState, userSelector, deleteUser, getCompanyUsers} from '../User/UserSlice';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from "react-intl";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

const Users = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {isFetching, isSuccess, isError, errorMessage, company_users} = useSelector(userSelector)

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getCompanyUsers() as any);
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage)
      dispatch(clearState())
    }
  }, [dispatch, isError, errorMessage])

  if (!company_users) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  const deleteUserById = (id: any) => {
    dispatch(deleteUser(id) as any)
  }

  return <>
    <div className="inline-flex">
    {company_users.map((user: any) =>{
      return <Card key={user._id} className="inline-flex rounded m-4" sx={{width: '210px', height:'240px', padding:'10px', borderRadius:'5px'}}>
          <CardContent className="w-full">
            <div className="flex justify-between">
              <Avatar sx={{width: 44, height: 44}}
                      src={process.env.REACT_APP_API_URL + '/' + user.avatar}></Avatar>
              <Menu sx={{}}>
                  <div onClick={() => navigate('/settings/add_user/' + user._id)}><FormattedMessage id="edit_user"/></div>
                  <div className="text-red-600" style={{color: 'red'}} onClick={() => deleteUserById(user._id)} ><FormattedMessage id="delete"/></div>
              </Menu>
            </div>
            <Typography sx={{fontSize: 16, paddingTop: '20px',}} color="#212629" gutterBottom align="left" className="mb-5 break-words">
              {user.first_name + ' ' + user.last_name}
            </Typography>
            <Typography sx={{fontSize: 10}} color="#6C757D" gutterBottom align="left" className="break-words">
              {user.email}
            </Typography>
            <Typography sx={{fontSize: 14, paddingTop: '14px'}} color="#007BFF" gutterBottom align="left" className="mr-4">
              {user.role}
            </Typography>
            <Typography sx={{fontSize: 14, paddingTop: '8px'}} color="#00DA8B" gutterBottom align="left" className="mr-4">
              <FormattedMessage id="active"/>
            </Typography>
          </CardContent>
        </Card>
    })}
    </div>
    <footer className="h-auto bg-blue-800 mt-40 rounded-md text-slate-50">
      <h2 className="text-xl pt-2"><FormattedMessage id="add_user"/></h2>
      <div className="flex">
        <div className="flex w-1/6 h-28">
          <img src="/assets/images/add-employees.png"/>
        </div>
        <div className="flex w-4/6">
          <p className="p-3 text-left"><FormattedMessage id="add_users_text"/></p>
        </div>
        <div className="flex w-1/6 h-1/6 mt-10 mb-3">
          <Button
            disabled={isFetching}
            onClick={() => navigate('/settings/add_user/')}
            variant="contained" sx={{mr: 3, mt: 2, mb:5, bgcolor:'#fff', color:'#1E40AF'}}><FormattedMessage id="add_user"/></Button>
        </div>
      </div>
    </footer>
  </>
};

export default withErrorBoundary(
  Users,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });