import {withErrorBoundary} from "react-error-boundary";
import {FormattedMessage, useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {clearState} from "../Sales/SalesSlice";
import toast from "react-hot-toast";
import {banksSelector, getLinkableBanksForCountry, getLinkNordigenBank} from "./BanksSlice";
import {Box, Card, CardContent, TextField, Autocomplete} from "@mui/material";
import CompanioButton from "../../common/components/CompanioButton";

const AddLocalBank = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {isError, errorMessage, linkableBanksForCountry} = useSelector(banksSelector)

  const [selectedCountry, setSelectedCountry] = useState('es')

  const [selectedBank, setSelectedBank] = useState<any>()

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);

  useEffect(() => {
    dispatch(getLinkableBanksForCountry(selectedCountry) as any)
  }, [dispatch, selectedCountry]);

  const linkToNordigenBank = () => {
    dispatch(getLinkNordigenBank(selectedBank.id || "") as any)
      .then((e: any) => {
        window.location.href = e.payload.link
      })
  }

  return <div>
    <div className="w-full">

      <SearchAndSelectCountry setSelectedCountry={setSelectedCountry} />

      {linkableBanksForCountry && <SearchAndSelectBank banks={linkableBanksForCountry} setSelectedBank={setSelectedBank} />}

        <div className="flex items-center pt-8 mt-8 justify-end border-t">
            <CompanioButton size="small" variant="contained" className="h-[40px]" disabled={!(selectedBank && linkableBanksForCountry)} onClick={() => linkToNordigenBank()}><FormattedMessage id='link' /></CompanioButton>
        </div>
      {/*{selectedBank && <p onClick={() => linkToNordigenBank()} className="cursor-pointer text-sm font-LaNord font-semibold text-[#253292] pt-6"><FormattedMessage id='link' /></p>}*/}
    </div>
  </div>
};

export default withErrorBoundary(
  AddLocalBank,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });

const SearchAndSelectCountry = ({setSelectedCountry}: {setSelectedCountry: any}) => {
  return <Autocomplete
    id="country-select"
    sx={{mt:2}}
    options={apiEnabledCountries}
    autoHighlight
    defaultValue={apiEnabledCountries[28]}
    onChange={(event: any, newValue: any) => {
      setSelectedCountry(newValue.code);
    }}
    getOptionLabel={(option) => option.name}
    renderOption={(props, option) => (
      <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props} key={option.code} defaultValue={option.code}>
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${option.code}.png`}
          srcSet={`https://flagcdn.com/w40/${option.code}.png 2x`}
          alt=""
        />
        {option.name}
      </Box>
    )}
    renderInput={(params) => (
      <div>
        <div className="text-xs font-LaNord font-bold text-[#9FA2B4] pb-2">Choose a country</div>
        <TextField
            {...params}
            // label="Choose a country"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
        />
      </div>

    )}
  />
}

const SearchAndSelectBank = ({banks, setSelectedBank}: {banks: any, setSelectedBank: any}) => {
  return <Autocomplete
    id="bankSelcet"
    sx={{mt:2}}
    options={banks}
    autoHighlight
    onChange={(event, newValue) => {
      setSelectedBank(newValue);
    }}
    getOptionLabel={(option: any) => option.name}
    renderOption={(props, option) => (
      <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props} key={option.id} defaultValue={option.id}>
        {option.logo && <img
          loading="lazy"
          width="20"
          src={option.logo}
          alt=""
        />}
        {option.name}
      </Box>
    )}
    renderInput={(params) => (
        <div>
          <div className="text-xs font-LaNord font-bold text-[#9FA2B4] pb-2">Choose a bank</div>
          <TextField
              {...params}
              // label="Choose a bank"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
          />
        </div>

    )}
  />
}

const apiEnabledCountries = [
  {code: 'at', name: 'Austria'},
  {code: 'be', name: 'Belgium'},
  {code: 'bg', name: 'Bulgaria'},
  {code: 'hr', name: 'Croatia'},
  {code: 'cy', name: 'Cyprus'},
  {code: 'cz', name: 'Czech Republic'},
  {code: 'dk', name: 'Denmark'},
  {code: 'ee', name: 'Estonia'},
  {code: 'fi', name: 'Finland'},
  {code: 'fr', name: 'France'},
  {code: 'de', name: 'Germany'},
  {code: 'gr', name: 'Greece'},
  {code: 'hu', name: 'Hungary'},
  {code: 'is', name: 'Iceland'},
  {code: 'ie', name: 'Ireland'},
  {code: 'it', name: 'Italy'},
  {code: 'lv', name: 'Latvia'},
  {code: 'li', name: 'Liechtenstein'},
  {code: 'lt', name: 'Lithuania'},
  {code: 'lu', name: 'Luxembourg'},
  {code: 'mt', name: 'Malta'},
  {code: 'nl', name: 'Netherlands'},
  {code: 'no', name: 'Norway'},
  {code: 'pl', name: 'Poland'},
  {code: 'pt', name: 'Portugal'},
  {code: 'ro', name: 'Romania'},
  {code: 'sk', name: 'Slovakia'},
  {code: 'si', name: 'Slovenia'},
  {code: 'es', name: 'Spain (España)'},
  {code: 'se', name: 'Sweden'},
  {code: 'gb', name: 'United Kingdom'}
]