import {withErrorBoundary} from "react-error-boundary";
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import {FormattedMessage} from "react-intl";
import React from "react";
import {Button} from "@mui/material";

const getRoutePath = (location: any, params: any) => {
  const {pathname} = location;

  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const currentPath = getRoutePath(location, params);

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="settings"/></p>
      <div></div>
      <LoggedInUserMenu/>
    </div>

    <div className="flex">
      <Button
        onClick={() => navigate('/settings')}
        variant={
          isActiveRoute(['/settings', '/settings/'], currentPath) ?
            'contained' :
            'outlined'
        }
        sx={{
          color: isActiveRoute(['/settings', '/settings/'], currentPath) ? "#FFF" : "#9FA2B4",
          borderRadius: "8px",
          height: "32px",
          backgroundColor: isActiveRoute(['/settings', '/settings/'], currentPath) ? "#253292" : "#FFFFFF",
          borderColor: isActiveRoute(['/settings', '/settings/'], currentPath) ? "#253292" : "#9FA2B4"}}
      >
        <FormattedMessage id="my_profile"/>
      </Button>

      <Button
        onClick={() => navigate('/settings/notifications')}
        variant={
          isActiveRoute(['/settings/notifications', '/settings/notifications/'], currentPath) ?
            'contained' :
            'outlined'
        }
        sx={{
          color: isActiveRoute(['/settings/notifications', '/settings/notifications/'], currentPath) ? "#FFF" : "#9FA2B4",
          borderRadius: "8px",
          marginLeft: "16px",
          height: "32px",
          backgroundColor: isActiveRoute(['/settings/notifications', '/settings/notifications/'], currentPath) ? "#253292" : "#FFFFFF",
          borderColor: isActiveRoute(['/settings/notifications', '/settings/notifications/'], currentPath) ? "#253292" : "#9FA2B4"}}>
        <FormattedMessage id="notifications"/>
      </Button>

      <Button
        onClick={() => navigate('/settings/email_automation')}
        variant={
          isActiveRoute(['/settings/email_automation', '/settings/email_automation/'], currentPath) ?
            'contained' :
            'outlined'
        }
        sx={{
          color: isActiveRoute(['/settings/email_automation', '/settings/email_automation/'], currentPath) ? "#FFF" : "#9FA2B4",
          borderRadius: "8px",
          marginLeft: "16px",
          height: "32px",
          backgroundColor: isActiveRoute(['/settings/email_automation', '/settings/email_automation/'], currentPath) ? "#253292" : "#FFFFFF",
          borderColor: isActiveRoute(['/settings/email_automation', '/settings/email_automation/'], currentPath) ? "#253292" : "#9FA2B4"}}>
        <FormattedMessage id="email_automation"/>
      </Button>

      <Button
        onClick={() => navigate('/settings/security')}
        variant={
          isActiveRoute(['/settings/security', '/settings/security/'], currentPath) ?
            'contained' :
            'outlined'
        }
        sx={{
          color: isActiveRoute(['/settings/security', '/settings/security/'], currentPath) ? "#FFF" : "#9FA2B4",
          borderRadius: "8px",
          marginLeft: "16px",
          height: "32px",
          backgroundColor: isActiveRoute(['/settings/security', '/settings/security/'], currentPath) ? "#253292" : "#FFFFFF",
          borderColor: isActiveRoute(['/settings/security', '/settings/security/'], currentPath) ? "#253292" : "#9FA2B4"}}>
        <FormattedMessage id="security"/>
      </Button>

      <Button
        onClick={() => navigate('/settings/users/')}
        variant={
          isActiveRoute(['/settings/users/', '/settings/add_user/','/settings/users', '/settings/add_user', '/settings/add_user/:selected_user_id'], currentPath) ?
            'contained' :
            'outlined'
        }
        sx={{
          color: isActiveRoute(['/settings/users/', '/settings/add_user/','/settings/users', '/settings/add_user', '/settings/add_user/:selected_user_id'], currentPath) ? "#FFF" : "#9FA2B4",
          borderRadius: "8px",
          marginLeft: "16px",
          height: "32px",
          backgroundColor: isActiveRoute(['/settings/users/', '/settings/add_user/','/settings/users', '/settings/add_user', '/settings/add_user/:selected_user_id'], currentPath) ? "#253292" : "#FFFFFF",
          borderColor: isActiveRoute(['/settings/users/', '/settings/add_user/','/settings/users', '/settings/add_user', '/settings/add_user/:selected_user_id'], currentPath) ? "#253292" : "#9FA2B4"}}>
        <FormattedMessage id="users"/>
      </Button>

      <Button
        onClick={() => navigate('/settings/other')}
        variant={
          isActiveRoute(['/settings/other', '/settings/other/'], currentPath) ?
            'contained' :
            'outlined'
        }
        sx={{
          color: isActiveRoute(['/settings/other', '/settings/other/'], currentPath) ? "#FFF" : "#9FA2B4",
          borderRadius: "8px",
          marginLeft: "16px",
          height: "32px",
          backgroundColor: isActiveRoute(['/settings/other', '/settings/other/'], currentPath) ? "#253292" : "#FFFFFF",
          borderColor: isActiveRoute(['/settings/other', '/settings/other/'], currentPath) ? "#253292" : "#9FA2B4"}}>
        <FormattedMessage id="other"/>
      </Button>
    </div>
    <Outlet/>
  </div>
};

const isActiveRoute = (routes: any, path: any) => {
  return routes.find((r: any) => r === path)
}

export default withErrorBoundary(
  Settings,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });