import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {withErrorBoundary} from "react-error-boundary";
import CompanioButton from "./CompanioButton";
import Slide from "@mui/material/Slide";

const SelectModal = (props: any) => {

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        // @ts-ignore
        TransitionComponent={Transition}
      >
        <p className="m-4 ml-6 laNord text-2xl font-bold">{props.title}</p>
        <DialogContent className="border border-x-0 border-t-0">
          <p className="text-[#9FA2B4] laNord text-lg mb-4">
            {props.subTitle}
          </p>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
            }}
            className="w-2/3 md:w-[400px]"
          >
            <div
              data-testid="select-user-input"
              className={`flex flex-col items-start justify-center`}>
              <select
                id={props.id}
                name={props.id}
                value={props.value}
                className="border rounded-lg p-3 mb-4 w-full text-sm text-[#9FA2B4] laNord"
                data-testid="select"
                onChange={(e: any) => props.onChange(e)}>
                {props.children}
              </select>
            </div>
          </Box>
        </DialogContent>
        <DialogActions sx={{my: "8px"}}>
          <CompanioButton size="large" variant="outlined" onClick={props.onClose}>Close</CompanioButton>
          <CompanioButton size="large" onClick={props.onConfirm}>Confirm</CompanioButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props}/>;
});

export default withErrorBoundary(
  SelectModal,
  {
    FallbackComponent: () => <div>Menu failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })
