import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {userSelector, clearState, getMe} from './UserSlice';
import toast from 'react-hot-toast';
import {useNavigate, useParams} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import {Container} from '@mui/material';
import {withErrorBoundary} from 'react-error-boundary';

const LoginConfirm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { user, isError, errorMessage } = useSelector(
    userSelector
  );

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);


  useEffect(() => {
    dispatch(getMe() as any);
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);

  if(user){
    params['*'] ? navigate('/'+params['*']) : navigate('/')
  }

  return (
    <Container maxWidth="xs">
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </Container>
  );
};

export default withErrorBoundary(
  LoginConfirm,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })
