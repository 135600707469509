import React, {useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  clearState, deleteDocument,
  getPurchases, moveDocumentToMonth,
  purchasesSelector,
  updateDocOutOfPocket,
  updateDocumentWithData,
  uploadDocument
} from './PurchasesSlice';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {
  Container,
  Box,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import BadgeForDocumentStatus from "../../common/components/BadgeForDocuments";
import Pagination from "../../common/components/Pagination";
import MonthYearFilter from "../../common/components/MonthYearFilter";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import CompanioButton from "../../common/components/CompanioButton"
import {FilterMenu} from "../../common/components/FilterMenu";
import {DropArea} from "../../common/components/DropArea";
import {withErrorBoundary} from "react-error-boundary";
import SearchFilter from "../../common/components/SearchFilter";
import {
  downloadDocumentById,
  prefixFromDocName,
  readFileAsBase64,
  removeDocumentTypePrefix,
  replacePrefixFromDocName
} from "../../common/utils/utils";
import Menu from "../../common/components/Menu";
import Switcher from "../../common/components/Switcher";
import SelectModal from "../../common/components/SelectModal";
import InputModal from "../../common/components/InputModal";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const Purchases = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    status: ['processed', 'processing', 'uploaded'],
    matching: ['matched', 'unmatched'],
    search: ''
  });
  const [monthYear, setMonthYear]: any = useState({
    month: localStorage.getItem('teee_selected_month') || new Date().getMonth() + 1,
    year: localStorage.getItem('teee_selected_year') || new Date().getFullYear()
  })
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [selectModal, setSelectModal] = useState(selectModalInitialState)
  const [textModal, setTextModal] = useState(textModalInitialState)

  const dropAreaRef: React.Ref<any> = useRef(null)

  const {isSuccess, isError, errorMessage, purchasesData} = useSelector(purchasesSelector)

  const intl = useIntl();
  const open = Boolean(filtersAnchorEl);

  useEffect(()=>{
    console.log('PurchasesData------>>>>', purchasesData)
  },[purchasesData])

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);


  useEffect(() => {
    dispatch(getPurchases({
      page: page,
      limit: limit,
      year: monthYear.year,
      month: monthYear.month,
      filters: filters
    }) as any);
  }, [dispatch,limit, page, filters, monthYear, isSuccess]);

  const now = new Date()
  const daNextMonth = now.getMonth() + 1
  const prevMonthDate = new Date()
  prevMonthDate.setDate(1)
  prevMonthDate.setMonth(prevMonthDate.getMonth() - 1)

  const validateWhichMonthChange = (docId: string, status: string) => {
    if(parseInt(monthYear.month) === daNextMonth) {
      return <div style={{color: "#9FA2B4"}} onClick={() => handleChangeDocumentMonth(docId, -1, status)}>
        {`Move to ${formatMonth(prevMonthDate)}`}
      </div>
    }
    else {
      if ((parseInt(monthYear.month) + 1) === daNextMonth) {
          return <div style={{color: "#9FA2B4"}} onClick={() => handleChangeDocumentMonth(docId, 0, status)}>
            {`Move to ${formatMonth(now)}`}
          </div>
      }
    }
  }


  const handleClick = (event: any) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setFiltersAnchorEl(null);
  };

  const handleDrag = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if(event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false)
    }
  }

  const handleDrop = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false)
    if(event.dataTransfer.files && event.dataTransfer.files[0]){
      const files: any[] = event.dataTransfer.files
      for (const file of files) {
        const filedata = await readFileAsBase64(file)
        dispatch(uploadDocument({
          type: "purchase_invoice",
          filename: file.name,
          filedata: filedata
        }))
      }
    }
  }

  const handleDropAreaChange = async (event: any) => {
    event.preventDefault();
    if(event.target.files && event.target.files[0]) {
      const files: any[] = event.target.files
      for (const file of files) {
        const filedata = await readFileAsBase64(file)
        dispatch(uploadDocument({
          type: "purchase_invoice",
          filename: file.name,
          filedata: filedata
        }))
      }
    }
  }

  const handleFilterChange = (group: any, value: any) => {
    // @ts-ignore
    const isActive = filters[group].includes(value)
    // @ts-ignore
    const updatedFiltersGroup = isActive ? filters[group].filter((i: any) => i !== value) : filters[group].concat(value)
    const updatedFilters = {
      ...filters,
      [group]: updatedFiltersGroup
    }
    setFilters(updatedFilters)
  }

  const handleUploadButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if(dropAreaRef.current) {
      dropAreaRef.current.click()
    }
  }

  const formatTransactionsDate = (date: string) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const day = new Date(date).getDate()
    const month = new Date(date).getMonth()
    const year = new Date(date).getFullYear()

    return `${months[month]} ${day}, ${year}`
  }
  const formatMonth = (date: any) => {
    const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"]
    const month = new Date(date).getMonth()
    return `${intl.formatMessage({id:months[month]})}`
  }

  const handleOutOfPocketChange = (event: any, docId: string) => {
    dispatch(updateDocOutOfPocket({docId, checked: event.target.checked}))
  }

  const handleOpenSelectModal = (title: string, subTitle: string, value: string, docId: string, docName: string) => {
    setSelectModal({...selectModal,
      title,
      subTitle,
      value,
      docId,
      docName,
      open: true
    })
  }
  const handleCloseSelectModal = () => {
    setSelectModal(selectModalInitialState)
  }

  const handleSelectModalChange = (e: any) => {
    setSelectModal({
      ...selectModal,
      value: e.target.value
    })
  }

  const handleConfirmSelection = () => {
    const data = {
      type: selectModal.value,
      title: replacePrefixFromDocName(selectModal.docName, 'purchase_invoice', selectModal.value)
    }
    dispatch(updateDocumentWithData({docId: selectModal.docId, newData: data}))
    setSelectModal(selectModalInitialState)
  }

  const handleOpenTextModal = (
    title: string,
    subTitle: string,
    value: string,
    docId: string,
    docName: string,
    action: string
  ) => {
    setTextModal({
      ...textModal,
      title,
      subTitle,
      value,
      docId,
      docName,
      action,
      open: true
    })
  }
  const handleTextModalChange = (e: any) => {
    setTextModal({
      ...textModal,
      value: e.target.value
    })
  }

  const handleCloseTextModal = () => {
    setTextModal(textModalInitialState)
  }

  const handleConfirmChange = () => {
    const data: any = {}
    if(textModal.action === "edit_name") {
      data.title = prefixFromDocName(textModal.value,"purchase_invoice") + textModal.value
    }
    if(textModal.action === "edit_description") {
      data.description = textModal.value
    }
    dispatch(updateDocumentWithData({docId: textModal.docId, newData: data}))
    setTextModal(textModalInitialState)
  }

  const handleChangeDocumentMonth = (docId: string, monthChange: number, status: string) => {
    if(status === 'uploaded'){
      dispatch(moveDocumentToMonth({docId, monthChange}))
    }
  }

  const checkStatusForDeletion = (docId: string, status: string) => {
    if(status === 'uploaded'){
      return <div style={{color: "#FE2142", borderTop: "1px solid #DFE0EB"}}>
        <ConfirmationDialog
          type='text'
          buttonText={intl.formatMessage({id: 'delete'})}
          buttonColor="red"
          title={intl.formatMessage({id: 'confirm_deletion'})}
          text={intl.formatMessage({id: 'confirm_document_deletion'})}
          confirmText={intl.formatMessage({id: 'yes'})}
          declineText={intl.formatMessage({id: 'no'})}
          onConfirm={() => handleDeleteDocument(docId)}
        />
      </div>
    }
  }

  const handleDeleteDocument = (docId: string) => {
    if(docId){
      dispatch(deleteDocument({docId}))
    }
  }

  const checkDocumentStatus = (status: string) => {
    if(status !== "uploaded"){
      return 'none'
    }
    return ''
  }

  if (!purchasesData) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="purchases"/></p>
      <LoggedInUserMenu />
    </div>
    <div className="border rounded-lg mb-6">
      <div className="flex justify-between items-center px-6 py-4">
        <p className="text-lg font-LaNord font-bold text-[#252733]">
          {intl.formatMessage({id: "purchase_invoices", defaultMessage:"Purchases Invoices"})}
        </p>
        <div className="flex justify-items-center items-center">
          <SearchFilter filters={filters} setFilters={setFilters} />
          {/*<CompanioButton size="small" variant="contained" className="w-32 h-[40px]" onClick={() => navigate('/sales/create-sales')}>Create</CompanioButton>*/}
          <MonthYearFilter monthYear={monthYear} setMonthYear={setMonthYear}/>
          <FilterMenu filters={filters}
                      className="mr-2-2"
                      intl={intl}
                      onClick={open ? () => {handleClose()} : (e: React.MouseEvent) => {handleClick(e)}}
                      open={open}
                      filtersAnchorEl={filtersAnchorEl}
                      handleChange={handleFilterChange}/>
        </div>

      </div>

      <TableContainer sx={{height: "350px"}}>
        <Table
          sx={{width: "100%"}}
          aria-labelledby="tableTitle"
          size='medium'
        >
          <TableHead className="p-4">
            <TableRow>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4] pl-4"><FormattedMessage id="document_name"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="date"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="matched"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="category"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="out_of_pocket"/></div></TableCell>
              <TableCell align="left" ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {purchasesData.docs.length ? purchasesData.docs.map((doc: any, i: any) => <TableRow
              // hover
              role="checkbox"
              tabIndex={-1}
              key={i}
            >
              <TableCell
                sx={{paddingLeft:4, paddingRight: 4}}
              >
                <div className="flex items-center">
                  <BadgeForDocumentStatus className="mr-4" status={doc.status}/>
                  <div>
                    <div className="text-sm text-left font-bold text-[#253292]">
                      {doc.name && removeDocumentTypePrefix(doc.name, doc.type)}
                    </div>
                    <div className="text-xs font-LaNord text-[#C5C7CD] pt-1">
                      <FormattedMessage id={doc.status} defaultMessage={doc.status}/>
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell align="left" ><div className="text-sm text-left font-bold text-[#253292]">{formatTransactionsDate(doc.created)}</div></TableCell>
              <TableCell align="left">
                {doc.matching && doc.matching.length ? <span className="bg-[#00DA8B] rounded-full text-xs font-LaNord font-bold text-white px-3 py-1"><FormattedMessage id="yes"/></span>
                  : <span className="bg-[#FE2142] rounded-full text-xs font-LaNord font-bold text-white px-3 py-1"><FormattedMessage id="yes"/></span>}
              </TableCell>
              <TableCell align="left">
                <div className="flex flex-col w-[135px] text-center">
                  <span className="bg-[#F0F1F7] rounded-lg text-xs font-LaNord font-bold text-[#9FA2B4] py-1 uppercase">{intl.formatMessage({id: doc.type})}</span>
                </div>
              </TableCell>
              <TableCell align="left">
                <div className="flex flex-col w-[135px] items-center">
                  <Switcher key={doc._id} onChange={(e: any) => handleOutOfPocketChange(e, doc._id)} checked={doc.out_of_pocket} />
                </div>
              </TableCell>
              <TableCell align="left">
                <Menu sx={{}}>
                  <div
                    style={{color: "#9FA2B4", display: checkDocumentStatus(doc.status)}}
                    onClick={() => handleOpenTextModal(intl.formatMessage({id:"edit_name"}), intl.formatMessage({id:"enter_name"}), removeDocumentTypePrefix(doc.name, doc.type), doc._id, doc.name, "edit_name")}
                  >
                    <FormattedMessage id="edit_name"/>
                  </div>
                  <div
                    style={{color: "#9FA2B4", display: checkDocumentStatus(doc.status)}}
                    onClick={() => handleOpenSelectModal(intl.formatMessage({id:"edit_type"}), intl.formatMessage({id:"select_type"}), doc.type, doc._id, doc.name)}
                  >
                    <FormattedMessage id="edit_type"/>
                  </div>
                  <div
                    style={{color: "#9FA2B4"}}
                    onClick={() => handleOpenTextModal(intl.formatMessage({id:"edit_description"}), intl.formatMessage({id:"enter_document_description"}), doc.description, doc._id, doc.name, "edit_description")}
                  >
                    <FormattedMessage id="edit_description"/>
                  </div>
                  <div
                    style={{color: "#9FA2B4"}}
                    onClick={() => downloadDocumentById(doc._id)}
                  >
                    <FormattedMessage id="download"/>
                  </div>
                  {doc.status === "uploaded" ? validateWhichMonthChange(doc._id, doc.status): null}
                  <div style={{color: "#253292", display: checkDocumentStatus(doc.status)}}>
                    <FormattedMessage id="link"/>
                  </div>
                  {checkStatusForDeletion(doc._id, doc.status)}
                </Menu>
              </TableCell>
            </TableRow>): <p>No invoices were found</p>}

          </TableBody>
        </Table>
      </TableContainer>
      <div>

        <Pagination className="mt-4"
                    requestPaginationData={purchasesData}
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setPage={setPage}
        />
      </div>
    </div>
    <div className="border rounded-lg max-h-[32rem] mb-8">
      <div className="flex justify-between items-center pt-8 px-8">
        <p className="text-left font-bold"><FormattedMessage id="upload_invoices"/></p>
        <CompanioButton size="small" variant="contained" className="w-32 h-[40px]" onClick={handleUploadButtonClick}>
          {intl.formatMessage({id: "upload", defaultMessage: "Upload"})}
        </CompanioButton>
      </div>
      <DropArea text="drag_purchase_invoices"
                dragActive={dragActive}
                handleDrop={handleDrop}
                handleDrag={handleDrag}
                handleChange={handleDropAreaChange}
                dropAreaRef={dropAreaRef}
      />
    </div>
    <SelectModal
      open={selectModal.open}
      onClose={handleCloseSelectModal}
      onConfirm={handleConfirmSelection}
      title={selectModal.title}
      subTitle={selectModal.subTitle}
      onChange={(e: any) => handleSelectModalChange(e)}
      value={selectModal.value}
    >
      <option value="purchase_invoice"><FormattedMessage id="purchase_invoice" /></option>
      <option value="sales_invoice"><FormattedMessage id="sales_invoice" /></option>
      <option value="bank_statement"><FormattedMessage id="bank_statement" /></option>
    </SelectModal>
    <InputModal
      open={textModal.open}
      onClose={handleCloseTextModal}
      onConfirm={handleConfirmChange}
      title={textModal.title}
      subtitle={textModal.subTitle}
      onChange={(e: any) => handleTextModalChange(e)}
      value={textModal.value}
    />
  </div>
};

const selectModalInitialState = {
  open: false,
  title: "",
  subTitle: "",
  docId: "",
  docName: "",
  value: "purchase_invoice"
}

const textModalInitialState = {
  open: false,
  title: "",
  subTitle: "",
  docId: "",
  docName: "",
  action: "",
  value: ""
}

export default withErrorBoundary(
  Purchases,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })
