import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import {store, persistor} from './app/store';
import {Provider} from 'react-redux';
import {Toaster} from 'react-hot-toast';
import {PersistGate} from "redux-persist/integration/react";

import {BrowserRouter} from "react-router-dom";
import IntlProviderWrapper from "./common/utils/intlProviderWrapper";

const appContainer = document.getElementById('root')

const root = createRoot(appContainer as any)

root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <IntlProviderWrapper>
            <App/>
          </IntlProviderWrapper>
        </BrowserRouter>
        <Toaster
          position='bottom-center'
          toastOptions={{
            duration: 7000,
          }}/>
      </PersistGate>
    </Provider>
  </>
);
