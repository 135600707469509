import {withErrorBoundary} from "react-error-boundary";
import React, { CSSProperties } from 'react';

type DefaultInputT = {
  id?: string;
  placeholder?: string;
  value: string;
  onChange: Function;
  style?: CSSProperties;
  className?: string;
  label?: string;
  required?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
};

function DefaultTextArea({
  id,
  placeholder,
  value,
  onChange,
  style,
  className,
  label,
  required,
  children,
  disabled
  }: DefaultInputT) {
  return (
    <div
      className={`flex flex-col items-start justify-center ${className}`}
      style={style}
      data-testid="registration_text_input_wrapper">
      {label && (
        <p
          className="text-start uppercase"
          style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
          {label}
        </p>
      )}
      <textarea
        disabled={disabled}
        className={`border rounded-lg bg-[#FCFDFE] h-[208px] max-h-[208px] mt-2 px-4 py-2 w-full text-[#9FA2B4] text-[12px]`}
        data-testid="textarea_input"
        required={required}
        placeholder={placeholder}
        id={id}
        autoComplete={id}
        value={value}
        onChange={(e) => onChange(e)}
      >
        {children}
      </textarea>
    </div>
  );
}

export default withErrorBoundary(DefaultTextArea, {
  fallback: <div>Failed to load text area component</div>
});
