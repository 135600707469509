import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie'
import {getNotifications} from "../Notifications/NotificationsSlice";
import {getBanks} from "../Banks/BanksSlice";

export const registerUser: any = createAsyncThunk<any, any, any>(
  'users/register',
  async (regData, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/auth/token',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...regData,
            type: "registration",
            locale: "es_ES",
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const verifyRegistrationToken: any = createAsyncThunk<any, any, any>(
  'users/verifyRegToken',
  async (token, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/auth/token/verify',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({token}),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const registerCompany: any = createAsyncThunk<any, any, any>(
  'users/register',
  async (regData, thunkAPI) => {
    const route = regData.is_switch ? '/switch' : '/signup'

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/auth' + route,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(regData),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUser: any = createAsyncThunk<any, any, any>(
  'users/login',
  async ({ email, userId, rememberMe }, thunkAPI) => {
    const uId = userId ? userId : ''
      console.log(uId, email)
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/auth/login/email',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            keep_me_logged_in: rememberMe,
            user_id: uId
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getMe: any = createAsyncThunk(
  'users/getMe',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/me',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || Cookies.get('tuempresaenestonia_admin') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getInitData = createAsyncThunk(
  'users/getInitial',
  async(_, thunkAPI) => {
    try{
      // populate bank accounts
      await thunkAPI.dispatch(getBanks());

      setInterval(() => {
        thunkAPI.dispatch(getNotifications());
      }, 10000)
    }
    catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
)
export const getCompanyUsers: any = createAsyncThunk(
  'users/getCompanyUsers',
  async(_, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/companies/users',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          }
        }
      )
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const addUser: any = createAsyncThunk<any, any, any>(
  'users/addUser',
  async (userData, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/companies/users/',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData)
        }
      )
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
)
export const updateUser: any = createAsyncThunk<any, any, any>(
  'users/updateUser',
  async ({first_name, last_name, email, locale, phone, role, id}, thunkAPI) => {
    const bodyData = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      locale: locale,
      phone: phone,
      role: role,
    }
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/companies/users/' + id,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData)
        }
      )
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const updateCompanyData: any = createAsyncThunk(
  "users/updateCompanyData",
  async (companyData, thunkAPI) => {
    try {
      const response = await fetch (
        process.env.REACT_APP_API_URL + '/companies/',
        {
          method: "PUT",
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(companyData)
        }
      )
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const updateUserSettings: any = createAsyncThunk<any, any, any>(
  "users/updateUserSettings",
  async (settings, thunkAPI) => {
    const bodyData ={value:settings}
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/companies/settings/reminder_emails_frequency',
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData)
        }
      )
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
)
export const updateCompanyAvatar: any = createAsyncThunk<any, any, any>(
  "users/updateAvatarImage",
  async({avatar}, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/companies/avatar',
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: avatar
          })
        }

      )
      await (thunkAPI.dispatch(getMe()))
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data)
    }

  }
)

export const updateAvatarImage: any = createAsyncThunk<any, any, any>(
  "users/updateAvatarImage",
  async({id, avatar}, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/companies/users/' + id + '/avatar',
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: avatar
          })
        }

      )
      await (thunkAPI.dispatch(getMe()))
      let data = await response.json()
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data)
    }

  }
)
export const deleteUser: any = createAsyncThunk<any, any, any>(
  "users/deleteUser",
  async(id, thunkAPI) => {
    try {
      console.log(id)
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/companies/users/' + id,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          }
        }
      )
      let data = await response.json()
      if (response.status === 200) {
        return id
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const updateCompanyRequisites: any = createAsyncThunk<any, any, any>(
  "users/updateRequisites",
  async(updateData, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/companies/requisites',
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updateData)
        }

      )

      let data = await response.json()

      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      console.log(e)
      return thunkAPI.rejectWithValue(e.response.data)
    }

  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    company_users: [],
    registrationTokenUser: null,
    emailSent: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    isAdmin: false,
    options: [],
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
    clearEmailSent: (state) => {
      state.emailSent = false
      return state
    }
  },
  extraReducers: {
    [registerUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [registerUser.pending]: (state) => {
      state.isFetching = true;
    },
    [registerUser.fulfilled]: (state) => {
      state.isFetching = false;
      state.emailSent = true
    },
    [verifyRegistrationToken.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [verifyRegistrationToken.pending]: (state) => {
      state.isFetching = true;
    },
    [verifyRegistrationToken.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.registrationTokenUser = payload
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.fulfilled]: (state, {payload}) => {
      let optionsArray: any = []
      for (const op in payload.options) {
        optionsArray.push({id: op, name: payload.options[op]})
      }
      state.isFetching = false;
      state.emailSent = payload.sent;
      state.isAdmin = payload.isAdmin;
      state.options = optionsArray || null;
    },
    [getMe.pending]: (state) => {
      state.isFetching = true;
    },
    [getMe.fulfilled]: (state: any, { payload }) => {
      state.isFetching = false;
      state.user = payload.user;
      state.company_users = [payload.user]
    },
    [getMe.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
    [getCompanyUsers.rejected] : (state) => {
      state.isFetching = false
      state.isError = true
    },
    [getCompanyUsers.pending] : (state) => {
      state.isFetching = true
    },
    [getCompanyUsers.fulfilled] : (state, {payload}) => {
      state.isFetching = false
      state.isError = false
      state.company_users = payload.users
    },
    [addUser.rejected] : (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [addUser.pending]: (state) => {
      state.isFetching = true
    },
    [addUser.fulfilled]: (state, {payload}) => {
      state.isFetching = false
      state.isError = false
      state.isSuccess = true
      state.company_users = state.company_users.concat(payload.user)
    },
    [updateUser.rejected] : (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [updateUser.pending]: (state) => {
      state.isFetching = true
    },
    [updateUser.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false
      state.isError = false
      state.isSuccess = true
      if (state.user._id === payload.user._id){
        state.user = payload.user
      }
      state.company_users = state.company_users.map((u: any) => {
        if (u._id === payload.user._id){
          return payload.user
        }
        return u
      })
    },
    [updateUserSettings.rejected]: (state,{payload}) =>{
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [updateUserSettings.pending] : (state) => {
      state.isFetching = true
    },
    [updateUserSettings.fulfilled] : (state: any, {payload}) => {
      state.isFetching = false
      state.isError = false
      state.isSuccess = true

      state.user = {
        ...state.user,
        company : payload.company
      }
    },
    [updateCompanyData.rejected]: (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [updateCompanyData.pending]: (state) => {
      state.isFetching = true
    },
    [updateCompanyData.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false
      state.isError = false
      state.isSuccess = true
      state.user = {
        ...state.user,
        company: payload.company
      }
    },
    [updateAvatarImage.rejected] : (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [updateAvatarImage.pending] : (state) => {
      state.isFetching = true
    },
    [updateAvatarImage.fulfilled]: (state: any, {payload}: any) => {
      state.isFetching = false
      state.isError = false
      state.isSuccess = true
      if (state.user._id === payload.user._id){
        state.user = payload.user
      }
      state.company_users = state.company_users.map((u: any) => {
        if (u._id === payload.user._id){
          return payload.user
        }
        return u
      })
    },
    [updateCompanyAvatar.rejected] : (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [updateCompanyAvatar.pending] : (state) => {
      state.isFetching = true
    },
    [updateCompanyAvatar.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false
      state.isError = false
      state.isSuccess = true
      if (state.user.company._id === payload.company._id){
        state.user.company = payload.company
      }
      state.company_users = state.company_users.map((u: any) => {
        if (u._id === payload.company._id){
          return payload.company
        }
        return u
      })
    },
    [deleteUser.rejected] : (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [deleteUser.pending] : (state) => {
      state.isFetching = true
    },
    [deleteUser.fulfilled]: (state, {payload}) => {
      state.isFetching = false
      state.isSuccess = true
      state.company_users = state.company_users.filter((i: any) => i._id !== payload)
    },
    [updateCompanyRequisites.rejected] : (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    },
    [updateCompanyRequisites.pending] : (state) => {
      state.isFetching = true
    },
    [updateCompanyRequisites.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false
      state.isSuccess = true
      state.user = {
        ...state.user,
        company : payload.company
      }
    },
    [registerCompany.rejected]: (state, {payload}) => {
      state.isFetching = false
      state.isError = true
      state.errorMessage = payload.message
    }
  },
});

export const { clearState, clearEmailSent } = userSlice.actions;

export const userSelector = (state: any) => state.user;
