import {withErrorBoundary} from "react-error-boundary";

const Other = () => {
  return <div>Other settings page</div>
}

export default withErrorBoundary(
  Other,
  {
    FallbackComponent: () => <div>Setting to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })