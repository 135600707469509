import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

export const getSales: any = createAsyncThunk<any, any, any>(
  'sales/getSales',
  async ({ page, limit, year, month, filters }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/docs/year/' + year +
        '/month/'+month +
        '/paginated?page=' + page +
        '&limit=' + limit +
        '&type=sales_invoice&filters=' + encodeURIComponent(JSON.stringify(filters)),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getSale: any = createAsyncThunk<any, any, any>(
    'sales/getSale',
    async (saleId, thunkAPI) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL+'/sales/'+saleId,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
                        'Content-Type': 'application/json',
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e: any) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const addSale: any = createAsyncThunk<any, any, any>(
    'sales/createSale',
    async ({title, description}, thunkAPI) => {
      const bodyData = {
        title: title,
        description: description,
      }
      try {
        const response = await fetch(
            process.env.REACT_APP_API_URL + '/docs',
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(bodyData)
            }
        )
        let data = await response.json()
        if (response.status === 200) {
          return data
        } else {
          return thunkAPI.rejectWithValue(data)
        }
      } catch (e: any) {
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
)
export const editSale: any = createAsyncThunk<any, any, any>(
    'sales/editSale',
    async ({id, title, description}, thunkAPI) => {
      const bodyData = {
        title: title,
        description: description,
      }
      try {
        const response = await fetch(
            process.env.REACT_APP_API_URL + '/docs/' + id,
            {
              method: 'PUT',
              headers: {
                Accept: 'application/json',
                Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(bodyData)
            }
        )
        let data = await response.json()
        if (response.status === 200) {
          return data
        } else {
          return thunkAPI.rejectWithValue(data)
        }

      } catch (e: any) {
        thunkAPI.rejectWithValue(e.response.data)
      }
    }
)

export const deleteSale: any = createAsyncThunk<any, any, any>('sales/deleteSale', async (id, thunkAPI) => {
  try {
    const response = await fetch(process.env.REACT_APP_API_URL + '/docs/' + id,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          }
        }
    )
    let data = await response.json()
    if (response.status === 200) {
      return id
    } else {
      return thunkAPI.rejectWithValue(data)
    }
  } catch (e: any) {
    thunkAPI.rejectWithValue(e.response.data);
  }
})

export const uploadDocument: any = createAsyncThunk <any, any, any>(
  'sales/uploadDocument',
  async ({type, filename, filedata }, thunkAPI) => {
    try {
      let description = type
      description = (description.replace('/_/g', ' ').trim()) + " " + filename
      const params = {
        name: filename,
        type: type,
        data: filedata,
        description: description,
        force: true
      }
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/docs/upload/',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const salesSlice = createSlice({
  name: 'sales',
  initialState: {
    salesData: null,
    emailSent: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [getSales.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getSales.pending]: (state) => {
      state.isFetching = true;
    },
    [getSales.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.salesData = payload
    },
    [uploadDocument.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [uploadDocument.pending]: (state) => {
      state.isFetching = true;
    },
    [uploadDocument.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
  },
});

export const { clearState } = salesSlice.actions;

export const salesSelector = (state: any) => state.sales;
