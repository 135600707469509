import React from 'react';
import { Button } from '@mui/material';

type CompanioButtonProps = {
    id?: string;
    onClick?: Function;
    variant?: string;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    type?: string;
    disabled?: boolean;
    children: React.ReactNode;
};

function CompanioButton({
  id,
  onClick,
  variant,
  size,
  className,
  type,
  disabled,
  children
}: CompanioButtonProps) {
    return (
        // @ts-ignore
        <Button
            id={id}
            name={id}
            variant="outlined"
            data-testid="companio_button"
            className={className}
            onClick={(e) => (onClick ? onClick(e) : null)}
            size={size}
            margin="none"
            type={type}
            disabled={disabled}
            sx={{
                maxHeight: '48px',
                textTransform: 'none',
                fontWeight: 600,
                backgroundColor: variant === 'outlined' ? 'transparent' : '#FF4EA1',
                color: variant === 'outlined' ? '#253292' : '#FFFFFF',
                border: variant === 'outlined' ? 'solid #253292 2px' : 'solid #FF4EA1 2px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-center',
                borderRadius: '31px',
                transaction: 'all 300ms',
                margin: '0 8px 0 8px',
                padding: '0 24px 0 24px',
                boxShadow: '0px 4px 12px rgba(55, 81, 255, 0.24)',
                ':hover': {
                    border: variant === 'outlined' ? 'solid #253292 2px' : 'solid #FF4EA1 2px',
                    backgroundColor: variant === 'outlined' ? 'transparent' : '#FF4EA1',
                    opacity: 0.7
                },
                ':disabled': {
                    border: variant === 'outlined' ? 'solid #253292 2px' : 'solid #FF4EA1 2px',
                    backgroundColor: variant === 'outlined' ? 'transparent' : '#FF4EA1',
                    color: variant === 'outlined' ? '#253292' : '#FFFFFF',
                    opacity: 0.3
                }
            }}>
            {children}
        </Button>
    );
}

export default CompanioButton;