import {withErrorBoundary} from "react-error-boundary";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from "react-intl";
import {
  clearState,
} from '../Banks/BanksSlice';
import toast from "react-hot-toast";
import {loadShipmentsOfType, virtualOfficeSelector} from "./VirtualOfficeSlice";
import CompanioButton from "../../common/components/CompanioButton";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import SearchFilter from "../../common/components/SearchFilter";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Menu from "../../common/components/Menu";
import Pagination from "../../common/components/Pagination";
import {useNavigate, useParams} from "react-router-dom";

const VirtualOffice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  console.log(params)
  const type = Object.values(params).length ?Object.values(params)[0] : 'letters'

  const {virtualOfficeData, isError, errorMessage} = useSelector(virtualOfficeSelector)
  const intl = useIntl()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    search: ''
  });

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);

  useEffect(() => {
    dispatch(loadShipmentsOfType({
      type: type,
      page: page,
      limit: limit,
      filters: filters
    }) as any);
  }, [dispatch, filters, limit, page, type])

  const isContained = () => {
    return type === "letters" ? true : false
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="virtual_office"/></p>
      <LoggedInUserMenu />
    </div>
    <div className="flex items-start w-full mb-8">
      <Button variant={isContained() ? "contained" : "outlined"}
              sx={{
                color: isContained() ? "#fff" : "#9FA2B4",
                borderRadius: "8px",
                backgroundColor: isContained() ? "#253292" : "#fff"
              }}
              onClick={() => navigate('/virtual_office/letters')}>
        <FormattedMessage id="letters" />
      </Button>
      <Button variant={isContained() ? "outlined" : "contained"}
              sx={{
                color: isContained() ? "#9FA2B4" : "#fff",
                borderRadius: "8px",
                backgroundColor: isContained() ? "#fff" : "#253292",
                marginLeft: "16px"
              }}
              onClick={() => navigate('/virtual_office/packages')}>
        <FormattedMessage id="packages" />
      </Button>
    </div>
    <div className="flex flex-col w-full border rounded-lg my-6">
      <div className="flex justify-between items-center px-6 py-4">
        <p className="text-lg font-LaNord font-bold text-[#252733]">{intl.formatMessage({id: type, defaultMessage:type})}</p>
        <div className="flex justify-items-center items-center">
          <SearchFilter filters={filters} setFilters={setFilters} />
          <CompanioButton size="small" variant="contained" className="w-40 h-[40px]" onClick={() => navigate('/virtual_office/add/' + type)}>
            Add {type}
          </CompanioButton>
        </div>
      </div>
      <TableContainer sx={{height: "350px"}}>
        <Table
          sx={{minWidth: 550, width: "100%"}}
          aria-labelledby="tableTitle"
          size='medium'
        >
          <TableHead className="p-4">
            <TableRow>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4] pl-4"><FormattedMessage id="title"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="status"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="scanned_date"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="arrival_date"/></div></TableCell>
              <TableCell align="left" ></TableCell>
            </TableRow>
          </TableHead>
          {type === 'letters' ? <LettersTable letterData={virtualOfficeData} /> : <PackagesTable packageData={virtualOfficeData}/>}
        </Table>
      </TableContainer>
      <div>

        <Pagination className="mt-4"
                    requestPaginationData={virtualOfficeData}
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setPage={setPage}
        />
      </div>
    </div>
  </div>
}

const LettersTable = ({letterData}: any) => {
  console.log(letterData)

  return <TableBody>
    {letterData.letters && letterData.letters.length ? letterData.letters.map((letter: any, i: any) => <TableRow
        // hover
        role="checkbox"
        tabIndex={-1}
        key={i}
      >
        <TableCell
          component="th"
          scope="col"
          sx={{paddingLeft:4, paddingRight: 4}}
        >
          <div className="text-sm text-left font-bold text-[#253292]">
            {letter.description}
          </div>
        </TableCell>
        <TableCell align="left" >
          <div className={`text-center ${badgeForStatus(letter.status)}`}>
            {(letter.status)}
          </div>
        </TableCell>
        <TableCell align="left" >
          <div className="text-sm text-left text-[#253292]">{letter.scanned_date ? formattedDate(letter.scanned_date): '-'}</div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]">{letter.arrival_date? formattedDate(letter.arrival_date): '-'}</div>
        </TableCell>
        <TableCell align="left">
          <Menu sx={{}}>
            <div><FormattedMessage id="see_details"/></div>
          </Menu>
        </TableCell>
      </TableRow>):
      <TableRow>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]">No letters were found</div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]"></div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]"></div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]"></div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]"></div>
        </TableCell>
      </TableRow>
    }

  </TableBody>
}

const PackagesTable = ({packageData}: any) => {

  return <TableBody>
    {packageData.packages && packageData.packages.length ? packageData.packages.map((pck: any, i: any) => <TableRow
        // hover
        role="checkbox"
        tabIndex={-1}
        key={i}
      >
        <TableCell
          component="th"
          scope="col"
          sx={{paddingLeft:4, paddingRight: 4}}
        >
          <div className="text-sm text-left font-bold text-[#253292]">
            {pck.description}
          </div>
        </TableCell>
        <TableCell align="left" >
          <div className={`text-center ${badgeForStatus(pck.status)}`}>
            {(pck.status)}
          </div>
        </TableCell>
        <TableCell align="left" >
          <div className="text-sm text-left text-[#253292]">{pck.scanned_date ? formattedDate(pck.scanned_date): '-'}</div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]">{pck.arrival_date? formattedDate(pck.arrival_date): '-'}</div>
        </TableCell>
        <TableCell align="left">
          <Menu sx={{}}>
            <div><FormattedMessage id="see_details"/></div>
          </Menu>
        </TableCell>
      </TableRow>):
      <TableRow>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]">No letters were found</div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]"></div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]"></div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]"></div>
        </TableCell>
        <TableCell align="left">
          <div className="text-sm text-left text-[#253292]"></div>
        </TableCell>
      </TableRow>

    }

  </TableBody>
}

const formattedDate = (date: string) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const day = new Date(date).getDate()
  const month = new Date(date).getMonth()
  const year = new Date(date).getFullYear()

  return `${months[month]} ${day}, ${year}`
}

const badgeForStatus = (status: string) => {
  switch (status) {
    case 'delivered':
    case 'scanned':
      return 'border rounded-lg bg-[#00DA8B] text-white uppercase'
    case 'arrived':
    case 'sent':
      return 'border rounded-lg bg-[#FEA500] text-white uppercase'
    case 'lost':
    case 'canceled':
      return 'border rounded-lg bg-red-600 text-white uppercase'
    case 'arriving':
      return 'border rounded-lg bg-blue-600 text-white uppercase'
  }
}

export default withErrorBoundary(
  VirtualOffice,
  {
    FallbackComponent: () => <div>Error to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  }
)