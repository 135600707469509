import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

export const getBanks: any = createAsyncThunk(
  'banks/getBanks',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/bank_accounts?show_disabled=true',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const disableBank: any = createAsyncThunk<any, any, any>(
  'banks/disableBank',
  async (id, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/bank_accounts/'+id,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      console.log(data)
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteBank: any = createAsyncThunk<any, any, any>(
  'banks/deleteBank',
  async (id, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/bank_accounts/'+id,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ true_deletion: true, delete_permanently: true })
        }
      );
      let data = await response.json();
      console.log(data)
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getLinkableBanksForCountry: any = createAsyncThunk<any, any, any>(
  'banks/getLinkableBanksForCountry',
  async (country, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/bank_accounts/countries/'+country,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          }
        }
      );
      let data = await response.json();
      console.log(data)
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getLinkNordigenBank = createAsyncThunk<any, any, any>(
  'banks/getLinkNordigenBank',
  async (bankId, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/bank_accounts/link/'+bankId,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          }
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const banksSlice = createSlice({
  name: 'banks',
  initialState: {
    banks: null,
    linkableBanksForCountry: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [getBanks.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getBanks.pending]: (state) => {
      state.isFetching = true;
    },
    [getBanks.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.banks = payload.banks
    },
    [disableBank.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [disableBank.pending]: (state) => {
      state.isFetching = true;
    },
    [disableBank.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.banks = state.banks ? (state.banks as any).map((bank: any) => {
        if(bank._id === payload.bank._id){
          return payload.bank
        }
        return bank
      }) : null;
    },
    [deleteBank.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [deleteBank.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteBank.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.banks = state.banks ? (state.banks as any).filter((bank: any) => {
        return bank._id !== payload.bank._id
      }): null;
    },
    [getLinkableBanksForCountry.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getLinkableBanksForCountry.pending]: (state) => {
      state.isFetching = true;
    },
    [getLinkableBanksForCountry.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.linkableBanksForCountry = payload.banks
    },
  },
});

export const { clearState } = banksSlice.actions;

export const banksSelector = (state: any) => state.banks;
