import {
  persistStore,
  persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {combineReducers, configureStore} from '@reduxjs/toolkit';
import { userSlice } from '../features/User/UserSlice';
import { salesSlice } from "../features/Sales/SalesSlice";
import { bankMovementsSlice } from "../features/BankMovements/BankMovementsSlice";
import { purchasesSlice } from "../features/Purchases/PurchasesSlice"
import { billingSlice } from "../features/Billing/BillingSlice"
import { customersSlice } from "../features/Customers/CustomersSlice"
import { reportsSlice } from "../features/Reports/ReportsSlice"
import { notificationsSlice } from "../features/Notifications/NotificationsSlice"
import { ticketsSlice } from "../features/Tickets/TicketsSlice";
import { banksSlice } from "../features/Banks/BanksSlice";
import {travelsSlice} from "../features/Travels/TravelsSlice"
import { employeesSlice } from '../features/Employee/EmployeeSlice';
import { virtualOfficeSlice } from '../features/VirtualOffice/VirtualOfficeSlice';

const persistConfig = {
  key: 'root',
  version: 0.41,
  storage,
}

const rootReducer = combineReducers({
  user: userSlice.reducer,
  sales: salesSlice.reducer,
  bankMovements: bankMovementsSlice.reducer,
  purchases: purchasesSlice.reducer,
  billing: billingSlice.reducer,
  customers: customersSlice.reducer,
  reports: reportsSlice.reducer,
  notifications: notificationsSlice.reducer,
  tickets: ticketsSlice.reducer,
  banks: banksSlice.reducer,
  travels: travelsSlice.reducer,
  employees: employeesSlice.reducer,
  virtualOffice: virtualOfficeSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export let persistor = persistStore(store)
