
import {useNavigate} from 'react-router-dom';
import {withErrorBoundary} from 'react-error-boundary';
import {
  Button,
  Typography,
  Paper, Container, Box, TextField, InputLabel, Select, MenuItem, FormControl
} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {getMe, updateCompanyRequisites, userSelector, clearState} from "../User/UserSlice";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import emtak from "../../translations/emtak/emtak_summary_es";
import {readFileAsBase64} from "../../common/utils/utils";
import toast from "react-hot-toast";

const RegisterSteps = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const [fieldsToUpdate, setFieldsToUpdate] = useState([])

  const {isFetching, isError, isSuccess, user, errorMessage} = useSelector(userSelector)

  useEffect(() => {
    dispatch(getMe() as any);
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
    if (isSuccess) {
      toast.success(intl.formatMessage({id: 'data_successfully_modified'}));
      dispatch(clearState());
      navigate(-1)
    }
  }, [dispatch, isError, intl, isSuccess, errorMessage, navigate]);

  if (!user) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  const status = user.company.status
  if(!status || status === 'pending_company_payment'){
    navigate('/register')
  }
  if(status === 'ready'){
    navigate('/')
  }
  if(status === 'pending_bank_account'){
    navigate('/register/bank_account')
  }

  const updateField = (data: any) => {
    const fieldExists = fieldsToUpdate.find((i: any) => i.id === data.id)

    if(fieldExists){
      const updatedFields: any = fieldsToUpdate.map((i: any) => {
        if (i.id === data.id){
          return data
        }
        return i
      })

      return setFieldsToUpdate(updatedFields)
    }

    return setFieldsToUpdate(fieldsToUpdate.concat(data))
  }

  const submitNewData = () => {
    dispatch(updateCompanyRequisites({business_information: fieldsToUpdate}) as any)
  }

  const failedInputs = user.company.business_information.filter((i: any) => i.status === 'rejected')

  const hasFailedInputs = failedInputs.length > 0

  const submitDisabled = failedInputs.length !== fieldsToUpdate.length

  return <div className="flex min-h-screen">
    <div className="flex-1 flex flex-col justify-between" style={{padding: '40px'}}>
      <img src="/assets/images/companio-logo.png" alt="logo" width="149" style={{maxHeight: "40px"}}/>

      <div className="text-left">
        <Typography><FormattedMessage id="company_status_pending_documents"/></Typography>
        <Button variant="outlined" onClick={() => navigate(-1)}><FormattedMessage id="back" /></Button>

        {hasFailedInputs && <FormattedMessage id="we_need_you_fix_information"/>}

        {hasFailedInputs && <Button
          disabled={submitDisabled && !isFetching}
          variant="contained"
          onClick={() => submitNewData()}
        >
          <FormattedMessage id="next" />
        </Button>}

        {user.company.business_information.map((i: any, key: any) => {
          let statusColor = "yellow-600"
          let subText = <FormattedMessage id="pending" />

          if(i.status === 'rejected'){
            statusColor = "red-600"
            subText = i.reason
          }
          if(i.status === 'verified'){
            statusColor = "green-600"
            subText = <FormattedMessage id="approved" />
          }

          return <Paper key={key} className={'flex justify-between p-4 mb-3 border-'+statusColor}>
            <div>
              <Typography>{key + 1}. <FormattedMessage id={i.id} /></Typography>
              <Typography className={'text-'+statusColor}>{subText}</Typography>
            </div>
            {statusOrInput(i, updateField, intl, fieldsToUpdate)}
          </Paper>
        })}

      </div>

      <div></div>
      {/*has to be here because of flex */}

    </div>

    <div className="flex items-center justify-center"
         style={{
           background: 'linear-gradient(109.4deg, #9A53CB 1.26%, #FF4EA1 96.4%)',
           padding: '40px',
           width: '350px'
         }}>
      <img src="/assets/images/login-people.png" alt="people"/>
    </div>
  </div>
};

const statusOrInput = (i: any, updateField: any, intl: any, fieldsToUpdate: any) => {
  if(i.status === 'unverified' || i.status === 'verified'){
    return i.value || i.filename
  }

  if(i.type === 'String'){
    return <TextField
      id={i.id}
      label={intl.formatMessage({id: 'your_legal_address'})}
      onChange={(e) => updateField({
        id: i.id,
        type: 'String',
        value: e.target.value
      })}
      required/>
  }

  if(i.type === 'File'){
    const idHandler = async (e: any) => {
      const base64File = await readFileAsBase64(e.target.files[0])

      updateField({
        id: i.id,
        type: 'File',
        filename: e.target.files[0].name,
        mime_type: e.target.files[0].type,
        value: base64File
      })
    }

    const fileUploadField = fieldsToUpdate.find((item: any) => item.id === i.id)

    return <label htmlFor={i.id}>
      <input style={{display: "none"}}
             onChange={idHandler}
             accept="*"
             id={i.id}
             type="file"/>
      <Button variant="contained" component="span">
        {fileUploadField ? fileUploadField.filename : <FormattedMessage id="upload"/>}
      </Button>
    </label>
  }

  if(i.id === 'activity'){
    return <FormControl>
      <InputLabel id="activity"><FormattedMessage id="activity"/></InputLabel>
      <Select
        labelId="activity"
        id="activity"
        label={intl.formatMessage({id: 'activity'})}
        sx={{width: "350px", textAlign: "left"}}
        onChange={(e) => updateField({
          id: 'activity',
          type: 'Options',
          value: e.target.value
        })}
        defaultValue=""
      >
        <MenuItem sx={{width: "350px", textAlign: "left"}} value="" disabled><FormattedMessage id="choose_option"/></MenuItem>
        {emtak.map((e, i) => {
          return <MenuItem sx={{width: "350px", textAlign: "left"}} value={e.code} key={i}>{e.description}</MenuItem>
        })}
      </Select>
    </FormControl>
  }
}

export default withErrorBoundary(
  RegisterSteps,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
