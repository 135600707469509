import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {clearState, getReports, reportsSelector} from './ReportsSlice';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {withErrorBoundary} from 'react-error-boundary';

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Container,
  Box, TableHead,
} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import BadgeForDocumentStatus from "../../common/components/BadgeForDocuments";
import MonthYearFilter from "../../common/components/MonthYearFilter";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import CompanioButton from "../../common/components/CompanioButton";

const Reports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const [monthYear, setMonthYear] = useState({
    month: localStorage.getItem('teee_selected_month') || new Date().getMonth() + 1,
    year: localStorage.getItem('teee_selected_year') || new Date().getFullYear()
  })

  const {isError, errorMessage, reportsData} = useSelector(reportsSelector)

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);


  useEffect(() => {
    dispatch(getReports({
      year: monthYear.year,
      month: monthYear.month,
    }) as any);
  }, [dispatch, monthYear]);

  if (!reportsData) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="reports"/></p>
      <LoggedInUserMenu />
    </div>
    <div className="border rounded-lg mb-6">
      <div className="flex justify-between items-center px-6 py-8 border border-x-0 border-t-0">
        <p className="text-lg font-LaNord font-bold text-[#252733]">{intl.formatMessage({id: "reports", defaultMessage:"Reports"})}</p>
        <div className="flex justify-items-center items-center">
          {/*<CompanioButton size="small" variant="contained" className="w-32 h-[40px]" onClick={() => navigate('/sales/create-sales')}>Create</CompanioButton>*/}
          <MonthYearFilter monthYear={monthYear} setMonthYear={setMonthYear}/>
        </div>
      </div>

      <TableContainer>
        <Table
          sx={{minWidth: 750}}
          aria-labelledby="tableTitle"
          size='medium'
        >
          <TableHead className="p-4">
            <TableRow>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4] pl-4"><FormattedMessage id="reports"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="status"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="date"/></div></TableCell>
              <TableCell align="left" ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {reportsData.reports.map((report: any, i: any) =>
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={i}
              >
              <TableCell
                scope="row"
              >
                <div className="text-sm text-left font-bold text-[#253292] pl-4">
                  {intl.formatMessage({ id: report.type})}
                </div>
              </TableCell>
              <TableCell align="left"><BadgeForDocumentStatus className="mr-2" status={report.status}/>
                <span className="text-[#9FA2B4]"><FormattedMessage id={report.status} defaultMessage={report.status}/></span>
              </TableCell>
              <TableCell align="left">
                <div className="text-sm text-left font-bold text-[#253292]">
                  {formattedDate(report.created)}
                </div>
              </TableCell>
              <TableCell>
                <CompanioButton variant="outlined" size="small" onClick={() => navigate("report/" + report._id)}>
                  <FormattedMessage id="open"/>
                </CompanioButton>
              </TableCell>
            </TableRow>)}

          </TableBody>
        </Table>
      </TableContainer>
    </div>

  </div>
};

const formattedDate = (date: string) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const day = new Date(date).getDate()
  const month = new Date(date).getMonth()
  const year = new Date(date).getFullYear()

  return `${months[month]} ${day}, ${year}`
}

export default withErrorBoundary(
  Reports,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })
