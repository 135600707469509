type CurrencyDataT = {
    "code": string;
    "name": string;
};

export const currencyData: Array<CurrencyDataT> = [
    {
        "code": "EUR",
        "name": "Euro",

    },
    {
        "code": "USD",
        "name": "United States Dollar",
    },
]