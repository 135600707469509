
import {useNavigate} from 'react-router-dom';
import {withErrorBoundary} from 'react-error-boundary';
import {
  Button,
  Typography,
} from "@mui/material";
import {FormattedMessage} from "react-intl";

const RegisterSteps = () => {
  const navigate = useNavigate()


  return <div className="flex min-h-screen">
    <div className="flex-1 flex flex-col justify-between" style={{padding: '40px'}}>
      <img src="/assets/images/companio-logo.png" alt="logo" width="149" style={{maxHeight: "40px"}}/>

      <div>
        <Typography><FormattedMessage id="thank_you"/></Typography>
        <Typography>
          <FormattedMessage id="complete_registration_description"/>
        </Typography>

        <Button variant="contained" onClick={() => navigate('/register/onboarding')}><FormattedMessage id="lets_go" /></Button>
      </div>

      <div></div>
      {/*has to be here because of flex */}

    </div>

    <div className="flex items-center justify-center"
         style={{
           background: 'linear-gradient(109.4deg, #9A53CB 1.26%, #FF4EA1 96.4%)',
           padding: '40px',
           width: '350px'
         }}>
      <img src="/assets/images/login-people.png" alt="people"/>
    </div>
  </div>
};

export default withErrorBoundary(
  RegisterSteps,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
