import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import CompanioButton from "../../common/components/CompanioButton";
import {FormattedMessage, useIntl} from "react-intl";
import BadgeForDocumentStatus from "../../common/components/BadgeForDocuments";
import {UploadFile} from "../../common/components/UploadFile";
import {readFileAsBase64} from "../../common/utils/utils";
import {uploadDocument, salesSelector, addSale, editSale, getSale, clearState} from "../Sales/SalesSlice";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";

const UploadInvoice =() => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    // const {sale_id} = useParams()
    const intl = useIntl();
    //
    // const [limit, setLimit] = useState(10)
    // const [page, setPage] = useState(1)
    // const [filters, setFilters] = useState({
    //     search: ''
    // })
    //
    const dropAreaRef: React.Ref<any> = useRef(null)
    const [dragActive, setDragActive] = useState(false);

    const {isFetching, isError, errorMessage, isSuccess, salesData} = useSelector(salesSelector)

    // const selectedSale = salesData.contacts.find((i: any) => i._id === sale_id)

    // const initialSaleState = sale_id ? {
    //     title: selectedSale.name,
    //     description: selectedSale.description,
    //     id: sale_id
    // } : emptySaleData

    // const [saleData, setSaleData] = useState(initialSaleState)

    // const handleInputChange = (e: any) => {
    //     setSaleData({
    //         ...saleData,
    //         [e.target.id]: e.target.value
    //     })
    // }

    // const onSaveSaleClicked = () => {
    //     if(sale_id) {
    //         dispatch(editSale(salesData) as any)
    //     } else {
    //         dispatch(addSale(saleData) as any)
    //         setSaleData(emptySaleData)
    //     }
    // }

    useEffect(() => {
        if (isError) {
            toast.error(intl.formatMessage({id: 'error_modifying_user'}));
        }
        if (isSuccess) {
            toast.success(intl.formatMessage({id: 'data_successfully_modified'}));
        }
        dispatch(clearState());
    }, [dispatch, isError, isSuccess, intl])


    useEffect(() => {
        console.log('salesData33333', salesData)
    },[salesData])

    useEffect(() => {
        return () => {
            dispatch(clearState())
        }
    })

    useEffect(() => {
        if (isError) {
            toast.error(errorMessage)
            dispatch(clearState())
        }
    }, [dispatch, isError, errorMessage, navigate])

    // useEffect(() => {
    //     dispatch(getSale({page, limit, filters}) as any)
    // }, [dispatch, limit, page, filters])
    //
    const handleDrag = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if(event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false)
        }
    }

    const handleDrop = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false)
        if(event.dataTransfer.files && event.dataTransfer.files[0]){
            const files: any[] = event.dataTransfer.files
            for (const file of files) {
                const filedata = await readFileAsBase64(file)
                dispatch(uploadDocument({
                    type: "sales_invoice",
                    filename: file.name,
                    filedata: filedata
                }))
            }
        }
    }

    const handleDropAreaChange = async (event: any) => {
        event.preventDefault();
        if(event.target.files && event.target.files[0]) {
            const files: any[] = event.target.files
            for (const file of files) {
                const filedata = await readFileAsBase64(file)
                dispatch(uploadDocument({
                    type: "sales_invoice",
                    filename: file.name,
                    filedata: filedata
                }))
            }
        }
    }

    const handleUploadButtonClick = (event: React.MouseEvent) => {
        event.preventDefault();
        if(dropAreaRef.current) {
            dropAreaRef.current.click()
        }
    }
    return (
        <div className="flex flex-col w-full px-4">
            <div className="flex justify-between mb-5">
                <button className="flex justify-items-center items-center text-base" onClick={() => navigate("/sales/")}>
                    <ArrowBackIosIcon className="text-blue-800" sx={{height: '15px'}}/>
                    <p className="text-left py-5 font-bold text-blue-800">Back to Sales</p>
                </button>
                <LoggedInUserMenu/>
            </div>
            <div className="flex gap-8">
                <div className="w-5/12 border rounded-lg p-6">
                    <p className="text-lg text-left font-LaNord font-bold text-[#252733]">Upload purchase invoice</p>
                    <div className="flex flex-col items-start pt-6">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">TITLE</div>
                        <input placeholder="Short, descriptive title" className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full"
                               // value={salesData.title}
                               // onChange={handleInputChange}
                               required
                               id="title"/>
                    </div>
                    <div className="flex flex-col items-start pt-6">
                        <div className="text-xs font-LaNord text-[#9FA2B4] font-bold">DETAILS</div>
                        <textarea placeholder="Any additional details..." className="border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 p-4 w-full resize-none flex-1"
                                  // value={salesData.description}
                                  // onChange={handleInputChange}
                                  required
                                  id="description"/>
                    </div>
                    <div className="flex justify-between pt-8">
                        <UploadFile text="Upload a file"
                                    dragActive={dragActive}
                                    handleDrop={handleDrop}
                                    handleDrag={handleDrag}
                                    handleChange={handleDropAreaChange}
                                    dropAreaRef={dropAreaRef}/>
                        <CompanioButton size="small" variant="contained" className="w-40 h-40" onClick={handleUploadButtonClick}>{intl.formatMessage({id: "upload invoice", defaultMessage: "Upload Invoice"})}</CompanioButton>
                    </div>
                </div>
                <div className="w-7/12 border rounded-lg">
                    <p className="text-lg text-left font-LaNord font-bold text-[#252733] p-6">Your current invoices</p>
                    <div className="flex items-center p-6 border-t">
                        <BadgeForDocumentStatus className="mr-4" status={'uploaded'}/>
                        <div className="flex flex-col">
                            <div className="text-sm text-left font-bold text-[#253292]">
                                Invoice.pdf
                            </div>
                            <div className="text-xs font-LaNord text-[#C5C7CD] flex items-center pt-1">
                                <FormattedMessage id={'uploaded'} defaultMessage={'uploaded'}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center p-6 border-t">
                        <BadgeForDocumentStatus className="mr-4" status={'uploaded'}/>
                        <div className="flex flex-col">
                            <div className="text-sm text-left font-bold text-[#253292]">
                                Invoice.pdf
                            </div>
                            <div className="text-xs font-LaNord text-[#C5C7CD] flex items-center pt-1">
                                <FormattedMessage id={'uploaded'} defaultMessage={'uploaded'}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center p-6 border-t">
                        <BadgeForDocumentStatus className="mr-4" status={'uploaded'}/>
                        <div className="flex flex-col">
                            <div className="text-sm text-left font-bold text-[#253292]">
                                Invoice.pdf
                            </div>
                            <div className="text-xs font-LaNord text-[#C5C7CD] flex items-center pt-1">
                                <FormattedMessage id={'uploaded'} defaultMessage={'uploaded'}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const emptySaleData = {
    title: '',
    description: '',
}

export default UploadInvoice

