import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {FormattedMessage} from "react-intl";
import React from "react";

type DropAreaType = {
    text: string;
    dragActive: any;
    handleDrop: any;
    handleDrag: any;
    handleChange: any;
    dropAreaRef?: any;
}

export const UploadFile = ({text, dragActive, handleDrop, handleDrag, handleChange, dropAreaRef = null}: DropAreaType) => {
    return (
        <form className="flex items-center justify-center"
              onDragEnter={handleDrag}
              onSubmit={(e: any) => e.preventDefault()}
        >
            <input type="file" id="input-file-upload" multiple={true} className="hidden" ref={dropAreaRef} onChange={handleChange}/>
            <label id="label-file-upload" htmlFor="input-file-upload" className={`h-full w-full ${dragActive ? 'bg-slate-200': ''}`}>
                <div>
                    <div className="flex items-center">
                        <img src="/assets/images/logos/Icon_attachment.svg" alt="" width="24"/>
                        <div className="font-LaNord font-bold text-sm text-[#9FA2B4]"><FormattedMessage id={text} /></div>
                    </div>
                    {/*<CloudUploadOutlinedIcon sx={{color: "#DFE0EB"}} />*/}
                    {/*<p className="ml-2" style={{color: "#DFE0EB", fontSize: "14px"}}>*/}
                    {/*    <FormattedMessage id={text} />*/}
                    {/*</p>*/}
                </div>
            </label>
            { dragActive && <div id="drag-file-element"
                                 onDragEnter={handleDrag}
                                 onDragLeave={handleDrag}
                                 onDragOver={handleDrag}
                                 onDrop={handleDrop}
                                 style={{
                                     position: "absolute",
                                     width: "100%",
                                     height: "100%",
                                     borderRadius: "1rem",
                                     top: "0px",
                                     right: "0px",
                                     bottom: "0px",
                                     left: "0px",
                                 }}
            >
            </div> }
        </form>
    )
}

