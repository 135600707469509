import {withErrorBoundary} from "react-error-boundary";
import React, { CSSProperties } from 'react';

type DefaultInputT = {
  id?: string;
  placeholder?: string;
  value: string;
  onChange: Function;
  style?: CSSProperties;
  className?: string;
  label?: string;
  type?: string;
  required?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
};

function DefaultInput({
   id,
   placeholder,
   value,
   onChange,
   style,
   className,
   label,
   type,
   required,
   children,
   disabled
}: DefaultInputT) {
  return (
    <div
      className={`flex flex-col items-start justify-center ${className}`}
      style={style}
      data-testid="registration_text_input_wrapper">
      {label && (
        <p
          className="text-start uppercase"
          style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
          {label}
        </p>
      )}
      <input
        disabled={disabled}
        className={`border rounded-lg bg-[#FCFDFE] h-[42px] mt-2 px-4 w-full text-[#9FA2B4] text-[12px]`}
        data-testid="text_input"
        type={type || "text"}
        required={required}
        placeholder={placeholder}
        id={id}
        autoComplete={id}
        value={value}
        onChange={(e) => onChange(e)}
      >
        {children}
      </input>
    </div>
  );
}

export default withErrorBoundary(DefaultInput, {
  fallback: <div>Failed to load text input component</div>
});
