import './SelectBox.css'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearState, customersSelector, deleteCustomer, getCustomers} from "../../features/Customers/CustomersSlice";
import {useNavigate} from "react-router-dom";
import {Box, Container} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {userSelector} from "../../features/User/UserSlice";


const SelectBox = () => {
    const {isError, errorMessage, customersData} = useSelector(customersSelector)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {user} = useSelector(userSelector)

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [filters, setFilters] = useState({
        search: ''
    })

    useEffect(() => {
        return () => {
            dispatch(clearState())
        }
    })

    useEffect(() => {
        if (isError) {
            toast.error(errorMessage)
            dispatch(clearState())
        }
    }, [dispatch, isError, errorMessage, navigate])

    useEffect(() => {
        dispatch(getCustomers({page, limit, filters}) as any)
    }, [dispatch, limit, page, filters])

    const handleDeleteClick = (id: any) => {
        dispatch(deleteCustomer(id) as any)
    }

    if (!customersData) {
        return <Container maxWidth="xs">
            <Box sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box>
        </Container>
    }


    return (
        <>
        <div className="dropdown">
            <button className="dropbtn font-LaNord">Actions
                <ArrowDropDownIcon className="ml-12" style={{color:'#9FA2B4'}}/>
            </button>
            {customersData.contacts.map((c: any, i: any) =>
                <div className="dropdown-content font-LaNord font-bold">
                    <a onClick={() => navigate('/settings/add_user/'+user._id)}>Update</a>
                    <a>Send new invoice</a>
                    <a onClick={() => handleDeleteClick(c._id)}>Delete</a>
                </div>
            )}
        </div>
    </>
    )
}

export default SelectBox