import {useEffect, useState} from 'react';
import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {bankMovementsSelector, clearState, getBankMovements} from './BankMovementsSlice';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Container,
  Box,
  Menu as MUIMenu,
  Checkbox,
  FormControlLabel,
  FormGroup, TableHead
} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "../../common/components/Pagination";
import BadgeForTransaction from "../../common/components/BadgeForTransaction";
import MonthYearFilter from "../../common/components/MonthYearFilter";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import CompanioButton from "../../common/components/CompanioButton";
import {withErrorBoundary} from "react-error-boundary";
import {banksSelector} from "../Banks/BanksSlice";
import SearchFilter from "../../common/components/SearchFilter";
import Menu from "../../common/components/Menu";

const BankMovements = () => {
  const dispatch: Function = useDispatch();
  const navigate: Function = useNavigate();
  const intl: any = useIntl();

  const [limit, setLimit]: React.ComponentState = useState(10)
  const [page, setPage]: React.ComponentState = useState(1);
  const [filters, setFilters]: React.ComponentState = useState({
    matching: ['matched', 'unmatched'],
    search: ''
  });
  const [monthYear, setMonthYear]: React.ComponentState = useState({
    month: localStorage.getItem('teee_selected_month') || new Date().getMonth() + 1,
    year: localStorage.getItem('teee_selected_year') || new Date().getFullYear()
  })

  const {isError, errorMessage, bankMovementsData}: any = useSelector(bankMovementsSelector)

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);

  const {banks} = useSelector(banksSelector)

  useEffect(() => {
    dispatch(getBankMovements({
      bankAccountId: banks[0]._id,
      page: page,
      limit: limit,
      year: monthYear.year,
      month: monthYear.month,
      filters: filters
    }));
  }, [dispatch, limit, page, filters, monthYear, banks]);

  if (!banks.length) {
    return <Container maxWidth="xs">
      No banks connected!
    </Container>
  }

  if (!bankMovementsData) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5 items-center">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="bank_movements"/></p>
      <LoggedInUserMenu />
    </div>

    <div className="border rounded-lg mb-6">
      <div className="flex justify-between items-center px-6 py-4">
        <p className="text-lg font-LaNord font-bold text-[#252733]">{intl.formatMessage({id: "bank_movements", defaultMessage:"Bank Movements"})}</p>
        <div className="flex justify-items-center items-center">
          <SearchFilter filters={filters} setFilters={setFilters} />
          <MonthYearFilter monthYear={monthYear} setMonthYear={setMonthYear}/>
          <FilterMenu filters={filters} setFilters={setFilters}/>
        </div>
      </div>
      <div className="flex justify-end items-center py-2">
        <CompanioButton size="small" variant="contained" className="w-60 h-[40px]" onClick={() => console.log("need to be handled")}><FormattedMessage id="upload"/> <FormattedMessage id="bank_statement"/></CompanioButton>
      </div>


      <TableContainer sx={{height: "auto", maxHeight: "70vh"}}>
        <Table
          sx={{minWidth: 750}}
          aria-labelledby="tableTitle"
          size='medium'
        >
          <TableHead className="p-4">
            <TableRow>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4] pl-4"><FormattedMessage id="description" defaultMessage="Description"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="type" defaultMessage="Type"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="date" defaultMessage="Date"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="matched" defaultMessage="Matched"/></div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]"><FormattedMessage id="amount" defaultMessage="Amount"/></div></TableCell>
              <TableCell align="left" ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankMovementsData.transactions.map((transaction: any, i: any) => transactionRow(transaction, i))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination className="mt-4"
                  requestPaginationData={bankMovementsData}
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setPage={setPage}
      />

    </div>
  </div>
};

const transactionRow = (transaction: any, i: any) => {
  return <TableRow
    hover
    role="checkbox"
    tabIndex={-1}
    key={i}
  >
    <TableCell
      component="th"
      scope="row"
      sx={{paddingLeft:4, paddingRight: 4}}
    >
      <div className="text-sm text-left font-bold text-[#253292]">
        {transaction.description}
      </div>
      <div className="text-xs font-LaNord text-[#C5C7CD] pt-1">
        {transaction && transaction.account && transaction.account.description ? transaction.account.description + ' | ' : ''}
        {transaction.recipient || 'unknown'}
      </div>

    </TableCell>

    <TableCell align="left">
      <BadgeForTransaction transaction={transaction}/>
    </TableCell>
    <TableCell align="left">
      <div className="text-sm text-left font-bold text-[#253292]">
        {new Date(transaction.date || transaction.created).toLocaleString('en', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })}
      </div>
    </TableCell>

    <TableCell align="right">
      <div className="text-sm text-left font-bold text-[#FFFFF]">
        {transaction.matching && transaction.matching.matching_type ?
          <span className={"bg-[#00DA8B] flex items-center uppercase h-6 border rounded-lg laNord justify-center p-1"}
                style={{fontSize: '10px', color: "#FFFFFF"}}>
            <FormattedMessage id="matched"/>
            <a href={"./transaction_details.html?transaction_id=" + transaction._id}>
              <FormattedMessage id={transaction.matching.matching_type} />
            </a>
          </span> :
          <span className={"bg-[#FE2142] flex items-center uppercase h-6 border rounded-lg laNord justify-center p-1"}
                style={{fontSize: '10px', color: "#FFFFFF"}}>
            <FormattedMessage id="no" defaultMessage="No"/>
          </span>
        }
      </div>
    </TableCell>

    <TableCell align="left">
      <div className="text-sm text-left font-bold text-[#253292]">
        {parseFloat(transaction.net.value).toFixed(2) + ' ' + (transaction.net.currency || 'EUR')}<br/>
      </div>
      <div className="text-xs font-LaNord text-[#C5C7CD] pt-1">
        <FormattedMessage
          id="gross"/> {parseFloat(transaction.amount.value).toFixed(2) + ' ' + (transaction.amount.currency || 'EUR')},
        <FormattedMessage
          id="bank_fee"/> {transaction.fees && transaction.fees.value ? parseFloat(transaction.fees.value).toFixed(2) + ' ' + (transaction.fees.currency || 'EUR') : '0.00 EUR'}
      </div>
    </TableCell>

    <TableCell align="right">
      <Menu sx={{}}>
        <div><FormattedMessage id="see_details"/></div>
      </Menu>
    </TableCell>
  </TableRow>
}

const FilterMenu = ({filters, setFilters}: {filters: any, setFilters: React.FunctionComponent}) => {
  const intl = useIntl();
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
  const open = Boolean(filtersAnchorEl);
  const handleClick = (event: any) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setFiltersAnchorEl(null);
  };

  const handleChange = (group: string, value: any) => {
    const isActive = filters[group].includes(value)
    const updatedFiltersGroup = isActive ? filters[group].filter((i: any) => i !== value) : filters[group].concat(value)
    const updatedFilters = {
      ...filters,
      [group]: updatedFiltersGroup
    }
    setFilters(updatedFilters)
  }

  return <>
    <button className="flex bg-[#F7F8FC] text-sm font-LaNord font-semibold text-[#4B506D] rounded-lg items-center ml-4 h-[40px] px-4" aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
    >
      <div className="mr-2">
        <img src="/assets/images/logos/Icon_filter.svg"
             alt="logo"
             width="16"
        />
      </div>
      <FormattedMessage id="filters"/>
    </button>
    <MUIMenu
      id="basic-menu"
      anchorEl={filtersAnchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter4"
                    checked={filters.matching.includes('matched')}
                    onChange={() => handleChange('matching', 'matched')}/>}
                          label={intl.formatMessage({id: 'matched'})}/>
      </FormGroup>

      <FormGroup className="px-4">
        <FormControlLabel control={
          <Checkbox name="filter5"
                    checked={filters.matching.includes('unmatched')}
                    onChange={() => handleChange('matching', 'unmatched')}/>}
                          label={intl.formatMessage({id: 'unmatched'})}/>
      </FormGroup>
    </MUIMenu>
  </>
}

export default withErrorBoundary(
  BankMovements,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })
