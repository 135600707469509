import React from "react";
import {Pagination as MUIPagination, TablePagination as TablePagination} from '@mui/material'
import {withErrorBoundary} from 'react-error-boundary'


interface typeOfProps {
  className: string,
  requestPaginationData: any,
  limit: number,
  setLimit: any,
  page: number,
  setPage: any
}
const Pagination = ({className, requestPaginationData, limit, setLimit, page, setPage}: typeOfProps) => {
  const pagination = {
    totalDocs: requestPaginationData.totalDocs,
    limit: requestPaginationData.limit,
    page: requestPaginationData.page,
    totalPages: requestPaginationData.totalPages,
    hasNextPage: requestPaginationData.hasNextPage,
    nextPage: requestPaginationData.nextPage,
    hasPrevPage: requestPaginationData.hasPrevPage,
    prevPage: requestPaginationData.prevPage
  }
  const initial = ((pagination.page - 1) * pagination.limit) + 1
  const final = ((pagination.page - 1) * pagination.limit) + pagination.limit
  const paginationText = initial + ' - ' + Math.min(final, pagination.totalDocs) + ' of ' + pagination.totalDocs

  return <div className={className + ' flex justify-end w-100'}>
    {/*<div>*/}
    {/*  {paginationText}*/}
    {/*  <span onClick={() => setLimit(10)} className={getClass(limit, 10)}>10</span>*/}
    {/*  <span onClick={() => setLimit(50)} className={getClass(limit, 50)}>50</span>*/}
    {/*  <span onClick={() => setLimit(100)} className={getClass(limit, 100)}>100</span>*/}
    {/*  <span onClick={() => setLimit(500)} className={getClass(limit, 500)}>500</span>*/}
    {/*</div>*/}
    <TablePagination
        component="div"
        count={pagination.totalPages}
        page={requestPaginationData.prevPage}
        onPageChange={(e, page) => setPage(page)}
        rowsPerPage={requestPaginationData.limit}
        onRowsPerPageChange={requestPaginationData.limit}
    />
    {/*<MUIPagination count={pagination.totalPages} variant="outlined" color="primary" onChange={(e, page) => setPage(page)}/>*/}
  </div>
}


const getClass = (currentLimit: number, classLimit: number) => {
  const defaultClasses = 'hover:underline cursor-pointer mr-1'
  const currentClass = currentLimit === classLimit ? 'text-blue-600' : 'text-gray-600'
  return defaultClasses + ' ' + currentClass
}

export default withErrorBoundary(
  Pagination,
  {
    FallbackComponent: () => <div>Menu failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })