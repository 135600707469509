import {useEffect, useState} from "react";
import {withErrorBoundary} from "react-error-boundary";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import {Box} from "@mui/material";
import * as React from "react";

type SearchFilterProps = {
  id?: string;
  filters: React.ComponentState;
  setFilters: React.ComponentState;
}

const SearchFilter = ({filters, setFilters}: SearchFilterProps) => {
  const [query, setQuery]: React.ComponentState = useState(filters.search);

  const updateFilter: Function = () => {
    const updatedFilters = {
      ...filters,
      'search': query
    }
    setFilters(updatedFilters)
  }

  //delay between keystrokes so it doesn't query on every hit
  useEffect(() => {
    const timeOutId = setTimeout(() => updateFilter(query), 700);
    return () => clearTimeout(timeOutId);
  },[query]);

  return <Box sx={{ display: 'flex', alignItems: 'flex-end'}}>
    {/*<SearchRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} />*/}
    {/*<TextField*/}
    {/*  id="input-with-sx"*/}
    {/*  label="Search"*/}
    {/*  variant="standard"*/}
    {/*  onChange={e => setQuery(e.target.value)}*/}
    {/*  value={query}*/}
    {/*  className="border-none"*/}
    {/*/>*/}
    <Paper
        component="form"
        sx={{ m: '0 8px 0 8px',p: '4px 8px', display: 'flex', alignItems: 'center', width: 214, borderRadius: 2, boxShadow: 0, backgroundColor: '#FCFDFE', border: '1px solid #F0F1F7' }}
    >
      <IconButton type="button" sx={{ p: '4px 8px'}} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          onChange={e => setQuery(e.target.value)}
          value={query}
      />
    </Paper>
  </Box>

}

export default withErrorBoundary(
  SearchFilter,
  {
    FallbackComponent: () => <div>Search failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })
