import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {clearState, getSales, salesSelector} from './SalesSlice';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {withErrorBoundary} from 'react-error-boundary';
import CompanioButton from "../../common/components/CompanioButton"
// import {FilterMenu} from "../../common/components/FilterMenu";

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Container,
  Box,
  TableHead
} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import BadgeForDocumentStatus from "../../common/components/BadgeForDocuments";
import Pagination from "../../common/components/Pagination";
import MonthYearFilter from "../../common/components/MonthYearFilter";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import SearchFilter from "../../common/components/SearchFilter";
import {FilterMenu} from "../../common/components/FilterMenu";
import Menu from "../../common/components/Menu";
import {DropArea} from "../../common/components/DropArea";
import {readFileAsBase64} from "../../common/utils/utils";
import {uploadDocument} from "../Sales/SalesSlice";

const Sales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    status: ['processed', 'processing', 'uploaded'],
    matching: ['matched', 'unmatched'],
    search: ''
  });

  const intl = useIntl();
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
  const open = Boolean(filtersAnchorEl);
  const handleClick = (event: any) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setFiltersAnchorEl(null);
  };

  const [monthYear, setMonthYear] = useState({
    month: localStorage.getItem('teee_selected_month') || new Date().getMonth() + 1,
    year: localStorage.getItem('teee_selected_year') || new Date().getFullYear()
  })

  const [dragActive, setDragActive] = useState(false);


  const {isError, errorMessage, salesData} = useSelector(salesSelector)

  useEffect(()=>{
    console.log('SalesData------>>>>', salesData)
  },[salesData])

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, isError, errorMessage, navigate]);


  useEffect(() => {
    dispatch(getSales({
      page: page,
      limit: limit,
      year: monthYear.year,
      month: monthYear.month,
      filters: filters
    }) as any);
  }, [dispatch, limit, page, filters, monthYear]);

  const handleDrag = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if(event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false)
    }
  }

  const handleFilterChange = (group: any, value: any) => {
    // @ts-ignore
    const isActive = filters[group].includes(value)
    // @ts-ignore
    const updatedFiltersGroup = isActive ? filters[group].filter((i: any) => i !== value) : filters[group].concat(value)
    const updatedFilters = {
      ...filters,
      [group]: updatedFiltersGroup
    }
    setFilters(updatedFilters)
  }

  const handleDrop = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false)
    if(event.dataTransfer.files && event.dataTransfer.files[0]){
      const files: any[] = event.dataTransfer.files
      for (const file of files) {
        const filedata = await readFileAsBase64(file)
        dispatch(uploadDocument({
          type: "sales_invoice",
          filename: file.name,
          filedata: filedata
        }))
        console.log('need to be handle' + filedata)
      }
    }
  }

  const handleDropAreaChange = async (event: any) => {
    event.preventDefault();
    if(event.target.files && event.target.files[0]) {
      const files: any[] = event.target.files
      for (const file of files) {
        const filedata = await readFileAsBase64(file)
        dispatch(uploadDocument({
          type: "sales_invoice",
          filename: file.name,
          filedata: filedata
        }))
        console.log("need to be handle" + filedata)
      }
    }
  }

  if (!salesData) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between mb-5">
      <p className="text-2xl text-left font-bold text-blue-800"><FormattedMessage id="sales"/></p>
      <LoggedInUserMenu />
    </div>
    <div className="border rounded-lg mb-6">
      <div className="flex justify-between items-center px-6 py-4">
        <p className="text-lg font-LaNord font-bold text-[#252733]">Sales invoices</p>
        <div className="flex justify-items-center items-center">
          <SearchFilter filters={filters} setFilters={setFilters} />
          {/*<CompanioButton size="small" variant="contained" className="w-32 h-[40px]" onClick={() => navigate('/sales/create-sales')}>Create</CompanioButton>*/}
          <CompanioButton size="small" variant="contained" className="w-32 h-[40px]" onClick={() => navigate('/sales/upload-invoice')}>Upload</CompanioButton>
          <MonthYearFilter monthYear={monthYear} setMonthYear={setMonthYear}/>
          <FilterMenu filters={filters}
                      className="mr-2-2"
                      intl={intl}
                      onClick={open ? () => {handleClose()} : (e: React.MouseEvent) => {handleClick(e)}}
                      open={open}
                      filtersAnchorEl={filtersAnchorEl}
                      handleChange={handleFilterChange}/>
        </div>

      </div>

      <TableContainer sx={{height: "350px"}}>
        <Table
          sx={{minWidth: 550, width: "100%"}}
          aria-labelledby="tableTitle"
          size='medium'
        >
          <TableHead className="p-4">
            <TableRow>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4] pl-4">Document name</div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]">Date</div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]">Matched</div></TableCell>
              <TableCell align="left" ><div className="font-bold text-[#9FA2B4]">Category</div></TableCell>
              <TableCell align="left" ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {salesData.docs.map((doc: any, i: any) => <TableRow
                // hover
                role="checkbox"
                tabIndex={-1}
                key={i}
            >
              <TableCell
                  component="th"
                  scope="col"
                  sx={{paddingLeft:4, paddingRight: 4}}
              >
                <div className="flex items-center">
                  <BadgeForDocumentStatus className="mr-4" status={doc.status}/>
                  <div>
                    <div className="text-sm text-left font-bold text-[#253292]">
                      {doc.name.replace(new RegExp('sales_invoice_\\d+_', 'i'), "")}
                    </div>
                    <div className="text-xs font-LaNord text-[#C5C7CD] pt-1">
                      <FormattedMessage id={doc.status} defaultMessage={doc.status}/>
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell align="left" ><div className="text-sm text-left font-bold text-[#253292]">{doc.created}</div></TableCell>
              <TableCell align="left">
                {doc.matching.length === 0 ? <span className="bg-[#FE2142] rounded-full text-xs font-LaNord font-bold text-white px-3 py-1">No</span> : <span className="bg-[#00DA8B] rounded-full text-xs font-LaNord font-bold text-white px-3 py-1">Yes</span>}
              </TableCell>
              <TableCell align="left">
                <div className="flex flex-col w-[135px] text-center">
                  <span className="bg-[#F0F1F7] rounded-lg text-xs font-LaNord font-bold text-[#9FA2B4] py-1">SEE TRANSACTION</span>
                  <span className="bg-[#F0F1F7] rounded-lg text-xs font-LaNord font-bold text-[#9FA2B4] py-1 mt-4">OTHER DOCUMENT</span>
                </div>
              </TableCell>
              <TableCell align="left">
                <Menu sx={{}}>
                  <div><FormattedMessage id="see_details"/></div>
                </Menu>
              </TableCell>
            </TableRow>)}

          </TableBody>
        </Table>
      </TableContainer>
      <div>

        <Pagination className="mt-4"
                    requestPaginationData={salesData}
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    setPage={setPage}
        />
      </div>
    </div>
    <div className="border rounded-lg max-h-[32rem] mb-8">
      <div className="flex justify-between items-center pt-8 px-8">
        <p className="text-left font-bold"><FormattedMessage id="upload_invoice"/></p>
        <CompanioButton size="small" variant="contained" className="h-[40px]" onClick={() => navigate('/sales/create-sales')}>New Invoice</CompanioButton>
      </div>
      <DropArea text="drag_purchase_invoices"
                dragActive={dragActive}
                handleDrop={handleDrop}
                handleDrag={handleDrag}
                handleChange={handleDropAreaChange}

      />
    </div>
  </div>
};

// const FilterMenu = ({filters, setFilters}: any) => {
//   const intl = useIntl();
//   const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
//   const open = Boolean(filtersAnchorEl);
//   const handleClick = (event: any) => {
//     setFiltersAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setFiltersAnchorEl(null);
//   };
//
//   const handleChange = (group: any, value: any) => {
//     const isActive = filters[group].includes(value)
//     const updatedFiltersGroup = isActive ? filters[group].filter((i: any) => i !== value) : filters[group].concat(value)
//     const updatedFilters = {
//       ...filters,
//       [group]: updatedFiltersGroup
//     }
//     setFilters(updatedFilters)
//   }
//
//   return <>
//     <Button
//       id="basic-button"
//       aria-controls={open ? 'basic-menu' : undefined}
//       aria-haspopup="true"
//       aria-expanded={open ? 'true' : undefined}
//       onClick={handleClick}
//     >
//       <FormattedMessage id="filters"/>
//     </Button>
//     <Menu
//       id="basic-menu"
//       anchorEl={filtersAnchorEl}
//       open={open}
//       onClose={handleClose}
//       MenuListProps={{
//         'aria-labelledby': 'basic-button',
//       }}
//     >
//       <FormGroup className="px-4">
//         <FormControlLabel control={
//           <Checkbox name="filter1"
//                     checked={filters.status.includes('uploaded')}
//                     onChange={() => handleChange('status', 'uploaded')}/>}
//                           label={intl.formatMessage({id: 'uploaded'})}/>
//       </FormGroup>
//
//       <FormGroup className="px-4">
//         <FormControlLabel control={
//           <Checkbox name="filter2"
//                     checked={filters.status.includes('processing')}
//                     onChange={() => handleChange('status', 'processing')}/>}
//                           label={intl.formatMessage({id: 'processing'})}/>
//       </FormGroup>
//
//       <FormGroup className="px-4">
//         <FormControlLabel control={
//           <Checkbox name="filter3"
//                     checked={filters.status.includes('processed')}
//                     onChange={() => handleChange('status', 'processed')}/>}
//                           label={intl.formatMessage({id: 'processed'})}/>
//       </FormGroup>
//
//       <FormGroup className="px-4">
//         <FormControlLabel control={
//           <Checkbox name="filter4"
//                     checked={filters.matching.includes('matched')}
//                     onChange={() => handleChange('matching', 'matched')}/>}
//                           label={intl.formatMessage({id: 'matched'})}/>
//       </FormGroup>
//
//       <FormGroup className="px-4">
//         <FormControlLabel control={
//           <Checkbox name="filter5"
//                     checked={filters.matching.includes('unmatched')}
//                     onChange={() => handleChange('matching', 'unmatched')}/>}
//                           label={intl.formatMessage({id: 'unmatched'})}/>
//       </FormGroup>
//     </Menu>
//   </>
// }

export default withErrorBoundary(
  Sales,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
