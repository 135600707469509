import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

export const getTickets: any = createAsyncThunk<any, any, any>(
  'tickets/getTickets',
  async ({ page, limit, year, month, filters }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/tickets?page=' + page +
        '&limit=' + limit +
        '&filters=' + encodeURIComponent(JSON.stringify(filters)),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTicket: any = createAsyncThunk<any, any, any>(
  'tickets/getTicket',
  async (ticketId, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/tickets/'+ticketId,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addMessage: any = createAsyncThunk<any, any, any>(
  'tickets/addMessage',
  async ({ticketId, ticketData}, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/tickets/'+ticketId+'/messages',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(ticketData)
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    selectedTicket: null,
    ticketsData: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [getTickets.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getTickets.pending]: (state) => {
      state.isFetching = true;
    },
    [getTickets.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.ticketsData = payload
    },
    [getTicket.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getTicket.pending]: (state) => {
      state.isFetching = true;
    },
    [getTicket.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.selectedTicket = payload.ticket
    },
    [addMessage.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [addMessage.pending]: (state) => {
      state.isFetching = true;
    },
    [addMessage.fulfilled]: (state: any, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.selectedTicket = payload.ticket
      if(state.ticketsData.tickets.find((i: any) => i._id === payload.ticket._id)){
        state.ticketsData.tickets = state.ticketsData.tickets.map((i: any) => {
          if (i._id === payload.ticket._id){
            return payload.ticket
          }
          return i
        })
      }
    },
  },
});

export const { clearState } = ticketsSlice.actions;

export const ticketsSelector = (state: any) => state.tickets;
