import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Cookies from "js-cookie";

export const getBilling: any = createAsyncThunk(
  'billing/getBilling',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/customers/payment_methods',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTransactions: any = createAsyncThunk<any,any, any>(
  'billing/getTransactions',
  async ({limit, page} , thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/payments/paginated?page=' + page + '&limit=' + limit,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',

          },
        }
      )
      let data = await response.json();
      console.log(data)
      if(response.status === 200) {
        return data;
      }
      else {
      return thunkAPI.rejectWithValue(data);
    }

    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const setAsPrimaryPaymentMethod: any = createAsyncThunk<any, any, any>(
  'billing/setPrimary',
  async (id, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/customers/set_primary_payment_method/'+id,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return id;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const retryFailedPayment: any = createAsyncThunk<any, any, any>(
  'billing/retryFailedPayment',
  async(id, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/payments/'+id+'/retry' ,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return id;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const uploadProofOfPayment: any = createAsyncThunk<any, any, any>(
  'billing/uploadProofOfPayment',
  async({}, thunkAPI) => {

  }
)

export const deletePaymentMethod: any = createAsyncThunk<any, any, any>(
  'billing/deleteMethod',
  async (id, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/customers/payment_method/'+id,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return id;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    billingData: null,
    transactionsData: {
      totalPages: 1,
      totalDocs: 1,
      limit: 10,
      page: 1,
      pagingCounter: 1,
      payments: [{description: 'none', created: 'none', amount: 'none', status: 'none', customer: {name: 'not found'}}]
    },
    emailSent: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state: any) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [getBilling.rejected]: (state, {payload}) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getBilling.pending]: (state) => {
      state.isFetching = true;
    },
    [getBilling.fulfilled]: (state, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.billingData = payload
    },
    [getTransactions.rejected]: (state, {payload}) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getTransactions.pending]: (state) => {
      state.isFetching = true;
    },
    [getTransactions.fulfilled]: (state, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.transactionsData = payload
    },
    [setAsPrimaryPaymentMethod.rejected]: (state, {payload}) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [setAsPrimaryPaymentMethod.pending]: (state) => {
      state.isFetching = true;
    },
    [setAsPrimaryPaymentMethod.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.billingData = {
        ...state.billingData,
        stripe_customer: {
          ...state.billingData.stripe_customer,
          invoice_settings: {
            ...state.billingData.stripe_customer.invoice_settings,
            default_payment_method: payload
          }
      }
      }
    },
    [retryFailedPayment.rejected]: (state, {payload}) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [retryFailedPayment.pending]: (state) => {
      state.isFetching = true;
    },
    [retryFailedPayment.fulfilled]: (state: any) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [deletePaymentMethod.rejected]: (state, {payload}) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [deletePaymentMethod.pending]: (state) => {
      state.isFetching = true;
    },
    [deletePaymentMethod.fulfilled]: (state: any, {payload}) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.billingData = {
        ...state.billingData,
        payment_methods: state.billingData.payment_methods.filter((i: any) => i.id !== payload)
      }
    },
  },
});

export const {clearState} = billingSlice.actions;

export const billingSelector = (state: any) => state.billing;
