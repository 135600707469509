import {withErrorBoundary} from "react-error-boundary";
import {useIntl} from "react-intl";
import {Box, TextField} from "@mui/material";
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  clearState,
  userSelector,
  updateCompanyData,
  updateCompanyAvatar
} from '../User/UserSlice';
import toast from "react-hot-toast";
import {readFileAsBase64} from "../../common/utils/utils";
import {findDOMNode} from "react-dom";
import CompanioButton from "../../common/components/CompanioButton";
import DefaultInput from "../../common/components/DefaultInput";

const Profile = () => {

  const dispatch = useDispatch()
  const { isSuccess, isError, isFetching, user} = useSelector(userSelector)
  const intl = useIntl()
  const avatarRef: React.Ref<any> = useRef(null);

  const company = user.company
  const avatarImageFulfilled = company.avatar ? process.env.REACT_APP_API_URL + '/' + company.avatar : "/assets/images/default-company.png"

  const initialCompanyState = {
    name: company.name,
    email: company.email,
    registry_number: company.registry_number,
    products: company.moss.products.join(", ") || "",
    phone: company.phone,
    address: company.address || "",
    description: company.description || "",
    avatar: avatarImageFulfilled
  }

  const [companyData, setCompanyData] = useState<any>(initialCompanyState)

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(intl.formatMessage({id: 'error_modifying_user'}));
    }
    if (isSuccess) {
      toast.success(intl.formatMessage({id: 'data_successfully_modified'}));
    }
    dispatch(clearState());
  }, [dispatch, isError, isSuccess, intl])

  const handleInputChange = (e: any) => {
    setCompanyData({
      ...companyData,
      [e.target.id]: e.target.value
    })
  }
  const fileSelectedHandler = async (event: any) => {
    const base64File = await readFileAsBase64(event.target.files[0])
    if(company._id){
      const avatarInfo = {
        avatar: base64File
      }
      dispatch(updateCompanyAvatar(avatarInfo) as any)
    }
    else{
      setCompanyData({
        ...companyData,
        'avatar': base64File
      })
    }
    (findDOMNode(avatarRef.current) as any).setAttribute('src', base64File)
  }

  const onSaveCompanyDataClicked = () => {
    dispatch(updateCompanyData(companyData) as any)
  }

  return <>
    <div className="flex flex-col w-full border rounded-lg my-6">

      <p className="text-2xl text-left px-4 font-bold text-blue-800">Edit Company</p>

      <Box
        component="form"
        sx={{
          width: '100%',
          height: 'auto',
          minWidth: 'auto'
        }}
        noValidate
        autoComplete="off"
      >
        <div className="flex flex-wrap pl-8 pb-4">
          <DefaultInput
            value={companyData.name}
            onChange={handleInputChange}
            id="company_name"
            label="Company Name"
            style={{ margin: "2%"}}
            className="w-full md:w-5/12"
          />

          <DefaultInput
            value={companyData.email}
            onChange={handleInputChange}
            id="email"
            label="email"
            style={{ margin: "2%"}}
            className="w-full md:w-5/12"
          />
          <DefaultInput
            value={companyData.registry_number}
            onChange={handleInputChange}
            id="registry_number"
            label="Registry Number"
            style={{ margin: "2%"}}
            className="w-full md:w-5/12"
          />
          <DefaultInput
            value={companyData.products}
            onChange={handleInputChange}
            id="products"
            label="MOSS"
            style={{ margin: "2%"}}
            className="w-full md:w-5/12"
          />
          <DefaultInput
            value={companyData.phone}
            onChange={handleInputChange}
            id="phone"
            label="Phone Number"
            style={{ margin: "2%"}}
            className="w-full md:w-5/12"
          />
          <DefaultInput
            value={companyData.address}
            onChange={handleInputChange}
            id="address"
            label="Address"
            style={{ margin: "2%"}}
            className="w-full md:w-5/12"
          />
          <DefaultInput
            id="description"
            label="Activity"
            onChange={handleInputChange}
            value={companyData.description}
            style={{width: '87%', marginLeft: "2%", marginTop:"1%", marginRight:"2%"}}
          />
        </div>
        <div className="flex justify-end mb-5" style={{marginRight: '3%'}}>
          <CompanioButton
            disabled={isFetching}
            size="medium"
            onClick={() => setCompanyData(initialCompanyState)}
            variant="outlined">Discard changes</CompanioButton>
          <CompanioButton
            disabled={isFetching}
            size="medium"
            onClick={onSaveCompanyDataClicked}
            variant="contained">Save</CompanioButton>
        </div>
      </Box>
      <Box
        className="border border-x-0 flex"
        component="form"
        sx={{
          width: 'auto',
          height: 'auto',
          minWidth: 'auto',
        }}
        noValidate
        autoComplete="off"
      >
        <div className="flex flex-col md:flex-row w-full justify-between">
          <div className="flex flex-col md:flex-row justify-center md:justify-end items-center">
            <img alt="avatar"
                 style={{width: 44, height: 44, borderRadius: "50%", margin: "20px"}}
                 src={companyData.avatar}
            />
            <p style={{
              fontSize: "12px",
              color: "#4B506D",
              fontStyle: "normal",
              lineHeight: "16px"
            }}
               className="LaNord"
            >
              Photo will be shown to your users when you send them messages or reply to them. Max Photo size 1 MB.
            </p>
          </div>

          <label htmlFor="contained-button-file">
            <input style={{display: "none"}} onChange={fileSelectedHandler} accept="image/*" id="contained-button-file"
                   ref={avatarRef}
                   multiple type="file"/>
            <div className="flex flex-col md:flex-row m-6">
              <CompanioButton variant="outlined" onClick={() => avatarRef.current.click()}>
                Upload new picture
              </CompanioButton>
            </div>

          </label>
        </div>
      </Box>
    </div>
  </>
}

export default withErrorBoundary(
  Profile,
  {
    FallbackComponent: () => <div>Setting to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })