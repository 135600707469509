import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

export const loadShipmentsOfType: any = createAsyncThunk<any, any, any>(
  'travels/loadShipmentsOfType',
  async ({type, page, limit, filters }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/' + type + '/paginated?page=' + page +
        '&limit=' + limit +
        '&filters=' + encodeURIComponent(JSON.stringify(filters)),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const virtualOfficeSlice = createSlice({
  name: "virtualOffice",
  initialState: {
    virtualOfficeData: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [loadShipmentsOfType.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [loadShipmentsOfType.pending]: (state) => {
      state.isFetching = true;
    },
    [loadShipmentsOfType.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.virtualOfficeData = payload
    },
  },
});

export const { clearState } = virtualOfficeSlice.actions;

export const virtualOfficeSelector = (state: any) => state.virtualOffice;
