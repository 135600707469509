
import {useNavigate} from 'react-router-dom';
import {withErrorBoundary} from 'react-error-boundary';
import {
  Button,
  Typography,
  Paper, Container, Box
} from "@mui/material";
import {FormattedMessage} from "react-intl";
import CheckMarkIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useEffect} from "react";
import {getMe, userSelector} from "../User/UserSlice";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const RegisterSteps = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMe() as any);
  }, [dispatch]);

  const {user} = useSelector(userSelector)

  if (!user) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  const status = user.company.status
  if(!status || status === 'pending_company_payment'){
    navigate('/register')
  }
  if(status === 'ready'){
    navigate('/')
  }
  if(status === 'pending_bank_account'){
    navigate('/register/bank_account')
  }

  return <div className="flex min-h-screen">
    <div className="flex-1 flex flex-col justify-between" style={{padding: '40px'}}>
      <img src="/assets/images/companio-logo.png" alt="logo" width="149" style={{maxHeight: "40px"}}/>

      <div className="text-left">
        <Typography><FormattedMessage id="application_process"/></Typography>

        <Paper className="flex justify-between p-4 mb-3">
          <Typography>1. <FormattedMessage id="business_information" /></Typography>
          <CheckMarkIcon color='success'/>
        </Paper>

        <Paper className="flex justify-between p-4 mb-3">
          <Typography>2. <FormattedMessage id="company_status_pending_documents" /></Typography>
          {step3Icon(user, navigate)}
        </Paper>

        <Paper className="flex justify-between p-4 mb-3">
          <Typography>3. <FormattedMessage id="business_registration" /></Typography>
          <AccessTimeIcon color='warning'/>
        </Paper>

        <Paper className="flex justify-between p-4 mb-3">
          <Typography>4. <FormattedMessage id="connect_bank_accounts_title" /></Typography>
          <AccessTimeIcon color='warning'/>
        </Paper>

        <Button variant="contained" onClick={() => navigate('/register/status')}><FormattedMessage id="lets_go" /></Button>
      </div>

      <div></div>
      {/*has to be here because of flex */}

    </div>

    <div className="flex items-center justify-center"
         style={{
           background: 'linear-gradient(109.4deg, #9A53CB 1.26%, #FF4EA1 96.4%)',
           padding: '40px',
           width: '350px'
         }}>
      <img src="/assets/images/login-people.png" alt="people"/>
    </div>
  </div>
};

const step3Icon = (user: any, navigate: any) => {
  const failedRequisites = user.company.business_information.filter((r: any) => r.hasOwnProperty('status') && r.status === 'rejected')

  if(user.company.status === 'pending_documents' && failedRequisites.length){
    return <Button variant="contained" color="error"  onClick={() => navigate('/register/verify_documents')}><FormattedMessage id="action_required" /></Button>
  }
  if(user.company.status === 'pending_documents' && !failedRequisites.length){
    return <Button variant="contained"  onClick={() => navigate('/register/verify_documents')}><FormattedMessage id="see_details" /></Button>
  }
  if(user.company.status === 'pending_company'){
    return <CheckMarkIcon color='success'/>
  }
}

export default withErrorBoundary(
  RegisterSteps,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
