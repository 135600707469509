import React, {useEffect, useState} from "react"
import {useSelector, useDispatch} from "react-redux"
import {
  clearState,
  getBilling,
  billingSelector,
  setAsPrimaryPaymentMethod,
  deletePaymentMethod,
  getTransactions,
  retryFailedPayment
} from './BillingSlice'
import toast from 'react-hot-toast';
import {FormattedMessage} from 'react-intl';

import {
  Container,
  Box,
  Button, Divider,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from "@mui/material/CircularProgress";
import Typography from '@mui/material/Typography';
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import Menu from "../../common/components/Menu";
import LoggedInUserMenu from "../../common/components/LoggedInUserMenu";
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone';
import {withErrorBoundary} from "react-error-boundary";
import Pagination from "../../common/components/Pagination";

const Billing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const {isSuccess, isError, errorMessage, billingData, transactionsData} = useSelector(billingSelector)

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage)
      dispatch(clearState())
    }
  }, [dispatch, isError, errorMessage])

  useEffect(() => {
    dispatch(getBilling() as any)
  }, [dispatch])

  useEffect(() => {
    dispatch(getTransactions({limit, page}) as any)
  }, [dispatch, limit, page, isSuccess])


  const setAsPrimaryPaymentMethodById = (id: string) => {
    dispatch(setAsPrimaryPaymentMethod(id) as any)
  }

  const deleteMethodById = (id: any) => {
    dispatch(deletePaymentMethod(id) as any)
  }

  const retryFailedPaymentById = (id: string) => {
    dispatch(retryFailedPayment(id) as any)
  }

  const downloadInvoiceById = (id: string) => {
    var url = process.env.REACT_APP_API_URL + '/invoices/' + id
    url += "?Authorization=" + encodeURIComponent(JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token);
    window.open(url, "_blank");
  }

  const actionsForPayment = (transaction: any) => {
    const trId = transaction._id

    switch (transaction.status) {
      case 'open':
      case 'pending':
        return (
          <Menu sx={{}}>
            <div onClick={() => navigate('/tickets')}>Contact us</div>
          </Menu>
        )
        break;
      case 'failed':
      case 'canceled':
      case 'expired':
        return (
          <Menu sx={{}}>
            <div onClick={() => retryFailedPaymentById(trId)}>Retry payment</div>
            <div onClick={() => console.log("here")}>Upload proof of payment</div>
            <div onClick={() => navigate('/tickets')}>Contact us</div>
          </Menu>
        )
        break;
      case 'paid':
      case 'succeeded':
        if (Number(transaction.amount) && parseFloat(transaction.amount) >= 0) {
          return (
            <Menu sx={{}}>
              <div onClick={() => downloadInvoiceById(trId)}>Download invoice</div>
            </Menu>
          )
        }
        break;
      default:
        break;
    }
  }

  const setStatusColor = (status: string) => {
    switch (status) {
      case 'succeeded':
      case 'paid':
        return 'text-white bg-green-500'
      case 'failed':
      case 'canceled':
      case 'expired':
      case 'requires_payment_method':
        return 'text-white bg-red-500'
      case 'requires_action':
        return 'text-gray bg-slate-300'
      case 'open':
      case 'processing':
      case 'pending':
        return 'text-white bg-yellow-500'
    }
  }

  const transactionsDataBackground = (index: number) => {
    return index % 2 === 1 ? "#fff" : "#F7F8FC"
  }

  const formatTransactionsDate = (date: string) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const day = new Date(date).getDate()
    const month = new Date(date).getMonth()
    const year = new Date(date).getFullYear()

    return `${months[month]} ${day}, ${year}`
  }

  if (!billingData) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }
  return <div className="flex flex-col w-full px-4">
    <div className="flex justify-between md:mb-12">
      <p className="text-2xl text-left  font-bold text-blue-800"><FormattedMessage id="billing"/></p>
      <div> </div>
      <LoggedInUserMenu />
    </div>
    <div className="flex items-start w-full mb-8">
      <Button variant="contained"
              sx={{color: "#fff", borderRadius: "8px", backgroundColor: "#253292"}}
              onClick={() => window.location.reload()}>
        Billing
      </Button>
      <Button variant="outlined"
              sx={{ml: "16px", color:"#9FA2B4", borderRadius: "8px"}}
              onClick={async () => await navigateToAddPaymentMethodWithSetupIntent(navigate)}>
        Add Card
      </Button>
    </div>

    <div className="flex items-start">
      {billingData.payment_methods.map((pm: any, i: any) => displayPaymentMethod(pm, i, billingData, setAsPrimaryPaymentMethodById, deleteMethodById))}
    </div>

    <div className="border border-solid mt-5 w-full h-80 max-h-80 overflow-y-scroll" style={{borderColor: "#DFE0EB", borderRadius: "8px"}}>
      <div className="text-left pt-4 pb-4 ">
        <div className="flex justify-between">
          <p className="pt-4 pl-9 font-bold">Transaction history</p>
          <Pagination className="my-1 pr-4"
                      requestPaginationData={transactionsData}
                      limit={limit}
                      setLimit={setLimit}
                      page={page}
                      setPage={setPage}/>
        </div>
        <Divider />
        <div className="mb-5" style={{color: "#253292", font: 'LaNord'}}>
            {transactionsData.payments.map((t: any, i: number) => {
              return <div className="flex justify-between border border-t-0 border-x-0 py-2 px-4"
                          style={{borderColor: "#DFE0EB", backgroundColor: transactionsDataBackground(i)}}
                          key={t._id}>
                <div className="w-56 h-12 bold">
                  <p>{t.customer.name}</p>
                  <p style={{color: '#9FA2B4', fontSize:'9px'}}>{t.description}</p>
                </div>
                <span className="w-16 h-12">€{t.amount.includes('.') ? t.amount : (t.amount / 100)}</span>
                <span className={`text-center h-5 uppercase pt-0.5 ${setStatusColor(t.status)}`}
                      style={{borderRadius: '8px', margin: '5px', fontSize: '11px', alignItems:'center', width: '88px'}}>
                  {t.status}
                </span>
                <span className="w-32 h-12 hidden lg:flex">{formatTransactionsDate(t.created)}</span>
                {actionsForPayment(t)}
              </div>
            })}
        </div>
      </div>

    </div>

  </div>
}

const displayPaymentMethod = (pm: any, i: any, billingData: any, setPrimaryMethod: any, deleteMethod: any) => {
  const type = pm.type

  const primaryPaymentId = (billingData.stripe_customer &&
    billingData.stripe_customer.invoice_settings &&
    billingData.stripe_customer.invoice_settings.default_payment_method) || ''

  const isPrimaryMethod = primaryPaymentId === pm.id

  if (type === 'card') {
    return displayCard(pm, i, billingData, isPrimaryMethod, setPrimaryMethod, deleteMethod)
  }

  if (type === 'sepa_debit') {
    return displaySepa(pm, i, billingData, isPrimaryMethod, setPrimaryMethod, deleteMethod)
  }

  return displayOther(pm, i, billingData, isPrimaryMethod, setPrimaryMethod, deleteMethod)
}

const displayCard = (pm: any, i: any, billingData: any, isPrimaryMethod: any, setPrimaryMethod: any, deleteMethod: any) => {
  return <Card key={i} className="inline-flex rounded m-4" sx={{width: '353px', height:'209px', background: 'linear-gradient(239.6deg, #C9B6F2 0%, #9A53CB 44.36%)', borderRadius: '11.2px'}}>
    <CardContent className="w-full m-2">
      <div className="flex justify-between">
        <p style={{ marginBottom: "1px", color:"#fff", textAlign: "left", fontSize: "16px", fontWeight: "bold", textTransform: "uppercase"}}>
          {pm.card.brand} {isPrimaryMethod && '- Primary'}
        </p>
        <Menu sx={{}}>
          <div onClick={() => setPrimaryMethod(pm.id)}>Set as primary</div>
          <div className="text-red" onClick={() => deleteMethod(pm.id)}>Delete</div>
        </Menu>
      </div>
      <div className="mt-2 mb-2 h-8 w-12 bg-amber-500" style={{borderRadius: '5px'}}>
        {}
      </div>
      <Typography sx={{fontSize: 24}} color="#fff" gutterBottom align="left" className="mt-5">
        {'**** **** **** ' + pm.card.last4}
      </Typography>
      <div className="flex justify-end pr-4">
        <div className="flex flex-col text-white uppercase align-start pt-0.5">
          <span style={{fontSize: '7px'}}>valid</span>
          <span style={{fontSize: '7px'}}>thru</span>
        </div>
        <div className="justify-center">
          <ArrowRightTwoToneIcon sx={{margin: 0, color: "#fff", fontSize: '18px'}}/>
        </div>
        <Typography sx={{fontSize: 16}} color="#fff" gutterBottom align="left">
          {pm.card.exp_month + '/' + pm.card.exp_year}
        </Typography>
      </div>
      <Typography sx={{fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase'}} color="#fff" gutterBottom align="left" className="mb-5">
        {billingData.customer.name}
      </Typography>
    </CardContent>
  </Card>
}

const displaySepa = (pm: any, i: any, billingData: any, isPrimaryMethod: any, setPrimaryMethod: any, deleteMethod: any) => {
  return <Card key={i} className="inline-flex rounded m-4" sx={{width: '353px', height:'209px', background: 'linear-gradient(239.6deg, #F4BDE7 0%, #FF4EA1 44.36%)', borderRadius: '11.2px'}}>
    <CardContent className="w-full m-2">
      <div className="flex justify-between">
        <p style={{ marginBottom: "1px", color:"#fff", textAlign: "left", fontSize: "16px", fontWeight: "bold", textTransform: "uppercase"}}>
          Bank card {isPrimaryMethod && '- Primary'}
        </p>
        <Menu sx={{}}>
          <div onClick={() => setPrimaryMethod(pm.id)}>Set as primary</div>
          <div className="text-red" onClick={() => deleteMethod(pm.id)}>Delete</div>
        </Menu>
      </div>
      <div className="mt-2 mb-2 h-8 w-12 bg-amber-500" style={{borderRadius: '5px'}}>
        {}
      </div>
      <Typography sx={{fontSize: 24}} color="#fff" gutterBottom align="left" className="mt-5">
        {'**** **** **** ' + pm.sepa_debit.last4}
      </Typography>
      <Typography sx={{fontSize: 22}} color="#fff" gutterBottom align="left" className="mr-4">
        {}
      </Typography>
      <Typography sx={{fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase'}} color="#fff" gutterBottom align="left" className="mb-5">
        {billingData.customer.name}
      </Typography>
    </CardContent>
  </Card>
}

const displayOther = (pm: any, i: any, billingData: any, isPrimaryMethod: any, setPrimaryMethod: any, deleteMethod: any) => {
  return <Card key={i} className="inline-flex rounded m-4" sx={{width: '400px', height:'220px'}}>
    <CardContent className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 w-full">
      <div className="flex justify-between">
        <Typography sx={{mb:1}} variant="h5" component="div" align="left" color="#fff">
          {pm.type} {isPrimaryMethod && '- Primary'}
        </Typography>
        <Menu sx={{}}>
          <div onClick={() => setPrimaryMethod(pm.id)}>Set as primary</div>
          <div className='tet-red' onClick={() => deleteMethod(pm.id)}>Delete</div>
        </Menu>
      </div>
      <Typography sx={{fontSize: 22}} color="#fff" gutterBottom align="left" className="mt-5">
        {}
      </Typography>
      <Typography sx={{fontSize: 22}} color="#fff" gutterBottom align="left" className="mr-4">
        {pm.billing_details.name}
      </Typography>
      <Typography sx={{fontSize: 14, fontWeight: 'bold'}} color="#fff" gutterBottom align="left" className="mb-5">
        {billingData.customer.name}
      </Typography>
    </CardContent>
  </Card>
}

const navigateToAddPaymentMethodWithSetupIntent = async (navigate: any) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + '/payments/setup_intent_secret',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      }
    );
    let data = await response.json();

    if (response.status === 200 && data.client_secret) {
      navigate('./add_payment_method/' + data.client_secret)
    } else {
      throw new Error('No seti')
    }
  } catch (e) {
    toast.error('Error fetching data for adding card')
  }
}

export default withErrorBoundary(
  Billing,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })

