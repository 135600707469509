import ReactApexChart from 'react-apexcharts';

const CompanioChart = () => {
    const options = {
        chart: {
            height: 350,
            zoom: {
                enabled: true
            },
            fill: {
                colors: ['#00DA8B', '#9A53CB'],
            }
        },

    };

    const series = [
        {
            name: "Today",
            data: [3, 16, 11, 17, 9, 15, 14, 20, 7, 11, 8, 14,]
        },
        {
            name: "Yesterday",
            data: [18, 9, 19, 15, 27, 16, 22, 14, 35, 21, 29, 18]
        }
    ];

    return (
        <ReactApexChart
            type="area"
            options={options}
            series={series}
            height={350}
        />
    );
};

export default CompanioChart