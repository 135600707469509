import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

type BankMovementsT = {
  bankAccountId: string;
  page: number;
  limit: number;
  year: number;
  month: number;
  filters: any
};

export const getBankMovements: any = createAsyncThunk(
  'bankMovements/getBankMovements',
  async ({ bankAccountId, page, limit, year, month, filters }: BankMovementsT, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL+'/bank_accounts/'+bankAccountId+'/transactions/year/' + year +
        '/month/'+month +
        '/paginated?page=' + page +
        '&limit=' + limit +
        '&filters=' + encodeURIComponent(JSON.stringify(filters)),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const bankMovementsSlice = createSlice({
  name: 'bankMovements',
  initialState: {
    bankMovementsData: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [getBankMovements.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [getBankMovements.pending]: (state) => {
      state.isFetching = true;
    },
    [getBankMovements.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.bankMovementsData = payload
    },
  },
});

export const { clearState } = bankMovementsSlice.actions;

export const bankMovementsSelector = (state: any) => state.bankMovements;
