import {withErrorBoundary} from "react-error-boundary";
import {Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, TextField, Typography} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {readFileAsBase64} from "../../../common/utils/utils";
import React from "react";
import {useSelector} from "react-redux";
import {userSelector} from "../../User/UserSlice";

const CountrySpecificFields = ({updateBusinessInfo, handleStep, registrationData}: {updateBusinessInfo: any, handleStep: any, registrationData: any}) => {
  const intl = useIntl()

  const {isFetching} = useSelector(userSelector);

  const fileHandler = async (e: any, type: any) => {
    const base64File = await readFileAsBase64(e.target.files[0])

    updateBusinessInfo({
      id: type,
      type: 'File',
      filename: e.target.files[0].name,
      mime_type: e.target.files[0].type,
      value: base64File
    })
  }

  const ssiNameField = registrationData.business_information.find((i: any) => i.id === 'digital_certificate')
  const freelanceNameField = registrationData.business_information.find((i: any) => i.id === 'freelance_document')
  const freelanceLastTwoYearsField = registrationData.business_information.find((i: any) => i.id === 'freelance_last_two_years')

  return <div>
    <div className="flex justify-between mt-2">
      <Typography><FormattedMessage id="phone"/></Typography>

      <TextField
        id="phone"
        label={intl.formatMessage({id: 'phone'})}
        onChange={(e: any) => updateBusinessInfo({
          id: 'phone',
          type: 'String',
          value: e.target.value
        })}
        required/>
    </div>

    <div className="flex justify-between mt-2">
      <Typography><FormattedMessage id="social_security_number"/></Typography>

      <TextField
        id="social_security_number"
        label={intl.formatMessage({id: 'social_security_number'})}
        onChange={(e: any) => updateBusinessInfo({
          id: 'social_security_number',
          type: 'String',
          value: e.target.value
        })}
        required/>
    </div>

    <div className="flex justify-between">
      <Typography><FormattedMessage id="digital_certificate"/></Typography>

      <label htmlFor="contained-button-file">
        <input style={{display: "none"}}
               onChange={(e) => fileHandler(e, 'digital_certificate')}
               accept="*"
               id="contained-button-file"
               type="file"/>
        <Button variant="contained" component="span">
          {ssiNameField ? ssiNameField.filename : <FormattedMessage id="upload"/>}
        </Button>
      </label>
    </div>

    <div className="flex justify-between mt-2">
      <Typography><FormattedMessage id="digital_certificate_password"/></Typography>

      <TextField
        id="digital_certificate_password"
        label={intl.formatMessage({id: 'digital_certificate_password'})}
        onChange={(e: any) => updateBusinessInfo({
          id: 'digital_certificate_password',
          type: 'Password',
          value: e.target.value
        })}
        required/>
    </div>

    {
      registrationData.status === 'freelancer' && <>
        <div className="flex justify-between">
          <Typography><FormattedMessage id="freelance_document"/></Typography>

          <label htmlFor="contained-button-file2">
            <input style={{display: "none"}}
                   onChange={(e) => fileHandler(e, 'freelance_document')}
                   accept="*"
                   id="contained-button-file2"
                   type="file"/>
            <Button variant="contained" component="span">
              {freelanceNameField ? freelanceNameField.filename : <FormattedMessage id="upload"/>}
            </Button>
          </label>
        </div>

        <div className="flex justify-between mt-2">
          <Typography><FormattedMessage id="vat"/></Typography>

          <TextField
            id="vat"
            label={intl.formatMessage({id: 'vat'})}
            onChange={(e) => updateBusinessInfo({
              id: 'vat',
              type: 'String',
              value: e.target.value
            })}
            required/>
        </div>
      </>
    }

    {
      registrationData.status === 'not_freelancer' && <>
        <div className="flex justify-between mt-2">
          <Typography><FormattedMessage id="iban"/></Typography>

          <TextField
            id="vat"
            label={intl.formatMessage({id: 'iban'})}
            onChange={(e) => updateBusinessInfo({
              id: 'iban',
              type: 'String',
              value: e.target.value
            })}
            required/>
        </div>

        <div className="flex justify-between mt-2">
          <Typography><FormattedMessage id="freelance_last_two_years"/></Typography>

          <FormGroup className="px-4">
            <FormControlLabel control={
              <Checkbox
                name="filter4"
                checked={freelanceLastTwoYearsField.value}
                onChange={(e) => updateBusinessInfo({
                  id: 'freelance_last_two_years',
                  type: 'Boolean',
                  value: !freelanceLastTwoYearsField.value
                })}/>}
                              label={intl.formatMessage({id: 'freelance_last_two_years'})}/>
          </FormGroup>
        </div>
      </>
    }

    <Button
      variant="outlined"
      onClick={() => handleStep(2)}
      sx={{mt: 1, mr: 1}}
    >
      <FormattedMessage id="back"/>
    </Button>

    <Button
      variant="contained"
      onClick={() => handleStep(4)}
      disabled={isFetching}
      sx={{mt: 1, mr: 1}}
    >
      {isFetching ? <CircularProgress/> : <FormattedMessage id="next"/>}
    </Button>

  </div>
}

export default withErrorBoundary(
  CountrySpecificFields,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
