import {Outlet, useNavigate} from 'react-router-dom';
import {
  Container, Box,
  Grid, Divider, List,
  ListItem, ListItemIcon, ListItemButton,
  ListItemText
} from "@mui/material";
import {PrivateRoute} from "./PrivateRoute";
import {useEffect} from "react";
import {withErrorBoundary} from "react-error-boundary";
import {useDispatch} from "react-redux";
import {getInitData} from "../../features/User/UserSlice";
import {FormattedMessage} from "react-intl";

const Layout = () => {
  const navigate: Function = useNavigate();

  const dispatch: Function = useDispatch();

  useEffect(() => {
    dispatch(getInitData())
  }, [dispatch]);

  return (<PrivateRoute>
      <Box sx={{display: 'flex', margin: '30px 0 0 30px'}}>
        <Container
          component="nav"
          className="min-h-screen text-white pt-5 rounded-2xl"
          style={{
            maxWidth: "229px",
            maxHeight: "1064x",
            background: 'linear-gradient(180deg, #9A53CB 0%, #FF4EA1 100%)'
          }}
          disableGutters={true}>
          <div className="flex px-8 pt-2 pb-8 items-center">
            <img src="/assets/images/companio-logo_new.png"
                 alt="logo"
                 width="33"
                 style={{maxHeight: "33px"}}
                 />
            <div className="font-LaNord text-xl text-left font-bold text-white-700 px-3">Companio</div>
          </div>

          <List>
            {
              menuItems.map((menu, i) =>
                    <ListItem disablePadding key={i}>
                      <button className="flex items-center px-5 py-4 hover:bg-[rgb(255,255,255,0.08)] hover:border-l-2 active:bg-[rgb(255,255,255,0.08)] focus:outline-none focus:border-l-2 focus:bg-[rgb(255,255,255,0.08)] w-full text-left">
                        <ListItemIcon sx={{minWidth: '25px'}}>
                          <img src={menu.icon} alt="" width="15" className="mx-4"/>
                        </ListItemIcon>
                        <ListItemText onClick={() => navigate(menu.path)}>
                          <div className="font-LaNord text-sm">
                            <FormattedMessage
                                id={menu.i18n}
                                defaultMessage={menu.name}/>
                          </div>
                        </ListItemText>
                      </button>
                    </ListItem>
                )
            }

            <Divider sx={{my: 1, backgroundColor: 'rgb(255, 255, 255, 0.4)'}}/>

            <button className="flex items-center px-5 py-4 hover:bg-[rgb(255,255,255,0.08)] hover:border-l-2 active:bg-[rgb(255,255,255,0.08)] focus:outline-none focus:border-l-2 focus:bg-[rgb(255,255,255,0.08)] w-full text-left">
              <ListItemIcon sx={{minWidth: '25px'}}>
                <img src="/assets/images/logos/Icon_Ticket.svg" alt="" width="15" className="mx-4"/>
              </ListItemIcon>
              <ListItemText onClick={() => navigate('tickets')}>
                <div className="font-LaNord text-sm">Tickets</div>
              </ListItemText>
            </button>

            <button className="flex items-center px-5 py-4 hover:bg-[rgb(255,255,255,0.08)] hover:border-l-2 active:bg-[rgb(255,255,255,0.08)] focus:outline-none focus:border-l-2 focus:bg-[rgb(255,255,255,0.08)] w-full text-left">
              <ListItemIcon sx={{minWidth: '25px'}}>
                <img src="/assets/images/logos/Icon_Settings.svg" alt="" width="15" className="mx-4"/>
              </ListItemIcon>
              <ListItemText onClick={() => navigate('settings')}>
                <div className="font-LaNord text-sm">Settings</div>
              </ListItemText>
            </button>


          </List>
        </Container>

        <Box
          component="main"
          sx={{
            backgroundColor: "#ffffff",
            flexGrow: 1,
            overflow: 'auto',
            width: "100vw"
          }}
        >
          <div className="pt-6 ml-8 mr-2">
            <Grid container spacing={3}>
              <Outlet/>
            </Grid>
          </div>
        </Box>
      </Box>
    </PrivateRoute>
  );
};

const menuItems = [
  {
    name: "Dashboard",
    i18n: 'home',
    path: "/",
    icon: "/assets/images/logos/Icon_Dashboard.svg"
  },
  {
    name: "Customers",
    i18n: 'customers',
    path: "/customers",
    icon: "/assets/images/logos/Icon_Customer.svg"
  },
  {
    name: "Sales",
    i18n: 'sales',
    path: "/sales",
    icon: "/assets/images/logos/Icon_Sales.svg"
  },
  {
    name: "Purchases",
    i18n: 'purchases',
    path: "/purchases",
    icon: "/assets/images/logos/Icon_Purchase.svg"
  },
  {
    name: "Banks",
    i18n: 'banks',
    path: "/banks",
    icon: "/assets/images/logos/Icon_bank.svg"
  },
  {
    name: "Employees",
    i18n: 'employees',
    path: "/employees",
    icon: "/assets/images/logos/Icon_Employees.svg"
  },
  {
    name: "Banks movements",
    i18n: 'bank_movements',
    path: "/bank_movements",
    icon: "/assets/images/logos/Icon_Bank_Movements.svg"
  },
  {
    name: "Reports",
    i18n: 'reports',
    path: "/reports",
    icon: "/assets/images/logos/Icon_Report.svg"
  },
  {
    name: "Virtual Office",
    i18n: 'virtual_office',
    path: "/virtual_office",
    icon: "/assets/images/logos/Icon_Virtual_Office.svg"
  },
  {
    name: "Travels",
    i18n: 'travels',
    path: "/travels",
    icon: "/assets/images/logos/Icon_travels.svg"
  },
  {
    name: "Billing",
    i18n: 'billing',
    path: "/billing",
    icon: "/assets/images/logos/Icon_Billing.svg"
  }
]

export default withErrorBoundary(
  Layout,
  {
    FallbackComponent: () => <div>Layout failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })