import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

export const getAllEmployees: any = createAsyncThunk<any, any, any>(
    'employees/getAllEmployees',
    async (_, thunkAPI) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL+'/employees',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
                        'Content-Type': 'application/json',
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e: any) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getCompanyRegistrationAndMembers: any = createAsyncThunk<any, any, any>(
    'employees/getCompanyRegistrationAndMembers',
    async(_,thunkAPI) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL+'/company_registration',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        Authorization: JSON.parse(Cookies.get('tuempresaenestonia_auth') || "").access_token,
                        'Content-Type': 'application/json',
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e: any) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const employeesSlice: any = createSlice({
    name: "employees",
    initialState: {
        employeesData: [],
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;

            return state;
        },
    },
    extraReducers: {
        [getAllEmployees.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload.message;
        },
        [getAllEmployees.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllEmployees.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.employeesData = payload.employees

        },
        [getCompanyRegistrationAndMembers.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload.message;
        },
        [getCompanyRegistrationAndMembers.pending]: (state) => {
            state.isFetching = true;
        },
        [getCompanyRegistrationAndMembers.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.isError = false;
            // @ts-ignore
            state.employeesData = [...state.employeesData, ...payload.registration.members]
        }
    },
});

export const { clearState } = employeesSlice.actions;

export const employeesSelector = (state: any) => state.employees;
