import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {withErrorBoundary} from "react-error-boundary";
import {useEffect, useState} from "react";
import {Box, Container} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const RegistrationPaymentStatus = () => {
  const {payment_intent_id} = useParams()

  const [status, setStatus] = useState(null)

  useEffect(() => {
      verifyPaymentIntent(payment_intent_id);
  }, [payment_intent_id]);

  const verifyPaymentIntent = async (intentId: any) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/payments/verify_registration_paid/'+intentId,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200 && data.magicLink && data.status === 'succeeded') {
        return window.location.href = data.magicLink
      }

      return setStatus(data.status)
    } catch (e: any) {
      toast.error('Error verifying setup ' + e.message)
    }
  }

  if(!status){
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  if(status === 'failed' || status === 'requires_action' || status === 'requires_payment_method'){
    return <div className="whitespace-pre text-left">
      Your payment has failed
    </div>
  }

  if(status === 'processing'){
    return <div className="whitespace-pre text-left">
      Your payment is processing.
      This can take up to few days depending on payment method.
      We will update you when payment is processed. You can refresh this page to check if payment status has changed.
    </div>
  }

  return <div className="whitespace-pre text-left">
    {status}
  </div>
}

export default withErrorBoundary(
  RegistrationPaymentStatus,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
