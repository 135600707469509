import {useNavigate, useParams} from "react-router-dom";
import {withErrorBoundary} from "react-error-boundary";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  clearState,
  employeesSelector,
  getAllEmployees,
  getCompanyRegistrationAndMembers
} from '../Employee/EmployeeSlice';
import {FormattedMessage, useIntl} from 'react-intl';
import toast from "react-hot-toast";
import {differenceInDaysBetweenDates, readFileAsBase64} from "../../common/utils/utils";
import DefaultInput from "../../common/components/DefaultInput";
import CompanioButton from "../../common/components/CompanioButton";
import {Checkbox, FormControlLabel} from "@mui/material";
import DefaultSelect from "../../common/components/DefaultSelect";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {countriesData} from "../../common/utils/countriesData";
import DefaultDatePicker from "../../common/components/DefaultDatePicker";
import {addTravel, travelsSelector} from "./TravelsSlice";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const AddUser = () => {
  const intl: any = useIntl()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {employeesData} = useSelector(employeesSelector)
  const {isSuccess, isError, errorMessage} = useSelector(travelsSelector)

  const [travelData, setTravelData]: React.ComponentState = useState(defaultTravelState)

  const [submitButton, setSubmitButton]: React.ComponentState = useState(true)

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      if (isError) {
        toast.error(errorMessage);
        dispatch(clearState());
      }
      if (isSuccess) {
        toast.success("success")
        dispatch(clearState());
        setTimeout(() => setTravelData(defaultTravelState), 500);
      }
    }
  }, [isError, errorMessage, isSuccess]);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getCompanyRegistrationAndMembers());
  }, [dispatch])

  useEffect(() => {
    if(travelData.employee_id && travelData.reason && travelData.origin
      && travelData.destination && travelData.start_date && travelData.end_date
      && travelData.accommodation_expenses && travelData.travel_expenses
      && travelData.filename && travelData.filedata) {
        setSubmitButton(false)
    }else {
      setSubmitButton(true)
    }

  }, [travelData])

  const handleInputChange = (e: any, id: any) => {
    if((e.target.id === "travel_expenses" || e.target.id === "accommodation_expenses") && e.target.value < 0) {
      return toast.error("Value must be greater than zero")
    }
    setTravelData((current: any) => {
      return {
        ...current,
        [e.target.id || id]: e.target.value}
    })
  }

  const handleSelectChange = (e: any, id: string) => {
    if(e.target.id === "employee_id") {
      const dailyAllowance: string = setDailyAllowance(e.target.value, null, null);
      const checked: boolean = Number(dailyAllowance) > 0 ? true : false;
      setTravelData((current: any) => {
        return {
          ...current,
          board_member: checked ? e.target.value: '',
          daily_allowance: checked,
          daily_allowance_result: dailyAllowance,
          employee: e.target.value,
          [e.target.id || id]: e.target.value}
      })
    }
    else {
      setTravelData((current: any) => {
        return {
          ...current,
          [e.target.id || id]: e.target.value}
      })
    }
  }

  const handleDateChange = (e: any, id: string) => {
    setTravelData((current: any) => {
      return {
        ...current,
        daily_allowance_result: setDailyAllowance(null, id, e.$d),
        [id]: new Date(e.$d)
      }
    })
  }

  const setDailyAllowance = (value: any, fieldName: any, fieldValue: any) => {
    const startDate = fieldName === 'start_date' ? fieldValue : travelData.start_date
    const endDate = fieldName === 'end_date' ? fieldValue : travelData.end_date

    const diffInDays = differenceInDaysBetweenDates(startDate, endDate) + 1
    let total = 0
    const selectedEmployee = employeesData.find((e: any) => e._id === (value || travelData.employee_id))
    if(selectedEmployee && selectedEmployee.type.includes("board_member")){
      if (diffInDays <= 15) { total = diffInDays * 50 }
      else {
        const firstFifteen = 15 * 50
        const theRest = (diffInDays - 15) * 32
        total = firstFifteen + theRest
      }
        return total.toFixed(2)
    } else {
      return total.toFixed(2)
    }
  }

  const handleInputFile = async (event: any) => {
    if(event.target.files && event.target.files[0]){
      const files: any[] = event.target.files
      const filedata = await readFileAsBase64(files[0])
      // @ts-ignore
      setTravelData({
        ...travelData,
        filename: files[0].name,
        filedata: filedata
      })
    }
  }

  const handleSubmit = () => {
    dispatch(addTravel(travelData))
  }
  const handleCancelButton = () => {
    setTravelData(defaultTravelState)
    navigate('/travels')
  }

  return <>
    <button className="flex justify-items-center items-center text-base ml-5" onClick={() => navigate("/travels")}>
      <ArrowBackIosIcon className="text-blue-800" sx={{height: '15px'}}/>
      <p className="text-left py-5 font-bold text-blue-800">Back to Travels</p>
    </button>
    <div className="flex flex-col w-full border rounded-lg m-5">

      <p className="text-xl text-left pl-8 pt-5 pb-12 font-bold"><FormattedMessage id="add_new_travel"/></p>

      <div className="flex flex-wrap justify-between my-4 px-10 border border-t-0 border-x-0">
        <div className="flex flex-col items-start w-full mb-4">
          <DefaultSelect
            label={intl.formatMessage({id: "employee"})}
            onChange={(e:any) => handleSelectChange(e, e.target.id)}
            id="employee_id" value={travelData.employee_id}
            className="w-full">
            <option>Choose employee</option>
            {employeesData.map((e: any) => {
              return  <option key={e._id}
                value={`${e._id}`}>{`${e.first_name} ${e.last_name} (${e.type})`}
              </option>
              })
            }
          </DefaultSelect>
          <div className="text-xs font-LaNord text-[#9FA2B4]">
            <FormControlLabel
              control={<Checkbox id="daily_allowance" sx={{color: '#9FA2B4'}} disabled={true} checked={travelData.daily_allowance}/>}
              label={intl.formatMessage({id: "request_daily_allowance"})} />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-between myt-4 pb-4 px-10 border border-t-0 border-x-0">
        <div className="flex flex-col items-start w-full md:w-[48%]">
          <DefaultSelect label={intl.formatMessage({id: "source"})} onChange={(e: any) => handleSelectChange(e, e.target.id)} id="origin" value={travelData.origin} className="w-full">
            <option>Choose source</option>
            {countriesData.map((c: any) => {
              return <option
               value={c.code} key={c.code}>{c.name}</option>
            })}
          </DefaultSelect>
        </div>
        <div className="flex flex-col items-start w-full md:w-[48%]">
          <DefaultSelect label={intl.formatMessage({id: "destination"})} onChange={(e: any) => handleSelectChange(e, e.target.id)} id="destination" value={travelData.destination} className="w-full">
            <option>Choose destination</option>
            {countriesData.map((c: any) => {
              return <option
                value={c.code} key={c.name}>{c.name}</option>
            })}
          </DefaultSelect>
        </div>
        <div className="flex flex-col items-start w-full md:w-[48%]">
          <p
            className="text-start uppercase mb-2"
            style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
            <FormattedMessage id="start_date" />
          </p>
          <DefaultDatePicker id="start_date" date={travelData.start_date} name={'start_date'} width="100%" onChange={(e: any) => handleDateChange(e, "start_date")} />
          {' '}
        </div>
        <div className="flex flex-col items-start w-full md:w-[48%]">
          <p
            className="text-start uppercase mb-2"
            style={{ color: '#9FA2B4', fontWeight: 700, fontSize: '12px' }}>
            <FormattedMessage id="end_date" />
          </p>
          <DefaultDatePicker
            id="end_date"
            date={travelData.end_date}
            name={'end_date'}
            width="100%"
            minDate={travelData.start_date}
            onChange={(e: any) => handleDateChange(e, "end_date")} />
          {' '}
        </div>
      </div>
      <div className="flex flex-wrap justify-between my-4 px-10">
        <div className="flex flex-col items-start w-full">
          <div className="flex flex-wrap justify-between justify-items-center items-center mt-2 w-full">
            <DefaultInput
              onChange={(e: any) => handleInputChange(e, e.target.id)}
              value={travelData.travel_expenses}
              required
              id="travel_expenses"
              type="number"
              label={intl.formatMessage({id: "travel_expenses"})}
              className="w-full md:w-[30%] mb-4"
            />
            <DefaultInput
              onChange={(e: any) => handleInputChange(e, e.target.id)}
              value={travelData.accommodation_expenses}
              required
              type="number"
              id="accommodation_expenses"
              label={intl.formatMessage({id: "accommodation_expenses"})}
              className="w-full md:w-[30%] mb-4"
            />
            <DefaultInput
              disabled={true}
              onChange={() => console.log("test")}
              value={travelData.daily_allowance_result}
              required
              type="number"
              id="daily_allowance_result"
              label={intl.formatMessage({id: "daily_allowance"})}
              className="w-full md:w-[30%] mb-4"
            />
            <DefaultInput
              onChange={(e: any) => handleInputChange(e, e.target.id)}
              value={travelData.reason}
              required
              id="reason"
              label={intl.formatMessage({id: "trip_reason"})}
              className="w-full mb-4"
            />
          </div>
        </div>

        <div className="flex flex-row justify-center md:justify-between w-full mb-4">
          <div className="flex w-full justify-center md:justify-start mt-4">
            <AttachFileIcon sx={{color: "#C5C7CD"}}/>
            <input type="file" className="hidden" id="upload_file" onChange={handleInputFile}/>
            <label htmlFor="upload_file" className="font-LaNord text-sm text-[#C5C7CD] cursor-pointer">
              <FormattedMessage id="document_proof_trip" />
            </label>
          </div>
          <div className="flex w-full justify-center md:justify-end mt-4">
            <CompanioButton
              onClick={() => handleCancelButton()}
              variant="outlined">
              cancel
            </CompanioButton>
            <CompanioButton
              disabled={submitButton}
              onClick={() => handleSubmit()}
              variant="contained">
              Submit
            </CompanioButton>
          </div>
        </div>
      </div>
    </div>
  </>
};

type defaultTravelStateT = {
  employee: string;
  employee_id: string;
  board_member: string;
  daily_allowance: Boolean;
  reason: string;
  origin: string;
  destination: string;
  filedata: string;
  filename: string;
  start_date: Date;
  end_date: Date;
  accommodation_expenses: number;
  travel_expenses: number;
  daily_allowance_result: number;
  allow_dates_in_the_past: Boolean;
}

const defaultTravelState: defaultTravelStateT = {
  employee: '',
  employee_id: '',
  board_member: '',
  daily_allowance: false,
  reason: '',
  origin: '',
  destination: '',
  filedata: '',
  filename: '',
  start_date: new Date(),
  end_date: new Date(),
  accommodation_expenses: 0,
  travel_expenses: 0,
  daily_allowance_result: 0,
  allow_dates_in_the_past: true
}

const hotelCeilings = {
  AF: 75,
  AX: 145,
  AL: 160,
  DZ: 85,
  AS: 135,
  AD: 126.57,
  AO: 175,
  AI: 140,
  AQ: 145,
  AG: 140,
  AR: 210,
  AM: 210,
  AW: 185,
  AU: 135,
  AT: 130,
  AZ: 200,
  BS: 195,
  BH: 195,
  BD: 140,
  BB: 140,
  BY: 135,
  BE: 140,
  BZ: 135,
  BJ: 100,
  BM: 140,
  BT: 130,
  BO: 1100,
  BQ: 185,
  BA: 135,
  BW: 135,
  BV: 145,
  BR: 180,
  IO: 145,
  BN: 165,
  BG: 169,
  BF: 90,
  BI: 115,
  KH: 115,
  CM: 105,
  CA: 165,
  CV: 75,
  KY: 135,
  CF: 80,
  TD: 145,
  CL: 175,
  CN: 155,
  CX: 145,
  CC: 145,
  CO: 120,
  KM: 85,
  CG: 18,
  CD: 140,
  CK: 135,
  CR: 140,
  CI: 130,
  HR: 120,
  CU: 150,
  CW: 145,
  CY: 145,
  CZ: 155,
  DK: 150,
  DJ: 170,
  DM: 140,
  DO: 170,
  EC: 140,
  EG: 140,
  SV: 125,
  GQ: 85,
  ER: 80,
  EE: 110,
  ET: 145,
  FK: 145,
  FO: 145,
  FJ: 120,
  FI: 140,
  FR: 150,
  GF: 140,
  PF: 135,
  GA: 115,
  GM: 120,
  GE: 215,
  DE: 115,
  GH: 140,
  GI: 145,
  GR: 140,
  GL: 145,
  GD: 140,
  GP: 115,
  GU: 135,
  GT: 125,
  GG: 145,
  GN: 145,
  GW: 90,
  GY: 160,
  HT: 125,
  VA: 145,
  HN: 125,
  HK: 205,
  HU: 150,
  IS: 160,
  IN: 195,
  ID: 145,
  IR: 145,
  IQ: 85,
  IE: 150,
  IM: 145,
  IL: 210,
  IT: 135,
  JM: 175,
  JP: 275,
  JE: 145,
  JO: 135,
  KZ: 175,
  KE: 165,
  KI: 145,
  KP: 145,
  KR: 200,
  KW: 195,
  KG: 185,
  LV: 145,
  LB: 190,
  LS: 100,
  LR: 150,
  LY: 175,
  LI: 95,
  LT: 115,
  LU: 145,
  MO: 95,
  MK: 160,
  MG: 105,
  MW: 165,
  MY: 160,
  MV: 135,
  ML: 95,
  MT: 115,
  MH: 135,
  MQ: 110,
  MR: 75,
  MU: 140,
  YT: 110,
  MX: 185,
  FM: 135,
  MD: 170,
  MC: 97.,
  MN: 90,
  ME: 145,
  MS: 145,
  MA: 130,
  MZ: 140,
  MM: 75,
  NA: 85,
  NR: 135,
  NP: 135,
  NL: 170,
  NC: 135,
  NZ: 125,
  NI: 135,
  NE: 75,
  NG: 185,
  NU: 135,
  NF: 145,
  MP: 135,
  NO: 140,
  OM: 135,
  PK: 130,
  PW: 135,
  PA: 160,
  PG: 135,
  PY: 140,
  PE: 135,
  PH: 150,
  PN: 145,
  PL: 145,
  PT: 120,
  PR: 140,
  QA: 135,
  RE: 90,
  RO: 170,
  RU: 275,
  RW: 160,
  BL: 145,
  SH: 145,
  KN: 145,
  LC: 140,
  MF: 145,
  PM: 145,
  VC: 190,
  WS: 135,
  SM: 114,
  ST: 95,
  SA: 195,
  SN: 135,
  RS: 145,
  SC: 140,
  SL: 135,
  SG: 150,
  SX: 145,
  SK: 125,
  SI: 110,
  SB: 120,
  SO: 125,
  ZA: 145,
  SS: 145,
  ES: 125,
  LK: 105,
  SD: 215,
  SR: 125,
  SZ: 90,
  SE: 160,
  CH: 140,
  SY: 145,
  TW: 200,
  TJ: 110,
  TZ: 200,
  TH: 145,
  TL: 145,
  TG: 95,
  TK: 135,
  TO: 105,
  TT: 115,
  TN: 85,
  TR: 165,
  TM: 150,
  TC: 135,
  TV: 135,
  UG: 180,
  UA: 190,
  AE: 195,
  GB: 175,
  US: 200,
  UY: 160,
  UZ: 155,
  VU: 110,
  VE: 125,
  VN: 205,
  VG: 145,
  VI: 145,
  YE: 165,
  ZM: 135,
  ZW: 115,
}

export default withErrorBoundary(
  AddUser,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });