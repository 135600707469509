import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {withErrorBoundary} from 'react-error-boundary';
import {
  Box,
  Stepper,
  Container,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import {clearState, registerCompany, userSelector, verifyRegistrationToken} from '../User/UserSlice';
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Contract from "./Steps/Contract"
import AlreadyFreelancer from "./Steps/AlreadyFreelancer";
import BasicFields from "./Steps/BasicFields";
import toast from "react-hot-toast";
import CountrySpecificFields from "./Steps/CountrySpecificFields";

const steps = [
  {
    stepNumber: 0,
    label: 'Contract',
    component: Contract
  },
  {
    stepNumber: 1,
    label: 'Current status',
    component: AlreadyFreelancer
  },
  {
    stepNumber: 2,
    label: 'Basic info',
    component: BasicFields
  },
  {
    stepNumber: 3,
    label: 'Advanced info',
    component: CountrySpecificFields
  },
];

const RegisterSteps = () => {
  const {token} = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0);

  const [registrationData, setRegistrationData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    is_switch: false,
    status: "not_freelancer",
    business_information: [
      {
        id: 'passport_pic',
        type: 'File',
        filename: null,
        mime_type: null,
        value: null
      },
      {
        id: 'activity',
        type: 'Options',
        value: null
      },
      {
        id: 'address',
        type: 'String',
        value: null
      },
      {
        id: 'city',
        type: 'String',
        value: null
      },
      {
        id: 'country',
        type: 'Options',
        value: null
      },
      {
        id: 'freelance_last_two_years',
        type: 'Boolean',
        value: false
      }
    ]
  });

  const handleStep = (stepNumber: any, newData: any) => {
    if(stepNumber !== 4){
      if (newData) {
        setRegistrationData({
          ...registrationData,
          ...newData
        })
      }
      setActiveStep(stepNumber);
      return
    }
    dispatch(registerCompany({data: registrationData}) as any).then((e: any) => {
      navigate('/register/payment/'+JSON.stringify(e.payload))
    })
  };

  const updateBusinessInfo = (newData: any) => {
    if(registrationData.business_information.find(i => i.id === newData.id)){
      const updatedBusinessInfo = registrationData.business_information.map(i => {
        if (i.id === newData.id) {
          return newData
        }
        return i
      })

      return setRegistrationData({
        ...registrationData,
        business_information: updatedBusinessInfo
      })
    }

    setRegistrationData({
      ...registrationData,
      business_information: registrationData.business_information.concat(newData)
    })
  };

  const {registrationTokenUser, isError, errorMessage} = useSelector(userSelector)

  useEffect(() => {
    if(registrationTokenUser){
      setRegistrationData((prevState) => {
        return {
          ...prevState,
          email: registrationTokenUser.email,
          first_name: registrationTokenUser.first_name,
          last_name: registrationTokenUser.last_name
        }})
    }
  }, [registrationTokenUser])


  useEffect(() => {
    dispatch(verifyRegistrationToken(token) as any)
  }, [dispatch, token])

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
      navigate("/register")
    }
  }, [dispatch, isError, errorMessage, navigate]);

  if (!registrationTokenUser) {
    return <Container maxWidth="xs">
      <Box sx={{display: 'flex'}}>
        <CircularProgress/>
      </Box>
    </Container>
  }

  return <div className="flex min-h-screen">
    <div className="flex-1 flex flex-col justify-between" style={{padding: '40px'}}>
      <img src="/assets/images/companio-logo.png" alt="logo" width="149" style={{maxHeight: "40px"}}/>

      <Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step: any, index: number) => (
            <Step key={step.label}>
              <StepLabel>
                {step.label}
              </StepLabel>

              <StepContent>
                {
                  step.component && React.createElement(
                    step.component,
                    {
                      registrationTokenUser,
                      handleStep,
                      updateBusinessInfo,
                      registrationData
                    }
                  )
                }
              </StepContent>

            </Step>
          ))}
        </Stepper>
      </Box>

      <div></div>
      {/*has to be here because of flex */}

    </div>

    <div className="flex items-center justify-center"
         style={{
           background: 'linear-gradient(109.4deg, #9A53CB 1.26%, #FF4EA1 96.4%)',
           padding: '40px',
           width: '350px'
         }}>
      <img src="/assets/images/login-people.png" alt="people"/>
    </div>
  </div>
};

export default withErrorBoundary(
  RegisterSteps,
  {
    FallbackComponent: () => <div>Failed to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  });
