import {withErrorBoundary} from "react-error-boundary";
import {FormattedMessage} from "react-intl";
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from "react-intl";
import {
  clearState,
  banksSelector
} from '../Banks/BanksSlice';
import toast from "react-hot-toast";
import {userSelector} from "../User/UserSlice";
import CompanioButton from "../../common/components/CompanioButton";

const EmailAutomation = () => {
  const dispatch = useDispatch()
  const {banks} = useSelector(banksSelector)
  const {user} = useSelector(userSelector)
  const intl = useIntl()

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  const copyText = (email: any) => {
    navigator.clipboard.writeText(email)
    toast.success(intl.formatMessage({id: 'generated_copied'}));
  }

  const handleChange = (event: any) => {
    copyText(event)
  }

  const handleButtonClick = (type: any) => {
    let email = type
    if (type === 'purchase_invoice') {
      email = "p+p+" + user.company._id + "@companio.co"
    }
    if (type === 'sales_invoice') {
      email = "p+s+" + user.company._id + "@companio.co"
    }
    copyText(email)
  }

  return <div className="flex flex-col w-full border rounded-lg my-6">
    <div className="m-10 text-left">
      <p className="text-lg font-LaNord font-bold text-[#9FA2B4]">
        <FormattedMessage id={"email_automation_1"}/>
      </p>

      <p className="text-lg font-LaNord font-bold text-[#252733]">
        <FormattedMessage id={"email_automation_2"}/>
      </p>
    </div>

    <div className="flex flex-col md:flex-row justify-between w-1/2 ml-10">
      <p className="text-lg font-LaNord font-bold text-[#9FA2B4]"><FormattedMessage id={"purchase_invoice"}/></p>
      <CompanioButton
        variant="contained"
        size="large"
        onClick={() => handleButtonClick("purchase_invoice")}>
        <FormattedMessage id={"generate_and_copy"}/>
      </CompanioButton>
    </div>

    <div className="flex flex-col md:flex-row justify-between w-1/2 ml-10 mt-10">
      <p className="text-lg font-LaNord font-bold text-[#9FA2B4]"><FormattedMessage id={"sales_invoice"}/></p>
      <CompanioButton
        variant="contained"
        size="large"
        onClick={() => handleButtonClick("sales_invoice")}>
        <FormattedMessage id={"generate_and_copy"}/>
      </CompanioButton>
    </div>

    <div className="flex flex-col w-1/2 ml-10 mt-10 items-start">
      <p className="text-lg font-LaNord font-bold text-[#9FA2B4]"><
        FormattedMessage id="bank_statement" defaultMessage="Bank statement"/>
      </p>
      <div className="flex justify-between items-center mt-2 w-full h-10 mb-10">
        <select className="h-[40px] p-2 border rounded-lg text-sm font-LaNord text-[#9FA2B4] w-full bg-[#FCFDFE]" id="bank_statement">
          {banks.map((bank: any) => <option value={"p+b+" + bank.bank_type + "+" + bank.company + "@companio.co"}
                                           key={bank._id}
                                           className="text-left"
                                           onClick={() => handleChange("p+b+" + bank.bank_type + "+" + bank.company + "@companio.co")}>
              <em>{bank.name.replace('_', ' ').toUpperCase()}</em>
            </option>
          )}
        </select>
      </div>
    </div>
  </div>
}

export default withErrorBoundary(
  EmailAutomation,
  {
    FallbackComponent: () => <div>Setting to load</div>, // we can display message with (err) => <div>{err.error.message}</div>
    onError: (err, info) => {
      console.log(err, info) // we can send this to our api, logger, sentry or anything
    }
  })